import type { ExactFilter } from "@/hooks/useTableSSR";
import { FilterFilled } from "@ant-design/icons";
import { Typography } from "antd";
import _cloneDeep from "lodash/fp/cloneDeep";
import _isEqual from "lodash/fp/isEqual";
import type { Dispatch, SetStateAction } from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import ColumnSelect from "./ColumnSelect";
import Result from "./Result";
import { SBox, SContent, SMessage, STableFilter } from "./StyleComponent";
import ValueList from "./ValueList";

/* ------------------- Types ------------------ */
export interface TableFilterProps {
  hook: UseTableFilterHook;
  dataCount: number; //篩選結果資料總筆數量
  rawCount: number; // 未篩選比數
  loading?: boolean;
  success?: boolean;
}

/* -------------------------------------------- */
/*               Default Component              */
/* -------------------------------------------- */
const TableFilter = (props: TableFilterProps) => {
  const { dataCount, rawCount, loading, success } = props;
  const {
    open,
    toggle,
    curColumn,
    setCurColumn,
    exactFilter,
    setExactFilter,
    filterValues,
    filterColumns,
    getLabelFromValue,
  } = props.hook;
  const hasFilters = Object.keys(exactFilter).some(
    (col) => exactFilter[col].length
  );

  /* ------------------ STEP 1 ------------------ */
  const onSelectColumn = (col: string) => {
    setCurColumn(col);
  };
  /* ------------------ STEP 2 ------------------ */
  const selectSingleValue = (val: string) => {
    const newValues: any = { ...exactFilter };
    //檢查此欄位是否已有設定過篩選值
    if (curColumn in newValues) {
      //檢查此篩選值是否已選取
      if (newValues[curColumn].includes(val)) {
        const idx = newValues[curColumn].indexOf(val);
        newValues[curColumn].splice(idx, 1);
        !newValues[curColumn].length && delete newValues[curColumn];
      } else {
        newValues[curColumn].push(val);
      }
    } else {
      newValues[curColumn] = [val];
    }

    setExactFilter(newValues);
  };

  const selectMultipleValues = (values: FilterValues[string]) => {
    if (values.length === 0) return;

    const updatedValues = { ...exactFilter };
    updatedValues[curColumn] = [
      /** 增加選取搜尋到的數值，增加（不覆蓋）原有篩選數值 */
      ...new Set([
        ...(updatedValues[curColumn] || []), // Possibly undefined
        ...values.map((v) => v.value),
      ]),
    ];
    setExactFilter(updatedValues);
  };

  const deselectMultipleValues = (values: FilterValues[string]) => {
    if (values.length === 0 || !exactFilter[curColumn]) return;

    const updatedValues = { ...exactFilter };
    const deselectedValues = values.map((i) => i.value);
    updatedValues[curColumn] = updatedValues[curColumn].filter(
      (i) => deselectedValues.indexOf(i) === -1
    );
    if (updatedValues[curColumn].length === 0) {
      delete updatedValues[curColumn];
    }

    setExactFilter(updatedValues);
  };

  /* ------------------ STEP 3 ------------------ */
  const clearValueFilter = (col: string, val: string) => {
    const updatedValues = { ...exactFilter };
    const idx = updatedValues[col].indexOf(val);
    if (idx !== -1) {
      updatedValues[col].splice(idx, 1);
      !updatedValues[curColumn].length && delete updatedValues[curColumn];
    }
    setExactFilter(updatedValues);
  };

  const clearFieldFilter = (col: string) => {
    const updatedValues = { ...exactFilter };
    if (!updatedValues[col]) return;
    delete updatedValues[col];
    setExactFilter(updatedValues);
  };

  const clearAllFilters = () => {
    setExactFilter({});
  };

  /* -------------------- JSX ------------------- */
  /** 已設定篩選條件 (bgc 亮色)
   *     - 打開、縮起：提示已設定篩選條件並顯示資料總筆數
   *  尚未設定篩選條件 (bgc 灰色)
   *     - 打開：empty string
   *     - 縮起：提示點擊即可設定                  */
  const totalCountText =
    typeof rawCount === "number" ? `全部 ${rawCount} 筆資料。` : "";
  const filterDetailMsg = hasFilters
    ? `依表格的內容進行篩選，結果有 ${dataCount} 筆資料。${totalCountText}`
    : open
    ? totalCountText
    : `依表格的內容進行篩選，${totalCountText}`;

  /* ------------------- RENDER ------------------ */
  return (
    <STableFilter>
      <SMessage onClick={toggle} hasFilters={hasFilters}>
        <div className="title">
          <FilterFilled />
          內容篩選器
        </div>
        <Typography.Text
          className="detail"
          ellipsis={{
            tooltip: { placement: "topRight", title: filterDetailMsg },
          }}
        >
          {filterDetailMsg}
        </Typography.Text>
      </SMessage>
      <SContent show={open}>
        {/* ------------------ STEP. 1 ------------------ */}
        <SBox>
          <div className="title">
            <span>1</span>
            <span>選擇欲篩選欄位</span>
          </div>
          <ColumnSelect
            options={filterColumns}
            onSelectColumn={onSelectColumn}
            curColumn={curColumn}
          />
        </SBox>
        {/* ------------------ STEP. 2 ------------------ */}
        <SBox>
          <div className="title">
            <span>2</span>
            <span>選取篩選值</span>
          </div>
          <ValueList
            rawValues={filterValues[curColumn] ?? []}
            selectedValues={exactFilter[curColumn]}
            selectSingleValue={selectSingleValue}
            selectMultipleValues={selectMultipleValues}
            deselectMultipleValues={deselectMultipleValues}
            curColumn={curColumn}
            getLabelFromValue={getLabelFromValue}
            loading={!!loading}
            success={success}
          />
        </SBox>
        {/* ------------------ STEP. 3 ------------------ */}
        <SBox>
          <div className="title">
            <span>3</span>
            <span>檢視篩選條件</span>
          </div>
          <Result
            selectedFilters={exactFilter}
            clearValueFilter={clearValueFilter}
            clearFieldFilter={clearFieldFilter}
            filterColumns={filterColumns}
            clearAllFilters={clearAllFilters}
            getLabelFromValue={getLabelFromValue}
          />
        </SBox>
      </SContent>
    </STableFilter>
  );
};

export default TableFilter;

/* -------------------------------------------- */
/*              Table Filter Hooks              */
/* -------------------------------------------- */
export type FilterValues = {
  [key: string]: {
    count: number;
    value: string;
    total: number;
    // label?: string;
  }[];
};
type FilterColumns = { value: string; label: string }[];
type OnFilterChangeReturn = FilterValues | undefined;

interface UseTableFilterProps {
  defaultOpen?: boolean;

  // 僅用來抓取新的 filter values 資料
  onFilterChange?: (
    curColumn: string
  ) => OnFilterChangeReturn | Promise<OnFilterChangeReturn>;

  filterColumns: FilterColumns; // 可進行篩選的 column
  defaultFilterValues?: FilterValues; // 所有欄位篩選值預設值
  exactFilter: ExactFilter; // 已選取的篩選條件
  setExactFilter: Dispatch<SetStateAction<ExactFilter>>; // 設定篩選條件
  getLabelFromValue?: (column: string, value: any) => string;
}
export interface UseTableFilterHook {
  open: boolean; // 篩選器開關狀態
  toggle: () => void; // 切換篩選器展開狀態
  curColumn: string;
  setCurColumn: Dispatch<SetStateAction<string>>;
  reloadFilterValues: () => void;
  filterValues: FilterValues; // 所有欄位篩選值

  /** Return as is from props */
  filterColumns: UseTableFilterProps["filterColumns"]; // 欲進行篩選的 column
  exactFilter: UseTableFilterProps["exactFilter"];
  setExactFilter: UseTableFilterProps["setExactFilter"];
  getLabelFromValue?: UseTableFilterProps["getLabelFromValue"];
}
export const useTableFilter = (
  props: UseTableFilterProps
): UseTableFilterHook => {
  const {
    defaultOpen,
    onFilterChange,
    exactFilter,
    setExactFilter,
    filterColumns,
    defaultFilterValues,
    getLabelFromValue,
  } = props;

  /** Panel UI 狀態 */
  const [open, setOpen] = useState<boolean>(defaultOpen ?? false);
  const toggle = useCallback(() => setOpen((prev) => !prev), []);
  const [curColumn, setCurColumn] = useState<string>(() => {
    if (filterColumns.length > 0) {
      // 預設為第一個
      return filterColumns[0].value;
    }
    return "";
  });

  /** 篩選 Panel 種類與數量 */
  const [filterValues, setFilterValues] = useState<FilterValues>(
    defaultFilterValues || {}
  );
  const cacheFilterValues = useRef(filterValues);

  /** 更新 onFilterChange closure 抓取新的 FilterValues 資料 */
  const updateRef = useRef<NonNullable<UseTableFilterProps["onFilterChange"]>>(
    onFilterChange || (() => ({}))
  );
  useEffect(() => {
    if (onFilterChange) {
      updateRef.current = onFilterChange;
    }
  }, [onFilterChange]);

  /** 當 drawId 改變時，將觸發 onFilterChange 抓取新的 filterValues。使用 Math.random() 當作 ID。適用於外部邏輯觸發更新，例如版次改變 */
  const [queryId, setQueryId] = useState<number>(Math.random());

  /** Cache previous state of `exactFilter` and `queryId` */
  const prevQueryId = useRef(queryId);
  const prevExactFilter = useRef(exactFilter);
  const prevCurColumn = useRef(curColumn);

  /** Update filter values on ...
   * @If queryId changes (外部邏輯)
   * @If exactFilter deep comparison is different
   * @If curColumn changes and no cache exists in `cacheFilterValues`
   * @NoCacheExistIf `cacheFilterValues` has no curColumn data and length is not empty
   */
  useEffect(() => {
    const fetchFilterData = async () => {
      const isQueryIdChange = prevQueryId.current !== queryId;
      const isExactFilterChange = !_isEqual(
        prevExactFilter.current,
        exactFilter
      );
      const isColumnChange = prevCurColumn.current !== curColumn;

      if (isExactFilterChange) {
        cacheFilterValues.current = {};
      }

      const cacheData = cacheFilterValues.current[curColumn];
      const noCacheExist = !(Array.isArray(cacheData) && cacheData.length > 0);

      if (
        isQueryIdChange ||
        isExactFilterChange ||
        (isColumnChange && noCacheExist)
      ) {
        const data = await updateRef.current(curColumn);
        if (data) {
          const mergedData = {
            ...cacheFilterValues.current,
            ...data,
          };
          setFilterValues(mergedData);
          cacheFilterValues.current = mergedData;
        }
      }
    };

    fetchFilterData();
    prevExactFilter.current = _cloneDeep(exactFilter);
    prevQueryId.current = queryId;
    prevCurColumn.current = curColumn;
  }, [exactFilter, queryId, curColumn, open, setExactFilter]);

  /** 手動觸發刷新 */
  const reloadFilterValues = useCallback(() => {
    setQueryId(Math.random());
    /** Reset all cache when manually reload */
    setFilterValues({});
    cacheFilterValues.current = {};
  }, []);

  return {
    open,
    toggle,
    curColumn,
    setCurColumn,
    exactFilter,
    setExactFilter,
    filterColumns,
    filterValues,
    reloadFilterValues,
    getLabelFromValue,
  };
};
