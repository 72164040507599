/** Types */
import type {
  AsyncThunk,
  ActionReducerMapBuilder,
  // CaseReducer,
  // PayloadAction,
  // AsyncThunkPayloadCreator,
} from "@reduxjs/toolkit";
import type { EnhancedAxiosResponse } from "../utils/axios";

/* ------------------- Types ------------------ */
export type InitialState = {
  [key in string]: StateData;
};

export interface StateData<T = any> {
  loading: boolean;
  success: boolean | undefined;
  data: T | undefined;
}

// type ThunkCallBacks = {
//   pendingCallback?: CaseReducer<InitialState>;
//   fulfilledCallback?: CaseReducer<InitialState, PayloadAction<InitialState>>;
//   rejectedCallback?: CaseReducer<InitialState>;
// };

interface CreateExtraReducerParams {
  builder: ActionReducerMapBuilder<InitialState>;
  asyncThunk: AsyncThunk<any, any, any>;
  stateKey: string;
  // callback?: ThunkCallBacks;
}

/* ----------------- Functions ---------------- */
export const createApiState = <T>(): StateData<T> => ({
  loading: false,
  success: undefined,
  data: undefined,
});

export const createExtraReducer = ({
  builder,
  asyncThunk,
  stateKey,
}: // callback,
CreateExtraReducerParams) => {
  // const { pendingCallback, fulfilledCallback, rejectedCallback } =
  //   callback || {};

  builder.addCase(asyncThunk.pending, (state) => {
    state[stateKey].loading = true;
    // pendingCallback && pendingCallback(state, action);
  });

  builder.addCase(asyncThunk.fulfilled, (state, action) => {
    state[stateKey].loading = false;
    state[stateKey].success = true;
    state[stateKey].data = action.payload;
    // fulfilledCallback && fulfilledCallback(state, action);
  });

  builder.addCase(asyncThunk.rejected, (state) => {
    state[stateKey].loading = false;
    state[stateKey].success = false;
    // rejectedCallback && rejectedCallback(state, action);
  });
};

export const handleAxiosThunk = async (
  response: EnhancedAxiosResponse,
  thunkAPI: any,
  defer: number = 100
) => {
  const { ok, data } = response;

  /** Delay for smooth UI transition when data changed */
  await sleep(defer);

  return ok ? data : thunkAPI.rejectWithValue(null);
};

const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};
