import { ApiService } from "../axios";

const endpoints = {
  prepare: {
    url: "/api/scheduler/prepare",
    method: "post",
  },
  reschedule: {
    url: "/api/scheduler/schedule",
    method: "post",
  },
  release: {
    url: "/api/scheduler/release",
    method: "post",
  },
  writeBackERP: {
    url: "/save_back_to_erp",
    method: "post",
  },
} as const;

type EndpointName = keyof typeof endpoints;

class ScheduleApi extends ApiService<EndpointName> {
  _endpoints = endpoints;

  prepare = () => this.send("prepare");

  reschedule = (autoRelease: boolean = false) => {
    return this.send("reschedule", {
      data: { auto_release: autoRelease },
    });
  };

  release = () => this.send("release");

  writeBackERP = () => this.send("writeBackERP");
}

export default ScheduleApi;
