import React, { useState } from "react";

/** Bootstrap */
import { Button, FloatingLabel, Form, Spinner, Stack } from "react-bootstrap";

/** Componenets */
import AlertDismissible from "../../components/react-bootstrap/alert/AlertDismissible";

/** CSS Modules */
import S from "./SignIn.module.scss";

/** Hooks */
import useAuth from "../../hooks/useAuth";
import { useTranslation } from "react-i18next";

/** Images & Icons */
import { FaUser, FaKey } from "react-icons/fa";
import { BiErrorCircle } from "react-icons/bi";

/** Plugins */
import { Formik } from "formik";
import * as Yup from "yup";

/** ---------- Code Start ---------- */

const SignIn = () => {
  const [alertMessasge, setAlertMessage] = useState<string | void>();

  const { signIn } = useAuth();
  const { t } = useTranslation("common", { keyPrefix: "signIn" });

  const schema = Yup.object().shape({
    email: Yup.string()
      .email(t("error.invalidEmail"))
      .max(255)
      .required(t("error.requireEmail")),
    password: Yup.string().max(255).required(t("error.requirePassword")),
  });

  return (
    <>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={schema}
        onSubmit={async (values) => {
          /** clear error message first, so new <AlertDismissible> component can be re-created. */
          setAlertMessage();

          const { email, password } = values;
          const errorMessage = await signIn(email, password);

          errorMessage && setAlertMessage(errorMessage);
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          touched,
          values,
          errors,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit} autoComplete="on" className={S.sign_in}>
            <Stack gap={4}>
              {/* show message from server on error */}
              {alertMessasge && (
                <AlertDismissible variant="danger">
                  <div className="alert-message">
                    <BiErrorCircle size="1.2rem" />
                    <span className="align-middle ms-1">{alertMessasge}</span>
                  </div>
                </AlertDismissible>
              )}

              {/* email */}
              <FloatingLabel
                controlId="email"
                label={
                  <Stack
                    className="align-middle"
                    direction="horizontal"
                    gap={2}
                  >
                    <FaUser />
                    <span>{t("email")}</span>
                  </Stack>
                }
              >
                <Form.Control
                  type="text"
                  name="email"
                  placeholder={t("email")}
                  value={values.email}
                  isInvalid={!!(touched.email && errors.email)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  autoComplete={"email"}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </FloatingLabel>

              {/* password */}
              <FloatingLabel
                controlId="password"
                label={
                  <Stack
                    className="align-middle"
                    direction="horizontal"
                    gap={2}
                  >
                    <FaKey />
                    <span>{t("password")}</span>
                  </Stack>
                }
              >
                <Form.Control
                  type="password"
                  name="password"
                  placeholder={t("password")}
                  value={values.password}
                  isInvalid={!!(touched.password && errors.password)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  autoComplete={"current-password"}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Stack>

            {/* submit */}
            <div className="d-grid mt-4">
              <Button
                variant="info"
                type="submit"
                size="lg"
                disabled={isSubmitting}
              >
                {isSubmitting && (
                  <Spinner animation="border" size="sm" className="me-2" />
                )}
                {t("login")}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default SignIn;
