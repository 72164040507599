import { useEffect, useState } from "react";

/** Antd */
import { App, ConfigProvider, theme as antTheme } from "antd";

/** Constants */
import { THEME } from "../constants/globals";

/** Hooks */
import useTheme from "../hooks/useTheme";

/** Types */
import type { ThemeConfig } from "antd";
import type { OneOfValues } from "../types/util";

const getAntdTheme = (theme: OneOfValues<typeof THEME>): ThemeConfig => {
  const dark = theme === THEME.DARK;

  return {
    algorithm: dark ? antTheme.darkAlgorithm : antTheme.defaultAlgorithm,

    token: {
      borderRadius: 2,

      /* ---------------- Base Colors --------------- */
      /**
       * @note need to be literal color not variable color.
       */
      colorBgBase: dark ? "#202634" : "#fff",
      colorTextBase: dark ? "#fff" : "#000",
      colorPrimary: "#ed6c28",
      colorLink: dark ? "#9fc7ff" : "#1677ff",
      colorLinkHover: dark ? "#c1dfff" : "#69b1ff",
      colorLinkActive: dark ? "#83bfff" : "#0958d9",

      /* -------------- Derived Colors -------------- */
      /**
       * @note can be variable (computed) color, e.g., `var(--bs-color)`
       */

      /** Box-shadow is black in dark theme. Change it to default lighter color.
       * @component Input. Error border.
       * @component Button. Danger.
       */
      controlOutline: "rgba(5, 145, 255, 0.1)",
      colorErrorOutline: "rgba(255, 38, 5, 0.06)",
    },

    components: {
      Alert: {
        // colorSuccessBg: "hsl(90 100% 15% / 1)",
        // colorSuccessBorder: "hsl(94 70% 22% / 1)",
      },
      Spin: {
        colorPrimary: dark ? "#ddd" : "#555",
      },
      Slider: {
        colorPrimaryBorder: "#f3975a",
        colorPrimaryBorderHover: "#f3975ab7",
      },
      Typography: {
        colorLink: dark ? "hsl(215deg 100% 60%)" : "hsl(215deg 100% 60%)",
        colorLinkHover: dark ? "hsl(215deg 100% 70%)" : "hsl(215deg 100% 45%)",
        colorLinkActive: dark ? "hsl(215deg 100% 80%)" : "hsl(215deg 100% 30%)",
      },
      Notification: {
        /** 降低 Notification zindex 使 Header Dropdown 永遠顯示在最上層 */
        zIndexPopup: 1000,
      },
    },
  };
};

const AntdThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const { theme } = useTheme();
  const [AntdTheme, setAntdTheme] = useState(() => getAntdTheme(theme));

  useEffect(() => {
    setAntdTheme(getAntdTheme(theme));
  }, [theme]);

  return (
    <ConfigProvider theme={AntdTheme}>
      <App
        message={{ top: 68 }}
        notification={{ placement: "bottomLeft", maxCount: 2 }}
        style={{
          /* overwrite the `.ant-app` class that declare color to white */
          color: "inherit",
        }}
      >
        {children}
      </App>
    </ConfigProvider>
  );
};

export default AntdThemeProvider;
