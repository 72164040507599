import React from "react";

/** CSS Modules */
import S from "./Tag.module.scss";

/** PLugins */
import classnames from "classnames";

const Tag = ({
  children,
  variant,
}: {
  children: React.ReactNode;
  variant?: string;
}) => {
  return <div className={classnames(S.tag, S[`${variant}`])}>{children}</div>;
};

export default Tag;
