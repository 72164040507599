import { LS_TABLE_DRAG } from "@/constants/globals";
import { NotNull } from "@/types/util";
import { useEffect, useRef } from "react";
import { z } from "zod";
import { CustomColumnType, getCacheKey } from "../ResizableTable";

export interface DraggableHook {
  updateDraggable: (columns: CustomColumnType[]) => void;
  sortDraggable: (a: CustomColumnType, b: CustomColumnType) => number;
  resetDraggable: () => void;
}

const useDraggable = (): DraggableHook => {
  const dragOrderRef = useRef<CacheSchema>([]);
  const localStorageKey = getLocalStorageKey();

  useEffect(() => {
    const cacheData = cacheSchema.parse(
      JSON.parse(String(localStorage.getItem(localStorageKey)))
    );
    dragOrderRef.current = cacheData || [];
  }, [localStorageKey]);

  const updateDraggable = (columns: CustomColumnType[]) => {
    dragOrderRef.current = columns
      .filter((c) => !c.fixed) // Ignore fixed columns order
      .map((c) => getCacheKey(c));

    localStorage.setItem(localStorageKey, JSON.stringify(dragOrderRef.current));
  };

  const sortDraggable = (a: CustomColumnType, b: CustomColumnType) => {
    const o = dragOrderRef.current;

    const aIndex = o.findIndex((columnKey) => findColumnKey(a, columnKey));

    const bIndex = o.findIndex((columnKey) => findColumnKey(b, columnKey));

    return aIndex - bIndex;
  };

  const resetDraggable = () => {
    localStorage.removeItem(getLocalStorageKey());
    dragOrderRef.current = [];
  };

  return {
    updateDraggable,
    sortDraggable,
    resetDraggable,
  };
};

export default useDraggable;

/* ------------------- Utils ------------------ */
const findColumnKey = (searchColumn: CustomColumnType, refKey: string) => {
  const searchKey = getCacheKey(searchColumn);
  return searchKey && refKey && searchKey === refKey;
};

const getLocalStorageKey = () => {
  return `${LS_TABLE_DRAG}${window.location.pathname}`;
};

/* -------------------- Zod ------------------- */
const cacheSchema = z.string().array().nullable();

type CacheSchema = NotNull<z.infer<typeof cacheSchema>>;
