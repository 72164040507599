/** Define dispatch actions for `useReducer`. Put action name in type and data in payload */
export type ActionMap<Actions extends Record<string, any>> = {
  [Key in keyof Actions]: Actions[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: Actions[Key];
      };
};

/** Extract first-level values from an object. */
export type OneOfValues<O> = O[keyof O];

export type OrNull<Type> = Type | null;

export type NotNull<T> = Exclude<T, null>;

export type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

export type PickRequired<T, K extends keyof T> = Pick<Required<T>, K> &
  Omit<T, K>;

export type RequiredWithType<T> = { [K in keyof Required<T>]: T[K] };

/** Required the key but Keep undefined type in value */
export type PickRequiredWithType<T, K extends keyof T> = Pick<
  RequiredWithType<T>,
  K
> &
  Omit<T, K>;

export enum PROMISE_STATE {
  PENDING = "pending",
  RESOLVE = "resolved",
  REJECT = "rejected",
}

export type AnyFunction = (...args: any[]) => any;
