import { useState } from "react";

const useFeedback = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [targetText, setTargetText] = useState<string>("");

  const openModalWith = (targetText: string) => {
    setTargetText(targetText);
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  return {
    open,
    openModalWith,
    closeModal,
    targetText,
  };
};

export default useFeedback;
