import { MoreOutlined } from "@ant-design/icons";
import { Button, Dropdown, DropdownProps } from "antd";
import { ItemType } from "antd/es/menu/hooks/useItems";

interface XCellMoreProps {
  items: ItemType[];
  dropdownSettings?: DropdownProps;
}

const XCellMore = ({ items, dropdownSettings }: XCellMoreProps) => {
  return (
    <Dropdown
      trigger={["click"]}
      arrow
      placement="bottomRight"
      {...dropdownSettings}
      menu={{ items }}
    >
      <Button icon={<MoreOutlined />} type="text" />
    </Dropdown>
  );
};

export default XCellMore;
