// For role validation
export const USER_ROLE = "user";
export const TRIAL_ROLE = "trial";

export const THEME = {
  // DEFAULT: "default",
  // COLORED: "colored",
  DARK: "dark",
  LIGHT: "light",
} as const;

export const THEME_PALETTE_LIGHT = {
  primary: "#3B82EC",
  "primary-dark": "#1659c7",
  "primary-light": "#84aef2",
  secondary: "#495057",
  success: "#4BBF73",
  info: "#1F9BCF",
  warning: "#f0ad4e",
  danger: "#d9534f",
  white: "#fff",
  "gray-100": "#f4f7f9",
  "gray-200": "#e2e8ee",
  "gray-300": "#dee6ed",
  "gray-400": "#ced4da",
  "gray-500": "#adb5bd",
  "gray-600": "#6c757d",
  "gray-700": "#495057",
  "gray-800": "#020202",
  "gray-900": "#212529",
  black: "#000",
};

export const THEME_PALETTE_DARK = {
  ...THEME_PALETTE_LIGHT,
  "primary-dark": "#84aef2",
  "primary-light": "#1659c7",
  white: "#293042",
  "gray-100": "#3e4555",
  "gray-200": "#545968",
  "gray-300": "#696e7b",
  "gray-400": "#7f838e",
  "gray-500": "#9498a1",
  "gray-600": "#a9acb3",
  "gray-700": "#bfc1c6",
  "gray-800": "#d4d6d9",
  "gray-900": "#eaeaec",
  black: "#fff",
};

export const RESOURCE_TYPE_COLOR = {
  vendor: "#faedcd",
  tooling: "#facdcd",
  machine: "#b7e4c7",
  operator: "#bde0fe",
  virtual: "#eeeeee",
  line: "#fefabd",
};

export const SOURCING_TYPE_COLOR = {
  "in-house": "#b7e4c7",
  "out-sourcing": "#facdcd",
};

export const TABLE_TAG_BASE_COLOR = "#eeeeee";
export const TABLE_TAG_TEXT_BASE_COLOR = THEME_PALETTE_LIGHT["gray-800"];
export const TABLE_TAG_LIGHT_TAG_TEXT_COLOR = "#111";

export const HEADER_HEIGHT = 64; // px

/* ---------------- Element ID ---------------- */
export const ID_TOUR_HEADER_MENU = "id_tour_header_menu";
export const ID_TOUR_HEADER_SHORTCUT = "id_tour_header_shortcut";
export const ID_TOUR_HEADER_ACTION = "id_tour_header_action";
export const ID_TOUR_HEADER_NOTIF = "id_tour_header_notif";
export const ID_TOUR_HEADER_PROFILE = "id_tour_header_profile";

/* --------------- LocalStorage --------------- */
export const LS_SHOW_TOUR = "react-show-tour";
export const LS_SIDEBAR_COLLAPSED_V0 = "react-sidebar-collapsed-v0";
export const LS_LANG_LOGIN = "react-lang-login";
export const LS_TABLE_PREFERENCE = "react-table-setting";
export const LS_TABLE_WIDTH = "react-table-width";
export const LS_TABLE_DRAG = "react-table-drag";
export const LS_TABLE_CUSTOM = "react-table-custom";
export const LS_TABLE_SORTING_V0 = "react-table-sorting-v0";
export const LS_THEME = "react-theme";
export const LS_PROJECT_MANGEMENT_JOB_INFO_COLUMNS_V0 =
  "react-project-management-job-info-columns-v0";
export const LS_PROJECT_MANGEMENT_TABS_ACTIVE_KEY =
  "react-project-management-tabs-active-key";

/* -------------- Form Validation ------------- */
export const PATTERN_PASSWORD = /^[\x21-\x7E]{4,}$/;

/* ---------------- Shared Component ---------------- */
/**
 * @description for RangeFilter and ConditionRangeWrapper
 */
export const DISPLAY_DATE_FORMAT = "YYYY-MM-DD HH:mm";
export const QUERY_DATE_FORMAT = "YYYYMMDDHHmm";
export const DEFAULT_START_QUERY_KEY = "start";
export const DEFAULT_END_QUERY_KEY = "end";
