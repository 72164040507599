import {
  Action,
  ThunkAction,
  combineReducers,
  configureStore,
} from "@reduxjs/toolkit";
import {
  STATE_KEY_PROJECT_EDIT_JOB,
  STATE_KEY_PROJECT_MANAGEMENT,
  STATE_KEY_RESOURCE_EDIT,
} from "./constants";
import dashboardReducer from "./slices/dashboard";
import notifReducer from "./slices/notification";
import { type InitialState as ProjectEditJobState } from "./slices/project/editJob/type";
import { type InitialState as ProjectManagementState } from "./slices/project/mangement/type";
import { type ResourceState } from "./slices/resource/resourceEdit";

/** Reducers
 * @note The keys used in the reducer object when configuring the store will determine the keys in the state object.
 * @note The nested reducers will be combined into one reducer and their state will be nested under the corresponding keys in the state object.
 */
const staticReducers = {
  notification: notifReducer,
  dashboard: dashboardReducer,
};

function createReducer(asyncReducers = {}) {
  return combineReducers({
    ...staticReducers,
    ...asyncReducers,
  });
}

/** Store
 * @note Use the reducer manager to create the root reducer.
 * @note Later, the reducer manager will be used to load other reducers dynamically.
 */
export default function configureAsyncStore() {
  const store = configureStore({
    reducer: createReducer(),
  });

  // Add a dictionary to keep track of the registered async reducers
  store.asyncReducers = {};

  // Create an inject reducer function
  // This function adds the async reducer, and creates a new combined reducer
  store.injectReducer = (key, asyncReducer) => {
    store.asyncReducers[key] = asyncReducer;
    store.replaceReducer(createReducer(store.asyncReducers));
  };

  // Return the modified store
  return store;
}

export const store = configureAsyncStore();

/** Typing */
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState> & {
  [STATE_KEY_RESOURCE_EDIT]: ResourceState;
  [STATE_KEY_PROJECT_MANAGEMENT]: ProjectManagementState;
  [STATE_KEY_PROJECT_EDIT_JOB]: ProjectEditJobState;
};
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
