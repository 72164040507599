import {
  createAsyncThunk,
  createSlice,
  type PayloadAction,
} from "@reduxjs/toolkit";

/** Axios API */
import ApiNotification from "../../utils/api/notification";

/** Plugins */
import { cloneDeep } from "lodash";

/** Types & Enums */
import {
  EVENT,
  type Levels,
  type MessageContent,
  type EventContent,
} from "../../types/notification";
import type { RootState } from "../store";

/** Utils */
import { sortedIndex } from "../../utils/data";
import logger from "../../utils/logger";

/** ---------- Types ---------- */
interface State {
  event: {
    [Key in EVENT]: EventContent;
  };
  pgBar: {
    stage: string;
    percentage: number;
    idle: boolean;
    sync: {
      status: boolean;
      last_IdleId: number;
    };
  };
}

/** Define action payload */
interface MessagePayload {
  event: EVENT;
  message: MessageContent;
}

interface ResetPayload {
  event: EVENT;
  id: number;
}

interface ReadPayload {
  event: EVENT;
  id: number;
}

interface IdlePaylaod {
  id: number;
}

/** ---------- Codes ---------- */
/** Message levels: the position of the elements is important */
const LEVELS: Array<undefined | Levels> = ["INFO", "WARNING", "ERROR"];

const initialEventContent: EventContent = {
  data: [],
  level: undefined,
  count: 0,
  sync: {
    status: false,
    MessageIdBeforeSync: [],
    last_readId: 0,
    last_MessageId: 0,
    last_ResetLevelId: 0,
  },
};

const initialState: State = {
  event: {
    [EVENT.SARA]: cloneDeep(initialEventContent),
    [EVENT.ABNOMRAL]: cloneDeep(initialEventContent),
  },
  pgBar: {
    stage: "",
    percentage: 0,
    idle: true,
    sync: {
      status: false,
      last_IdleId: 0,
    },
  },
};

const slice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    updateMessageData: (state, action: PayloadAction<MessagePayload>) => {
      const { event, message } = action.payload;
      const eventState = state.event[event];

      /** find the inserted index in descending order */
      const insertedIndex = sortedIndex({
        array: eventState.data,
        value: message,
        selector: (item) => item.id,
        direction: "desc",
        returnNullifDuplicate: true,
      });

      /** Skip duplicate message ID */
      if (insertedIndex !== null) {
        /** Insert message */
        eventState.data.splice(insertedIndex, 0, message);

        /** Truncate message size to 50 items */
        eventState.data = eventState.data.slice(0, 50);
      }

      /** Insert nessage data */
      insertMessage(eventState, message);

      /** Update last seen ID */
      eventState.sync.last_MessageId = message.id;

      /** Update level (uni-direction) */
      const idxCurrentLevel = LEVELS.indexOf(eventState.level);
      const idxIncomingLevel = LEVELS.indexOf(message.level);
      if (idxIncomingLevel !== -1 && idxIncomingLevel > idxCurrentLevel) {
        eventState.level = message.level;
      }

      /** Update count + 1 */
      eventState.count += 1;

      /** During socket disconnection, records incoming message id before next connection are established. */
      if (eventState.sync.status === false) {
        eventState.sync.MessageIdBeforeSync.push(message.id);
      }

      /** EVENT.SARA specific action: update progress bar */
      if (event === EVENT.SARA) {
        const pgState = state.pgBar;

        /** Update progress bar if key `pg_info` exist */
        if (message.pg_info !== undefined) {
          const { stage, perc_absolute } = message.pg_info;
          pgState.stage = stage;
          pgState.percentage = perc_absolute;
        }

        /** Update progress bar idle status */
        pgState.idle = false;
      }
    },

    resetMessageLevel: (state, action: PayloadAction<ResetPayload>) => {
      const { event, id } = action.payload;
      if (typeof id !== "number") return;

      const eventState = state.event[event];
      if (id > eventState.sync.last_ResetLevelId) {
        eventState.sync.last_ResetLevelId = id;
        eventState.level = event === EVENT.SARA ? "INFO" : undefined;
      }
    },

    readMessage: (state, action: PayloadAction<ReadPayload>) => {
      const { event, id } = action.payload;
      if (typeof id !== "number") return;

      const eventState = state.event[event];
      if (id > eventState.sync.last_readId) {
        eventState.sync.last_readId = id;
        eventState.count = 0;
      }

      /**
       * @note In the distributed system, the read count may be less than the acutal count if somehow the message event (larger ID) is recieved ahead of the read event (smaller ID). Below code compensate this issue.
       */
      const fixCount = countAfterId(
        /** ID was sort desending, change the order to ascending */
        eventState.data.map((m) => m.id).reverse(),
        id
      );
      eventState.count += fixCount;
    },

    idlePgBar: (state, action: PayloadAction<IdlePaylaod>) => {
      const { id } = action.payload;
      if (typeof id !== "number") return;

      const pgState = state.pgBar;
      if (id > pgState.sync.last_IdleId) {
        pgState.sync.last_IdleId = id;
        pgState.idle = true;
      }
    },

    unSync: (state) => {
      Object.keys(state.event).forEach((eventName) => {
        state.event[eventName as EVENT].sync.status = false;
      });
      state.pgBar.sync.status = false;
    },
  },
  extraReducers(builder) {
    builder.addCase(syncMessage.rejected, (_state, action) => {
      logger.error(action.payload);
    });

    builder.addCase(syncMessage.fulfilled, (state, action) => {
      const { event, data } = action.payload;
      if (data === null) return;

      const eventState = state.event[event];
      const pgState = state.pgBar;
      const {
        messages,
        last_message_id,
        last_read_id,
        last_count,
        last_level,
        last_reset_id,
        last_idle_status,
        last_idle_id,
        pg_name,
        pg_perc,
      } = data;

      /** Sync message data */
      messages.forEach((message: MessageContent) => {
        /** Insert nessage data */
        insertMessage(eventState, message);
      });

      /** Sync message count */
      const historyCount = countAfterId(
        eventState.sync.MessageIdBeforeSync,
        last_message_id
      );
      eventState.count = last_count + historyCount;

      /** Sync last-read message id */
      eventState.sync.last_readId = last_read_id;

      /** Sync last-seen message id */
      if (last_message_id > eventState.sync.last_MessageId) {
        eventState.sync.last_MessageId = last_message_id;
      }

      /** Sync message level */
      if (
        last_reset_id > eventState.sync.last_ResetLevelId ||
        last_reset_id === 0
      ) {
        eventState.level = last_level;
      }
      eventState.sync.last_ResetLevelId = last_reset_id;

      /** Sync progress bar idle status */
      if (last_idle_id > pgState.sync.last_IdleId) {
        pgState.idle = last_idle_status;
      }
      pgState.sync.last_IdleId = last_idle_id;

      /** Sync progress bar stage and pecentage */
      pgState.stage = pg_name;
      pgState.percentage = pg_perc;

      /** Set sync status to true */
      eventState.sync.status = true;
      pgState.sync.status = true;
    });
  },
});

/** Util Functions */
const insertMessage = (eventState: EventContent, message: MessageContent) => {
  /** find the inserted index in descending order */
  const insertedIndex = sortedIndex({
    array: eventState.data,
    value: message,
    selector: (item) => item.id,
    direction: "desc",
    returnNullifDuplicate: true,
  });

  /** Skip duplicate message ID */
  if (insertedIndex !== null) {
    /** Insert message */
    eventState.data.splice(insertedIndex, 0, message);

    /** Truncate message size to 50 items */
    eventState.data = eventState.data.slice(0, 50);
  }
};

const countAfterId = (array: number[], id: number): number => {
  const idx = sortedIndex({ array, value: id }) as number;
  let count = 0;
  if (idx < array.length) {
    let startIndex = array[idx] === id ? idx + 1 : idx;
    count = array.slice(startIndex).length;
  }
  return count;
};

/** Async thunks */
export const syncMessage = createAsyncThunk(
  "notification/syncMessage",
  async (event: EVENT, thunkAPI) => {
    const api = new ApiNotification();
    const data = await api.sync({ event, limit: 50 });

    return data
      ? { event, data }
      : thunkAPI.rejectWithValue(`Cannot sync message: ${event}`);
  }
);

/** Actions */
export const {
  updateMessageData,
  resetMessageLevel,
  readMessage,
  idlePgBar,
  unSync,
} = slice.actions;

/** Selectors */
export const selectSaraMessage = (state: RootState) =>
  state.notification.event[EVENT.SARA];

export const selectAbnormalMessage = (state: RootState) =>
  state.notification.event[EVENT.ABNOMRAL];

export const selectProgressBar = (state: RootState) => state.notification.pgBar;

/** Reducer */
export default slice.reducer;
