import { STATE_KEY_RESOURCE_EDIT } from "@/redux/constants";
import { RootState, store } from "@/redux/store";
import {
  Capabilities,
  ItemJlb,
  ItemWorkcenter,
  ResourceForm,
} from "@/utils/api/resource";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  filteredCapabilityItems,
  removeAllItems,
  removeWorkcenterItems,
  selectAllItems,
  setCapabilityItems,
  setSelectedItems,
  toggleSelectItem,
} from "./resourceEdit.util";

/**
 * @property workcenter 當前所選的工作中心
 * @property workcenters 所有的工作中心清單
 * @property selectedCapability 所選的所有製程
 * @property currentCapability 當前的站點製程
 * @property lines 所有的線別選項
 * @property selectedLines 所選的線別
 */
export interface ResourceState {
  formData: ResourceForm["values" | "default"] | undefined;
  options: ResourceForm["options"] | undefined;
  workcenterId: ItemWorkcenter["id"] | undefined;
  workcenters: ItemWorkcenter[];
  selectedCapability: Capabilities;
  capabilities: Capabilities;
  selectedLines: string[];
  capabilityState: CapabilityState;
}

interface CapabilityState {
  isWorkcenterLoading: boolean;
  isWorkcenterSuccess: boolean | undefined;
  isCapabilityLoading: boolean;
  isCapabilitySuccess: boolean | undefined;
}

const initialState: ResourceState = {
  formData: undefined,
  options: undefined,
  workcenterId: undefined,
  workcenters: [],
  capabilities: {},
  selectedCapability: {},
  selectedLines: [],
  capabilityState: {
    isWorkcenterLoading: false,
    isWorkcenterSuccess: undefined,
    isCapabilityLoading: false,
    isCapabilitySuccess: undefined,
  },
};

export const ResourceSlice = createSlice({
  name: "resourceEdit",
  initialState,
  reducers: {
    setFormData: (
      state,
      action: PayloadAction<ResourceForm["values" | "default"]>
    ) => {
      state.formData = action.payload;
    },
    setOptions: (state, action: PayloadAction<ResourceForm["options"]>) => {
      state.options = action.payload;
    },
    setCapabilityState: (state, action: PayloadAction<CapabilityState>) => {
      state.capabilityState = action.payload;
    },
    setWorkcenters: (state, action) => {
      state.workcenters = action.payload;
    },
    setSelectedWorkcenter: (state, action) => {
      state.workcenterId = action.payload;
    },
    setSelectedLines: (state, action) => {
      state.selectedLines = action.payload;
    },
    setCapabilities: (
      state: ResourceState,
      action: PayloadAction<Capabilities[]>
    ) => {
      setCapabilityItems(state, action);
    },
    setSelectedCapability: (state, action) => {
      setSelectedItems(state, action);
    },
    filteredCapability: (state, action) => {
      filteredCapabilityItems(state, action);
    },
    selectAll: (state: ResourceState, action: PayloadAction<ItemJlb[]>) => {
      selectAllItems(state, action);
    },
    removeAll: (state: ResourceState, action: PayloadAction<ItemJlb[]>) => {
      removeAllItems(state, action);
    },
    removeWorkcenter: (state, action: PayloadAction<number>) => {
      removeWorkcenterItems(state, action);
    },
    toggleSelect: (state, action: PayloadAction<ItemJlb>) => {
      toggleSelectItem(state, action);
    },
    resetFormData: (state) => {
      state.formData = undefined;
    },
    resetAll: (state: ResourceState) => {
      return initialState;
    },
    resetCapability: (state) => {
      state.selectedCapability = {};
    },
  },
});

export const {
  setFormData,
  setOptions,
  setCapabilityState,
  setSelectedWorkcenter,
  setWorkcenters,
  setSelectedCapability,
  filteredCapability,
  setSelectedLines,
  setCapabilities,
  selectAll,
  resetAll,
  removeAll,
  resetFormData,
  removeWorkcenter,
  toggleSelect,
  resetCapability,
} = ResourceSlice.actions;

export const formData = (state: RootState) => state.resourceEdit.formData;
export const options = (state: RootState) => state.resourceEdit.options;
export const workcenters = (state: RootState) => state.resourceEdit.workcenters;
export const capabilities = (state: RootState) =>
  state.resourceEdit.capabilities;
export const selectedLines = (state: RootState) =>
  state.resourceEdit.selectedLines;
export const selectedWorkcenter = (state: RootState) => {
  return state.resourceEdit.workcenterId;
};
export const selectedCapability = (state: RootState) =>
  state.resourceEdit.selectedCapability;
export const capabilityState = (state: RootState) =>
  state.resourceEdit.capabilityState;

export default ResourceSlice.reducer;

store.injectReducer(STATE_KEY_RESOURCE_EDIT, ResourceSlice.reducer);
