import { SSRParams, SSRResponse } from "@/hooks/useTableSSR";
import { ResourceType } from "@/types/resource";
import { ApiService } from "../axios";

/* ------------------- Types ------------------ */
type EndpointName = keyof typeof endpoints;

interface Api_GetBasicInfo_Response {
  data: {
    id: number;
    resource_name: string;
    resource_type: string;
    capacity_type: string;
    standard_capacity: number;
    status: string;
    workinghours: string;
    workingdays: string;
  };
}

interface Api_GetLineList_Response {
  data: string[];
}

interface Api_GetJobList_Response {
  data: {
    workcenter_name: string;
    primary: string[];
  }[];
}

interface Api_GetRecentEvent_Payload {
  ref_time: string;
}

interface Api_GetRecentEvent_Response {
  data: {
    id: number;
    event_name: string;
    started_on: string;
    ended_on: string;
  }[];
}

interface Api_GetLoadingChart_Payload {
  resource_id: number;
  start: string;
  end: string;
  level: "day" | "month";
}

export type Api_GetLoadingChart_Response = {
  datasets: { data: number[] }[];
  labels: string[];
} | null;

interface Api_GetScheduleJob_Payload {
  start: string;
  end: string;
}

export interface Api_GetScheduleJob_Response {
  data: {
    job_id: number;
    job_name: string;
    mo_nbr: string;
    product_name: string;
    lot_nbr: string;
    workcenter_name: string;
    est_time: number;
    job_seq_order: number;
    plan_start_time: string;
    plan_end_time: string;
    remaining_qty: number;
    instant_state: string;
  }[];
}

export interface ResourceForm {
  values?: Values;
  default?: Default;
  options: Options;
  error?: { _others?: string[] };
}

interface Options {
  capacity_type: string[][];
  is_extra: string[][];
  line: any[];
  resource_type: string[][];
  workingday: (number | string)[][];
  workinghour: (number | string)[][];
}

export interface Values {
  capacity_type: string;
  changeover_time: number;
  disabled: boolean;
  is_extra: boolean;
  jlb: Jlb[];
  line: any[];
  lock_time: number;
  resource_name: string;
  resource_type: string;
  standard_capacity: number;
  workingday: number;
  workinghour: number;
}

export interface Default {
  workingday: number;
  workinghour: number;
}

interface Jlb {
  id: number;
  job_name: string;
  workcenter_name: string;
}

export interface Api_GetResourceForm_Response extends ResourceForm {
  default?: Default;
}

interface Api_GetResourceWorkcenters_Response {
  data: ItemWorkcenter[];
}

export interface ItemWorkcenter {
  id: number;
  name: string;
}

interface Api_GetResourceJlbs_Response {
  data: Capabilities[];
}

export interface ItemJlb {
  id: number;
  workcenter_name: string;
  job_name: string;
  sourcing: "in-house" | "out-sourcing";
}

export interface Capabilities {
  [workcenterId: string]: ItemJlb[];
}

export interface FormValues {
  id?: number;
  data: Data;
}

interface Data {
  resource_type: string;
  is_extra: boolean;
  capacity_type: string;
  standard_capacity: number;
  workingday: number;
  workinghour: number;
  lock_time: number;
  changeover_time: number;
  disabled: boolean;
  jlb: string[];
  line: string[];
}

type Api_GetResourceItem_Response = [string, string][];

/** API_GetResourceManagement_Payload */
export interface ResourceItem {
  id: number;
  resource_name: string;
  resource_type: ResourceType;
  workcenter_name: string;
}

export interface Api_GetResourceTable_Response extends SSRResponse {
  id: number;
  resource_name: string;
  resource_type: "Vendor" | "Machine" | "Operator" | "Virtual" | "Tooling";
  capacity_type: "M" | "U";
  standard_capacity: number;
  calendar_name: string;
  hours_name: string;
  workcenter_name: string[];
}

export interface Api_DownloadResourceCsv_Payload {
  table: ResourceTableType;
}

export interface Api_DownloadResourceZip_Payload {
  table: ResourceTableType[];
}

export const enum ResourceTableType {
  ResourceSettings = "resource_settings",
  Capability = "resource_jobs",
  Calendar = "wd_custom",
  EventHistory = "event_history",
}

export interface Api_GetResourceItemForEvent_Response {
  data: {
    id: number;
    name: string;
    type: string;
  }[];
}

export interface Api_NewResourceEvent_Payload {
  resource_name: string;
  event_name: string;
  started_on: string;
  ended_on: string;
}

export interface Api_EditResourceEvent_Payload {
  id: number;
  started_on: string;
  ended_on: string;
}

export interface Api_DeleteResourceEvent_Payload {
  id: number[];
}

export interface Api_GetResourceEventTable_ExtraFilter {
  filter?: {
    event_start?: string;
    event_end?: string;
  };
}

export interface Api_GetResourceEventTable_Response extends SSRResponse {
  data: {
    id: number;
    resource_id: number;
    resource_name: string;
    event_name: string;
    created_on: string;
    started_on: string;
    ended_on: string;
  }[];
}

/* ----------------- API Path ----------------- */
const enum EndPoint {
  GetBasicInfo = "getBasicInfo",
  GetLineList = "getLineList",
  GetJobList = "getJobList",
  GetRecentEvent = "getRecentEvent",
  GetLoadingChart = "getLoadingChart",
  GetScheduleJob = "getScheduleJob",
  GetResourceItem = "getResourceItem",
  getResourceList = "getResourceList",
  getResourceTable = "getResourceTable",
  getResourceTableFilterValues = "getResourceTableFilterValues",
  downloadTable = "downloadTable",
  downloadResourceCsv = "downloadResourceCsv",
  downloadResourceZip = "downloadResourceZip",
  getResourceItemForEvent = "getResourceItemForEvent",
  getResourceEventTable = "getResourceEventTable",
  getResourceEventTableFilterValues = "getResourceEventTableFilterValues",
  downloadResourceEventTable = "downloadResourceEventTable",
  exportResourceEvent = "exportResourceEvent",
  newResourceEvent = "newResourceEvent",
  editResourceEvent = "editResourceEvent",
  deleteResourceEvent = "deleteResourceEvent",
  GetResourceForm = "getResourceForm",
  AddResourceForm = "addResourceForm",
  UpdateResourceForm = "updateResourceForm",
  DeleteResourceForm = "deleteResourceForm",
  GetResourceWorkcenters = "getResourceWorkcenters",
  GetResourceJlbs = "getResourceJobs",
}

const endpoints = {
  [EndPoint.GetBasicInfo]: {
    url: "/api/resource/{id}",
    method: "get",
  },
  [EndPoint.GetLineList]: {
    url: "/api/resource/{id}/line-list",
    method: "get",
  },
  [EndPoint.GetJobList]: {
    url: "/api/resource/{id}/job-list",
    method: "get",
  },
  [EndPoint.GetRecentEvent]: {
    url: "/api/resource/{id}/recent-event-list",
    method: "post",
  },
  [EndPoint.GetLoadingChart]: {
    url: "/api/resource/loading-analysis",
    method: "post",
  },
  [EndPoint.GetScheduleJob]: {
    url: "/api/resource/{id}/schedule-result",
    method: "post",
  },
  [EndPoint.GetResourceItem]: {
    url: "/api/resource/item",
    method: "get",
  },
  [EndPoint.getResourceList]: {
    url: "/api/resource/item/list",
    method: "post",
  },
  [EndPoint.getResourceTable]: {
    url: "/api/resource/item/list",
    method: "post",
  },
  [EndPoint.getResourceTableFilterValues]: {
    url: "/api/resource/item/list",
    method: "post",
  },
  [EndPoint.downloadTable]: {
    url: "/api/resource/item/list",
    method: "post",
  },
  [EndPoint.downloadResourceCsv]: {
    url: "/download/csv",
    method: "post",
  },
  [EndPoint.downloadResourceZip]: {
    url: "/download/zip",
    method: "post",
  },
  [EndPoint.getResourceItemForEvent]: {
    url: "/api/resource/item/event-use",
    method: "get",
  },
  [EndPoint.getResourceEventTable]: {
    url: "/api/resource/event/table",
    method: "post",
  },
  [EndPoint.getResourceEventTableFilterValues]: {
    url: "/api/resource/event/table",
    method: "post",
  },
  [EndPoint.downloadResourceEventTable]: {
    url: "/api/resource/event/table",
    method: "post",
  },
  [EndPoint.exportResourceEvent]: {
    url: "/download/csv",
    method: "post",
  },
  [EndPoint.newResourceEvent]: {
    url: "/api/event/crud",
    method: "post",
  },
  [EndPoint.editResourceEvent]: {
    url: "/api/event/crud",
    method: "put",
  },
  [EndPoint.deleteResourceEvent]: {
    url: "/api/event/crud",
    method: "delete",
  },
  [EndPoint.GetResourceForm]: {
    url: "/api/resource/form",
    method: "get",
  },
  [EndPoint.AddResourceForm]: {
    url: "/api/resource/form",
    method: "post",
  },
  [EndPoint.UpdateResourceForm]: {
    url: "/api/resource/form",
    method: "put",
  },
  [EndPoint.DeleteResourceForm]: {
    url: "/api/resource/form",
    method: "delete",
  },
  [EndPoint.GetResourceWorkcenters]: {
    url: "/api/resource/form/item-workcenter",
    method: "post",
  },
  [EndPoint.GetResourceJlbs]: {
    url: "/api/resource/form/item-jlb",
    method: "post",
  },
} as const;

/* ---------------- API Service --------------- */
class ResourceApi extends ApiService<EndpointName> {
  _endpoints = endpoints;

  getBasicInfo = (id: string | number) => {
    return this.send<Api_GetBasicInfo_Response>(
      EndPoint.GetBasicInfo,
      undefined,
      { pathVars: { id } }
    );
  };

  getLineList = (id: string | number) => {
    return this.send<Api_GetLineList_Response>(
      EndPoint.GetLineList,
      undefined,
      { pathVars: { id } }
    );
  };

  getJobList = (id: string | number) => {
    return this.send<Api_GetJobList_Response>(EndPoint.GetJobList, undefined, {
      pathVars: { id },
    });
  };

  getRecentEvent = (id: string | number, data: Api_GetRecentEvent_Payload) => {
    return this.send<Api_GetRecentEvent_Response>(
      EndPoint.GetRecentEvent,
      { data },
      { pathVars: { id } }
    );
  };

  getLoadingChart = (data: Api_GetLoadingChart_Payload) => {
    return this.send<Api_GetLoadingChart_Response>(EndPoint.GetLoadingChart, {
      data,
    });
  };

  getScheduleResult = (
    id: string | number,
    data: Api_GetScheduleJob_Payload
  ) => {
    return this.send<Api_GetScheduleJob_Response>(
      EndPoint.GetScheduleJob,
      { data },
      { pathVars: { id } }
    );
  };

  getResourceItem = () => {
    return this.send<Api_GetResourceItem_Response>(EndPoint.GetResourceItem);
  };

  getResourceList = (data: SSRParams) => {
    return this.send(EndPoint.getResourceList, { data });
  };

  getResourceTable = (data: SSRParams) => {
    return this.send<Api_GetResourceTable_Response>(EndPoint.getResourceTable, {
      data,
    });
  };

  getResourceTableFilterValues = (data: SSRParams) => {
    return this.send<SSRResponse>(EndPoint.getResourceTableFilterValues, {
      data: { ...data, include_data: false },
    });
  };

  downloadTable = (data: SSRParams) => {
    return this.send(EndPoint.downloadTable, {
      data: { ...data, download: true },
    });
  };

  downloadResourceCsv = (data: Api_DownloadResourceCsv_Payload) => {
    return this.send(EndPoint.downloadResourceCsv, { data });
  };

  downloadResourceZip = (data: Api_DownloadResourceZip_Payload) => {
    return this.send(EndPoint.downloadResourceZip, {
      data,
      responseType: "blob",
    });
  };

  getResourceItemForEvent = () => {
    return this.send<Api_GetResourceItemForEvent_Response>(
      EndPoint.getResourceItemForEvent
    );
  };

  getResourceEventTable = (
    data: SSRParams,
    extra: Api_GetResourceEventTable_ExtraFilter
  ) => {
    return this.send<Api_GetResourceEventTable_Response>(
      EndPoint.getResourceEventTable,
      { data: { ...data, extra } }
    );
  };

  getResourceEventTableFilterValues = (
    data: SSRParams,
    extra: Api_GetResourceEventTable_ExtraFilter
  ) => {
    return this.send<SSRResponse>(EndPoint.getResourceEventTableFilterValues, {
      data: { ...data, extra, include_data: false },
    });
  };

  downloadEventTable = (
    data: SSRParams,
    extra: Api_GetResourceEventTable_ExtraFilter
  ) => {
    return this.send(EndPoint.downloadResourceEventTable, {
      data: { ...data, extra, download: true },
    });
  };

  exportEvent = () => {
    return this.send(EndPoint.exportResourceEvent, {
      data: { table: "event_history" },
    });
  };

  newResourceEvent = (data: Api_NewResourceEvent_Payload) => {
    return this.send(EndPoint.newResourceEvent, { data });
  };

  editResourceEvent = (data: Api_EditResourceEvent_Payload) => {
    return this.send(EndPoint.editResourceEvent, { data });
  };

  deleteResourceEvent = (data: Api_DeleteResourceEvent_Payload) => {
    return this.send(EndPoint.deleteResourceEvent, { data });
  };

  getResourceForm = (id: string | number | undefined) => {
    return this.send<Api_GetResourceForm_Response>(
      EndPoint.GetResourceForm,
      id ? { params: { id } } : undefined
    );
  };

  addResourceForm = (data: FormValues) => {
    return this.send<ResourceForm>(EndPoint.AddResourceForm, { data });
  };

  updateResourceForm = (data: FormValues) => {
    return this.send<ResourceForm>(EndPoint.UpdateResourceForm, { data });
  };

  deleteResourceForm = (id: string | number) => {
    return this.send<ResourceForm>(EndPoint.DeleteResourceForm, {
      data: { id_list: [id] },
    });
  };

  getResourceWorkcenters = () => {
    return this.send<Api_GetResourceWorkcenters_Response>(
      EndPoint.GetResourceWorkcenters
    );
  };

  getResourceJlbs = (id: number) => {
    return this.send<Api_GetResourceJlbs_Response>(EndPoint.GetResourceJlbs, {
      data: { workcenter_id_list: [id] },
    });
  };
}

export default ResourceApi;
