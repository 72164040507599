/**
 * @note Typing is provided in `src/@types/styled.d.ts` to overwrite the default theme.
 */

import useMedia from "@/hooks/useMedia";
import useTheme from "@/hooks/useTheme";
import { theme } from "antd";
import React from "react";
import { ThemeProvider } from "styled-components";
import { breakpoint } from "./MediaContext";

const SThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const { token } = theme.useToken();
  const { dark } = useTheme();
  const media = useMedia();

  return (
    <ThemeProvider theme={{ antd: token, dark, media, breakpoint }}>
      {children}
    </ThemeProvider>
  );
};

export default SThemeProvider;
