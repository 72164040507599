import { useTranslation } from "react-i18next";
import FormDivider from "../FormDivider";
import WorkingDays from "./WorkingDays";
import WorkingHours from "./WorkingHours";

const WorkingDayHoursSection = () => {
  const { t } = useTranslation("resources", {
    keyPrefix: "_pages.edit",
  });

  return (
    <section style={{ width: "100%" }}>
      <FormDivider title={t("workingDayHours")} />
      <WorkingDays />
      <WorkingHours />
    </section>
  );
};

export default WorkingDayHoursSection;
