import { Form, InputNumber } from "antd";
import { useTranslation } from "react-i18next";
import { SFormLabel } from "../styledForm";

const checkIsLineType = (category: string): boolean => {
  return category === "Line";
};

const LineCapacity = () => {
  const form = Form.useFormInstance();
  const ResourceType = Form.useWatch("resource_type", form);

  const { t } = useTranslation("resources", {
    keyPrefix: "_pages.edit",
  });

  if (!checkIsLineType(ResourceType))
    return (
      <Form.Item name="line_capacity" noStyle>
        <InputNumber style={{ display: "none" }} />
      </Form.Item>
    );

  const rules = [{ required: true, message: t("lineCapacityRequired") }];

  return (
    <Form.Item
      name="line_capacity"
      label={<SFormLabel>{t("lineCapacity")}</SFormLabel>}
      rules={rules}
    >
      <InputNumber
        required
        min={1}
        max={1000}
        precision={0}
        placeholder={t("lineCapacityPlaceholder")}
        style={{ width: "100%" }}
      />
    </Form.Item>
  );
};

export default LineCapacity;
