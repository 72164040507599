import { useEffect, useState } from "react";

/** Antd */
import { Menu, MenuProps, Tooltip } from "antd";

/** Hooks */
import useMedia from "../../hooks/useMedia";

/** Icons */
import { FiMenu } from "react-icons/fi";
import { TbLayoutSidebarLeftCollapse } from "react-icons/tb";

/** Plugins */
import { useLocation } from "react-router-dom";
import styled from "styled-components";

/* ------------------- Types ------------------ */
interface NewSidebarProps {
  collapsed: boolean;
  open: () => void;
  close: () => void;
  menuItemsData: MenuProps["items"];
}

/* ---------------- Code Starts --------------- */

const NewSidebar = ({
  collapsed,
  open,
  close,
  menuItemsData,
}: NewSidebarProps) => {
  const { md } = useMedia();

  const { pathname } = useLocation();
  const [current, setCurrent] = useState(pathname);

  /** make menu item active depending on current URL */
  useEffect(() => {
    if (current !== pathname) {
      setCurrent(pathname);
    }
  }, [pathname, current]);

  return (
    <SSidebar isMobile={!md}>
      <SMenuCloseBtn onClick={close}>
        <TbLayoutSidebarLeftCollapse />
      </SMenuCloseBtn>
      <Menu
        mode="inline"
        items={menuItemsData}
        style={{ border: "none", background: "transparent" }}
        onClick={(e) => setCurrent(e.key)}
        selectedKeys={[current]}
      />
      {md && collapsed ? (
        <SMenuOpenColumn onClick={open}>
          <Tooltip title="開啟側邊欄" placement="right">
            <FiMenu />
          </Tooltip>
        </SMenuOpenColumn>
      ) : null}
    </SSidebar>
  );
};

export default NewSidebar;

/* ------------- Styled Components ------------ */
const SSidebar = styled.div<{ isMobile: boolean }>`
  height: 100%;
  width: ${(props) => (props.isMobile ? "100%" : "200px")};
  background-color: var(--bs-white);
  overflow-y: auto;
  border-right: 1px solid var(--bs-light);
`;

const SMenuCloseBtn = styled.button`
  padding: 1rem;
  border: none;
  background-color: transparent;
  transition: color 0.3s;
  font-size: 1.3rem;
  color: var(--bs-dark);

  &:hover {
    color: var(--bs-orange);
  }
`;

const SMenuOpenColumn = styled.div`
  font-size: 1.2rem;
  position: absolute;
  left: 100%;
  top: 0;
  bottom: 0;
  z-index: 100;
  width: var(--sara-sidebar-collapsed-width);
  border-right: 1px solid var(--bs-light);
  background-color: ${({ theme }) =>
    theme.dark ? "#293042" : "var(--bs-body-bg)"};
  cursor: pointer;

  box-shadow: var(--bs-light) 0px 0px 0px 1px;
  transition: all 0.3s;

  display: block;
  text-align: center;
  padding-top: 1.5rem;
  &:hover {
    color: var(--bs-orange);
    filter: ${({ theme }) =>
      theme.dark ? "brightness(1.2)" : "brightness(0.96)"};
  }
`;
