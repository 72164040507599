/**
 * @note 在編輯資源 form 中的 precision 和 max 的值
 */

export const LOCK_TIME_DEFAULT = "0.0";
export const LOCK_TIME_PRECISION = 1;
export const LOCK_TIME_RANGE = {
  min: 0,
  max: 720,
};

export const CHANGE_OVER_TIME_DEFAULT = "0";
export const CHANGE_OVER_TIME_PRECISION = 0;
export const CHANGE_OVER_TIME_RANGE = {
  min: 0,
  max: 10080,
};

/**
 * @note 編輯資源的製程能力顏色
 */
export const SELECTED_WORKCENTER_COLOR = "#e9c5934e";
