import React, { useEffect } from "react";

/** Hooks */
import useAuth from "../../hooks/useAuth";
import { useTranslation } from "react-i18next";

interface RestoreLanguageType {
  children: React.ReactNode;
}

const RestoreLanguage = ({ children }: RestoreLanguageType) => {
  const { user } = useAuth();
  const { i18n } = useTranslation();

  useEffect(() => {
    const restoreLang = user?.lang;
    if (restoreLang && i18n.language !== restoreLang) {
      i18n.changeLanguage(restoreLang);
    }
  }, [user, i18n]);

  return <React.Fragment>{children}</React.Fragment>;
};

export default RestoreLanguage;
