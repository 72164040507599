import { useCallback, useState } from "react";
import { BasicMenuItem } from "../FunctionButton";

interface Modal {
  isOpen: boolean;
  open: () => void;
  close: () => void;
  data: BasicMenuItem | null;
  setData: React.Dispatch<React.SetStateAction<BasicMenuItem | null>>;
}

const useModal = (): Modal => {
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState<Modal["data"]>(null);

  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);

  return {
    isOpen,
    open,
    close,
    data,
    setData,
  };
};

export default useModal;
