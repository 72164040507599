import AuthApi from "@/utils/api/auth";
import { createContext, ReactNode, useEffect, useReducer } from "react";
import useApi from "../hooks/useApi";
import { AuthState, AuthUser, CookieContextType } from "../types/auth";
import { ActionMap, OneOfValues } from "../types/util";
import { App } from "antd";

/** Define available auth `action` */
const INITIALIZE = "INITIALIZE";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";

/** Define initial auth `states` */
const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

/** Associate auth action with its `payload` */
type AuthActionTypes = {
  [INITIALIZE]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [SIGN_IN]: {
    user: AuthUser;
  };
  [SIGN_OUT]: undefined;
};

type AuthActions = OneOfValues<ActionMap<AuthActionTypes>>;

const reducer = (state: AuthState, action: AuthActions) => {
  switch (action.type) {
    case INITIALIZE: {
      const { isAuthenticated, user } = action.payload;
      return {
        ...state,
        isAuthenticated,
        isInitialized: true,
        user,
      };
    }
    case SIGN_IN: {
      const { user } = action.payload;
      return { ...state, isAuthenticated: true, user };
    }
    case SIGN_OUT: {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    }
    default:
      return state;
  }
};

const AuthContext = createContext<CookieContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const api = useApi(AuthApi);
  const { notification } = App.useApp();

  useEffect(() => {
    const initialize = async () => {
      /** Use try-catch block to ensure isInitialized is set to true even if unexpected error occured. Some child componenet, e.g., GuestGuard and AuthGuard, only rendered when initialized. */
      try {
        const { isAuthenticated, user, message } = await api.auth();
        if (isAuthenticated) {
          dispatch({
            type: INITIALIZE,
            payload: { isAuthenticated, user: user || null },
          });
          return;
        }

        if (message) {
          /** 情境：超過活躍上線人數限制，登入失敗 */
          notification.warning({
            message,
            placement: "top",
            duration: 30,
          });
        }
      } catch (err) {}

      dispatch({
        type: INITIALIZE,
        payload: { isAuthenticated: false, user: null },
      });
    };
    initialize();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signIn = async (
    email: string,
    password: string
  ): Promise<string | void> => {
    const { isAuthenticated, user, errorMessage } = await api.signIn(
      email,
      password
    );

    if (isAuthenticated) {
      /* ---------------- Temp. Code ---------------- */
      /** Redirect user to SSR page
       * !Hardcode Solution. Replace this later.
       */
      if (process.env.NODE_ENV === "production") {
        window.location.href = "/";
        return;
      }
      /* ---------------- Temp. Code ---------------- */

      dispatch({ type: SIGN_IN, payload: { user: user || null } });
    } else {
      return errorMessage || "Unable to login.";
    }
  };

  const signOut = async () => {
    (await api.signOut()) && dispatch({ type: SIGN_OUT });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        signIn,
        signOut,
        isAdmin: ["admin", "system"].includes(state.user?.role || ""),
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
