import ApiStatus from "@/components/dashboard/common/ApiStatus";
import useAppDispatch from "@/hooks/useAppDispatch";
import useAppSelector from "@/hooks/useAppSelector";
import {
  capabilityState,
  selectedWorkcenter,
  setSelectedWorkcenter,
  workcenters,
} from "@/redux/slices/resource/resourceEdit";
import { Typography } from "antd";
import { FixedSizeList } from "react-window";
import { SList, SSelectedIcon, SWorkcenterOption } from "../styledCapability";

const SelectedWorkcenter = () => {
  const list = useAppSelector(workcenters);
  const selected = useAppSelector(selectedWorkcenter);
  const loadingState = useAppSelector(capabilityState);

  const dispatch = useAppDispatch();

  const handleSelectWorkcenter = (workcenter: number): void => {
    dispatch(setSelectedWorkcenter(workcenter));
  };

  return (
    <SList>
      <ApiStatus
        loading={loadingState.isWorkcenterLoading}
        success={loadingState.isWorkcenterSuccess}
      >
        {list.length > 0 && (
          <FixedSizeList
            height={230}
            itemCount={list.length}
            itemData={list}
            itemSize={38.5}
            width="100%"
            itemKey={(index, data) => {
              return data[index].id;
            }}
          >
            {({ data, index, style }) => {
              const item = data[index];
              return (
                <SWorkcenterOption
                  key={item.id}
                  $isSelected={item.id === selected}
                  onClick={() => handleSelectWorkcenter(item.id)}
                  style={style}
                >
                  <Typography.Text
                    ellipsis={{ tooltip: item.name }}
                    style={{ paddingRight: "8px" }}
                  >
                    {item.name}
                  </Typography.Text>
                  {item.id === selected && <SSelectedIcon />}
                </SWorkcenterOption>
              );
            }}
          </FixedSizeList>
        )}
      </ApiStatus>
    </SList>
  );
};

export default SelectedWorkcenter;
