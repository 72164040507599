import React, { useEffect } from "react";

/** Hooks */
import useSocket from "../../hooks/useSocket";

/** Redux */
import useAppDispatch from "../../hooks/useAppDispatch";
import {
  updateMessageData,
  resetMessageLevel,
  readMessage,
  syncMessage,
  idlePgBar,
  unSync,
} from "../../redux/slices/notification";

/** Types */
import {
  EVENT,
  IDLE_EVENT,
  READ_EVENT,
  RESET_EVENT,
} from "../../types/notification";

/** Utils */
import logger from "../../utils/logger";

/** ---------- Code Start ----------
 * @logic First, we use websocket to listen the new messages. Once websocket is established, we use HTTP request to get the history messages. In this way, we ensure no loss of new messages.
 * @caution When pulling the history messages via HTTP, the new message might be both in the HTTP response and WS payload. So, we need to de-duplicate the messages by id, before adding then to the message list.
 */

const DEBUG = false;

const ProcessMessage = () => {
  const socket = useSocket();
  const dispatch = useAppDispatch();

  /** Listen to socket events */
  useEffect(() => {
    if (!socket) return;

    if (DEBUG) {
      socket.onAny((event) => {
        console.log(event);
      });
    }

    let registeredEvent: string[] = [];
    const registerEvent = (
      event: EVENT,
      resetEvent: RESET_EVENT,
      readEvent: READ_EVENT
    ) => {
      socket.on(event, (data) => {
        DEBUG && logger.debug(event, data);
        dispatch(
          updateMessageData({
            event,
            message: data,
          })
        );
      });

      socket.on(resetEvent, (data) => {
        DEBUG && logger.debug(resetEvent, data);
        dispatch(
          resetMessageLevel({
            event: event,
            id: data.id,
          })
        );
      });

      socket.on(readEvent, (data) => {
        DEBUG && logger.debug(readEvent, data);
        dispatch(
          readMessage({
            event: event,
            id: data.id,
          })
        );
      });

      /** Sync message data after socket are listening */
      dispatch(syncMessage(event));

      registeredEvent = [...registeredEvent, event, resetEvent, readEvent];
    };

    const registerPgIdleEvent = () => {
      socket.on(IDLE_EVENT.SARA, (data) => {
        DEBUG && logger.debug(IDLE_EVENT.SARA, data);
        dispatch(idlePgBar({ id: data.id }));
      });
      registeredEvent = [...registeredEvent, IDLE_EVENT.SARA];
    };

    /** Register Events */
    registerEvent(EVENT.SARA, RESET_EVENT.SARA, READ_EVENT.SARA);
    registerEvent(EVENT.ABNOMRAL, RESET_EVENT.ABNOMRAL, READ_EVENT.ABNOMRAL);
    registerPgIdleEvent();

    /** De-register Events */
    return () => {
      DEBUG && logger.debug("unsync");
      registeredEvent.forEach((e) => socket.off(e));
      dispatch(unSync());
    };
  }, [socket, dispatch]);

  return <></>;
};

export default ProcessMessage;
