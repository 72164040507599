import useApi from "@/hooks/useApi";
import useApiState from "@/hooks/useApiState";
import ResourceApi, {
  Api_GetResourceTable_Response,
} from "@/utils/api/resource";
import { Modal } from "antd";
import { MouseEvent, forwardRef, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { useResourceListContext } from "./useResourceListContext";

interface DeleteModalProps {
  record: Api_GetResourceTable_Response;
}

const DeleteModal = forwardRef(({ record }: DeleteModalProps, ref) => {
  const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);

  const { reloadTableData } = useResourceListContext();

  const { t } = useTranslation("resources", {
    keyPrefix: "_pages.list.table",
  });

  const api = useApi(ResourceApi);
  const deleteResource = useApiState(api.deleteResourceForm, {
    successMessage: t("deleteSuccess"),
    errorMessage: t("deleteError"),
  });

  const openDeleteModal = () => {
    setIsDeleteOpen(true);
  };

  const closeDeleteModal = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsDeleteOpen(false);
  };

  const handleDelete = async () => {
    try {
      const response = await deleteResource.send(record.id);
      if (!response.ok) throw new Error("Menu dropdown delete error");
      setIsDeleteOpen(false);
      reloadTableData();
    } catch (error) {
      console.error("Failed to delete resource:", error);
    }
  };

  useImperativeHandle(ref, () => ({
    click: () => openDeleteModal(),
  }));

  return (
    <>
      <div style={{ width: "100%", height: "100%" }}>{t("delete")}</div>
      <Modal
        title={t("deletePopTitle")}
        okText={t("delete")}
        cancelText={t("cancel")}
        open={isDeleteOpen}
        onOk={handleDelete}
        onCancel={closeDeleteModal}
        confirmLoading={deleteResource.loading}
      >
        {t("deletePopContent")}
      </Modal>
    </>
  );
});

export default DeleteModal;
