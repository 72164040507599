const FallbackSuspense = ({ text }: { text?: string }) => {
  return (
    <div
      className="d-flex flex-column flex-nowrap align-items-center justify-content-center w-100 h-100"
      style={{ minWidth: "300px" }}
    >
      <h1>{text || "Loading ..."}</h1>
      <div className="mt-3">
        <div
          className="spinner-grow text-primary mx-3"
          style={{ animationDelay: "50ms" }}
        ></div>
        <div
          className="spinner-grow text-warning mx-3"
          style={{ animationDelay: "100ms" }}
        ></div>
        <div
          className="spinner-grow text-danger mx-3"
          style={{ animationDelay: "150ms" }}
        ></div>
        <div
          className="spinner-grow text-warning mx-3"
          style={{ animationDelay: "200ms" }}
        ></div>
        <div
          className="d-none d-sm-inline-block spinner-grow text-success mx-3"
          style={{ animationDelay: "250ms" }}
        ></div>
        <div
          className="d-none d-md-inline-block d-sm-none spinner-grow text-warning mx-3"
          style={{ animationDelay: "300ms" }}
        ></div>
      </div>
    </div>
  );
};

export default FallbackSuspense;
