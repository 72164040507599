import { ExclamationCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { Space, Typography } from "antd";
import React, { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";

const ComponentSuspense = ({ children }: { children: React.ReactNode }) => {
  return (
    <ErrorBoundary
      fallback={
        <Space>
          <ExclamationCircleOutlined style={{ color: "red" }} />
          <Typography.Text type={"danger"} style={{ wordBreak: "keep-all" }}>
            Error loading component.
          </Typography.Text>
        </Space>
      }
    >
      <Suspense
        fallback={
          <Space>
            <Typography.Text
              type={"secondary"}
              style={{ wordBreak: "keep-all" }}
            >
              Loading component ...
            </Typography.Text>
            <LoadingOutlined />
          </Space>
        }
      >
        {children}
      </Suspense>
    </ErrorBoundary>
  );
};

export default ComponentSuspense;
