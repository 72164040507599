import { ApiService } from "../axios";

/** Types */
import { SSRParams, SSRResponse } from "@/hooks/useTableSSR";
import { EVENT, Levels } from "../../types/notification";

export interface Api_GetNotificationHistory_Extra {
  filter?: {
    event: "sara-message" | "abnormal";
  };
}

export interface Api_GetNotificationHistory_Response extends SSRResponse {
  data: NotificationHistory[];
}

export interface NotificationHistory {
  id: number;
  created_on: string;
  level: "INFO" | "WARNING" | "ERROR";
  subject: string;
  body: string;
}

const enum Endpoint {
  Sync = "sync",
  Read = "read",
  ResetLevel = "resetLevel",
  GetNotificationHistory = "getNotificationHistory",
}

const endpoints = {
  [Endpoint.Sync]: {
    url: "/api/notification/sync",
    method: "post",
  },
  [Endpoint.Read]: {
    url: "/api/notification/read",
    method: "post",
  },
  [Endpoint.ResetLevel]: {
    url: "/api/notification/reset-level",
    method: "post",
  },
  [Endpoint.GetNotificationHistory]: {
    url: "/api/notification/message-list",
    method: "post",
  },
} as const;

type EndpointName = keyof typeof endpoints;
interface ApiSyncResponse {
  messages: {
    created_on: string;
    id: number;
    level: Levels;
    subject: string;
    body: string;
  }[];
  last_message_id: number;
  last_count: number;
  last_level: Levels;
  last_reset_id: number;
  last_idle_status: false;
  last_idle_id: number;
  last_read_id: number;
  pg_name: string;
  pg_perc: 10;
}

class NotificationApi extends ApiService<EndpointName> {
  _endpoints = endpoints;

  async sync({
    event,
    limit,
  }: {
    event: EVENT;
    limit: number;
  }): Promise<ApiSyncResponse | null> {
    const response = await this.send(Endpoint.Sync, {
      data: { event, limit },
    });
    return response.ok ? response.data : null;
  }

  async read({ event, id }: { event: EVENT; id: number }): Promise<Boolean> {
    const response = await this.send(Endpoint.Read, {
      data: { event, id },
    });

    return response.ok;
  }

  async resetLevel({ event }: { event: EVENT }): Promise<Boolean> {
    const response = await this.send(Endpoint.ResetLevel, {
      data: { event },
    });

    return response.ok;
  }

  getNotificationHistory = async (
    payload: Partial<SSRParams>,
    extra: Api_GetNotificationHistory_Extra
  ) => {
    return this.send<Api_GetNotificationHistory_Response>(
      Endpoint.GetNotificationHistory,
      { data: { ...payload, extra } }
    );
  };
}

export default NotificationApi;
