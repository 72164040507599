/** Hooks */
import { useTranslation } from "react-i18next";

/** Plugins */
import { Result, ResultProps } from "antd";

const XResult = ({ resultProps }: { resultProps?: ResultProps }) => {
  const { t } = useTranslation("dashboard");

  return (
    <Result
      status="warning"
      subTitle={t("unableToLoadData")}
      style={{ padding: 0 }}
      {...resultProps}
    />
  );
};

export default XResult;
