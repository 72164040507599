/** Hooks */
import { useTranslation } from "react-i18next";

/** Plugins */
import { Empty } from "antd";

/** Types */
import { EmptyProps } from "antd";

/* ------------------ Default ----------------- */
const XEmpty = (props: EmptyProps) => {
  const { t } = useTranslation("dashboard");

  return (
    <Empty
      description={t("noData")}
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      {...props}
    />
  );
};

export default XEmpty;
