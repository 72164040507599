import useApi from "@/hooks/useApi";
import useApiState, { ApiState } from "@/hooks/useApiState";
import useAppDispatch from "@/hooks/useAppDispatch";
import useAppSelector from "@/hooks/useAppSelector";
import {
  capabilities,
  resetAll,
  resetCapability,
  selectedWorkcenter,
  setCapabilities,
  setCapabilityState,
  setFormData,
  setOptions,
  setSelectedCapability,
  setSelectedWorkcenter,
  setWorkcenters,
} from "@/redux/slices/resource/resourceEdit";
import ResourceApi, {
  Api_GetResourceForm_Response,
  FormValues,
} from "@/utils/api/resource";
import { EnhancedAxiosResponse } from "@/utils/axios";
import { App } from "antd";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

export interface EditFormHook {
  resourceId?: string | number;
  resourceForm?: ResourceForm;
  isModalVisible?: boolean;
  onSuccess?: () => void;
  formSubmit?: (values: FormValues) => Promise<void>;
}

type ResourceForm = ApiState<
  Api_GetResourceForm_Response,
  (
    id: string | number | undefined
  ) => Promise<EnhancedAxiosResponse<Api_GetResourceForm_Response>>
>;

const useEditForm = ({
  resourceId,
  onSuccess,
  isModalVisible,
}: EditFormHook = {}) => {
  const formDataLoadedRef = useRef(false);
  const { message } = App.useApp();
  const { t } = useTranslation("resources", {
    keyPrefix: "_pages.edit",
  });

  const workcenterId = useAppSelector(selectedWorkcenter);
  const capabilityList = useAppSelector(capabilities);
  const dispatch = useAppDispatch();

  const api = useApi(ResourceApi);
  const addResource = useApiState(api.addResourceForm, {
    successMessage: t("addResourceSuccess"),
    catchServerMessage: true,
    errorCallback: (data) => {
      const content =
        data?.error?._others?.[0] || Object.values(data?.error || {})[0];
      if (!content) return;
      message.error({ content, duration: 2 });
    },
  });
  const updateResource = useApiState(api.updateResourceForm, {
    successMessage: t("updateResourceSuccess"),
    errorMessage: t("updateResourceFailed"),
  });
  const resourceForm = useApiState(api.getResourceForm);
  const deleteResource = useApiState(api.deleteResourceForm, {
    successMessage: t("deleteResourceSuccess"),
    errorMessage: t("deleteResourceFailed"),
  });
  const resourceWorkcenters = useApiState(api.getResourceWorkcenters);
  const resourceJlbs = useApiState(api.getResourceJlbs);

  const formSubmit = async (values: FormValues) => {
    const isEditPage = resourceId !== undefined;
    try {
      if (isEditPage) {
        const response = await updateResource.send({ ...values });
        if (response.ok) return onSuccess?.();
        else throw new Error("Failed to update resource");
      } else {
        const response = await addResource.send({ data: { ...values.data } });
        if (response.ok) return onSuccess?.();
        else throw new Error("Failed to add resource");
      }
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * Set workcenter and capability loading state
   * to display loading indicators in CapabilityLineSection
   */
  useEffect(() => {
    dispatch(
      setCapabilityState({
        isWorkcenterLoading: resourceWorkcenters.loading,
        isWorkcenterSuccess: resourceWorkcenters.success,
        isCapabilityLoading: resourceJlbs.loading,
        isCapabilitySuccess: resourceJlbs.success,
      })
    );
  }, [
    dispatch,
    resourceJlbs.loading,
    resourceJlbs.success,
    resourceWorkcenters.loading,
    resourceWorkcenters.success,
  ]);

  /**
   * Only when the modal is visible, the form data, options, and selected capability are loaded
   * into redux store. After that, the data would not be loaded again.
   */
  useEffect(() => {
    if (!isModalVisible || formDataLoadedRef.current) return;

    Promise.resolve()
      .then(() => {
        return Promise.all([
          resourceForm.send(resourceId),
          resourceWorkcenters.send(),
        ]);
      })
      .then(([formData, workcenters]) => {
        if (!formData || !workcenters) return;
        if (isModalVisible && formData.data.default) {
          dispatch(resetCapability());
          dispatch(setFormData(formData.data.default));
          dispatch(setOptions(formData.data.options));
        }
        if (isModalVisible && workcenters.data) {
          const isWorkcenterExist = workcenters.data.data.length > 0;
          if (!isWorkcenterExist) return;
          dispatch(setWorkcenters(workcenters.data.data));
          dispatch(setSelectedWorkcenter(workcenters.data?.data[0]["id"]));
        }
        if (isModalVisible && formData.data.values) {
          dispatch(setFormData(formData.data.values));
          dispatch(setOptions(formData.data.options));
          dispatch(setSelectedCapability(formData.data.values?.jlb));
        }
        formDataLoadedRef.current = true;
      });

    return () => {
      dispatch(resetAll());
    };
  }, [dispatch, isModalVisible, resourceId, resourceForm, resourceWorkcenters]);

  /**
   * Only when the modal is visible, the workcenter list and capabilities are loaded
   * into redux store. Only when the capability is not stored in redux store, the
   * new capability would be fetched from the server.
   */
  useEffect(() => {
    if (!isModalVisible) return;
    if (!workcenterId) return; // Prevent fetch error when workcenterId is undefined
    if (workcenterId && capabilityList[workcenterId]) return;

    Promise.resolve()
      .then(() => resourceJlbs.send(workcenterId))
      .then((response) => {
        if (!response || !response.data) return;
        dispatch(setCapabilities(response.data.data));
      });
  }, [dispatch, workcenterId, resourceJlbs, capabilityList, isModalVisible]);

  /**
   * When modal is closed, reset loading state.
   */
  useEffect(() => {
    if (!isModalVisible) formDataLoadedRef.current = false;
  }, [isModalVisible]);

  return {
    onSuccess,
    resourceForm,
    addResource,
    updateResource,
    deleteResource,
    formSubmit,
  };
};

export default useEditForm;
