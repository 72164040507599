import styled from "styled-components";

export const STableFilter = styled.div`
  border-radius: 4px 4px 0 0;
  background-color: var(--bs-light);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
`;

export const SMessage = styled.div<{ hasFilters: boolean }>`
  padding: 0.5rem 1rem;
  color: var(--bs-dark);
  border-radius: 4px 4px 0 0;
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  background-color: ${(props) =>
    props.hasFilters ? "#9ed2e879" : "var(--bs-gray-200)"};

  .title {
    font-size: 1rem;
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }

  .detail {
  }
`;

export const SContent = styled.div<{ show: boolean }>`
  display: flex;
  /* flex-wrap: wrap; */
  max-height: ${(props) => (props.show ? 400 : 0)}px;
  /* padding-bottom: ${(props) => (props.show ? 1 : 0)}rem; */
  transition: all 0.3s;
  overflow-x: auto;
  overflow-y: hidden;
`;

export const SBox = styled.div`
  margin-bottom: 1rem;
  padding: 0rem 1rem;
  flex: 1 1 25%;
  min-width: 200px;
  margin-bottom: 1rem;

  &:nth-child(2) {
    flex: 1 1 50%;
    min-width: 300px;
  }

  .title {
    display: flex;
    gap: 0.5rem;
    align-items: baseline;

    & > :first-child {
      font-size: 2.5rem;
    }
  }
`;
