import React from "react";

/** Constants */
import { THEME } from "../constants/globals";

/** Hooks */
import useThemeState from "../hooks/useThemeState";

/* ------------------- Types ------------------ */
type Theme = typeof THEME[keyof typeof THEME];
interface ThemeState {
  changeTheme: () => void;
  dark: boolean;
  theme: Theme;
}

interface ThemeProviderType {
  children: React.ReactNode;
}

/* ------------------ Context ----------------- */
const initialState = {
  changeTheme: () => {},
  dark: false,
  theme: THEME.DARK,
};

const ThemeContext = React.createContext<ThemeState>(initialState);

function ThemeProvider({ children }: ThemeProviderType) {
  const [theme, setTheme] = useThemeState(THEME.DARK);
  const changeTheme = () => {
    setTheme(theme === THEME.DARK ? THEME.LIGHT : THEME.DARK);
  };

  return (
    <ThemeContext.Provider
      value={{
        changeTheme,
        dark: theme === THEME.DARK,
        theme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
}

export { ThemeContext, ThemeProvider };
