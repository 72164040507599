/** Bootstrap */
import { ProgressBar as BsProgressBar } from "react-bootstrap";

/** Hooks */
import useAppSelector from "../../hooks/useAppSelector";

/** Plugins */
import styled from "styled-components";

/** Redux */
import { selectProgressBar } from "../../redux/slices/notification";

/* ---------------- Code Start ---------------- */

const ProgressBar = () => {
  const pgBar = useAppSelector(selectProgressBar);

  return (
    <SProgressbar>
      <div className="label">
        {pgBar.percentage === 100
          ? "100% 已完成"
          : `${pgBar.percentage}% ${pgBar.stage}`}
      </div>
      <BsProgressBar
        now={pgBar.percentage}
        className="position-absolute top-0 bottom-0 start-0 end-0 m-auto"
        style={{ zIndex: 0 }}
        variant="success"
      />
    </SProgressbar>
  );
};

export default ProgressBar;

/* ------------- Styled Components ------------ */

const SProgressbar = styled.div`
  width: 100%;
  max-width: 250px;
  position: relative;
  margin-left: auto;

  .label {
    color: white;
    text-align: center;
    position: relative;
    bottom: 1px;
    left: 0;
    right: 0;
    top: 0;
    margin: auto;
    z-index: 100;
  }
`;
