import XCellTag from "@/components/ui/table/XCellTag";
import { EllipsisOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

interface JobTagsProps {
  value: string[] | null;
  resourceName: string;
  wrap: boolean;
}

const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.5rem;
  overflow: auto;
  scrollbar-width: none;
`;

const JobTags = ({ value, resourceName, wrap: isWrap }: JobTagsProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { t } = useTranslation("resources", {
    keyPrefix: "_pages.list",
  });

  if (!value) return null;

  if (value.length <= 5 || !isWrap) return <XCellTag value={value} />;

  return (
    <TagContainer>
      {value.slice(0, 5).map((text, index) => (
        <XCellTag key={index} value={text} />
      ))}
      <Button
        type="text"
        icon={<EllipsisOutlined />}
        onClick={() => setIsOpen(true)}
        style={{
          height: "1.5rem",
          width: "1.5rem",
          padding: 0,
          transform: "translateY(0.1rem)",
        }}
      />
      <Modal
        centered
        title={`${t("colName.capability")} - ${resourceName ?? ""}`}
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        styles={{ body: { padding: "0.25rem 0" } }}
        footer={() => (
          <Button onClick={() => setIsOpen(false)}>{t("cancel")}</Button>
        )}
      >
        <XCellTag value={value} />
      </Modal>
    </TagContainer>
  );
};

export default JobTags;
