/** Hooks */
import { useTranslation } from "react-i18next";

/** Icons */
import { ArrowLeft, Key } from "react-feather";

/** Plugins */
import { Link, Outlet } from "react-router-dom";
import { Card, Col, Row } from "antd";

/* ------------------ Default ----------------- */
const ResetPasswordPage = () => {
  const { t } = useTranslation("common", { keyPrefix: "resetPassword" });

  return (
    <>
      <Link to="/auth/sign-in" className="mb-2 text-muted d-inline-block">
        <ArrowLeft /> {t("backToLoginPage")}
      </Link>

      <Card>
        <Row gutter={[0, 24]}>
          {/* Description */}
          <Col
            xs={24}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div className="my-3 p-4 rounded-circle bg-dark bg-opacity-50 d-inline-block">
              <Key className="text-white" />
            </div>
            <h2>{t("resetPassword")}</h2>
          </Col>

          <Outlet />
        </Row>
      </Card>
    </>
  );
};

export default ResetPasswordPage;
