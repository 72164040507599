import CopyRightFooter from "@/components/trial/CopyRightFooter";
import Header from "@/components/trial/Header/Header";
import { Outlet } from "react-router-dom";
import styled from "styled-components";

const Container = styled.main`
  padding: 90px 2rem;
  position: relative;
`;

const TrialRoot: React.FC = () => {
  return (
    <Container>
      <Header />
      <Outlet />
      <CopyRightFooter />
    </Container>
  );
};

export default TrialRoot;
