/** Plugins */
import {
  PATH_PROJECT_MANAGEMENT_V2,
  PATH_WORKCENTER_ABNORMAL,
  PATH_WORKCENTER_COMMIT_WORK,
  PATH_WORKCENTER_LIST,
  PATH_WORKCENTER_SCHEDULE,
  PATH_WORKCENTER_WIP_RECORD,
} from "@/routes/path";
import { AuthUser } from "@/types/auth";
import { Space, Tag } from "antd";
import { Link } from "react-router-dom";
import styled from "styled-components";

/* ------------- Styled Components ------------ */
const SLink = styled(Link)`
  text-decoration: none !important;
`;

/* ------------------- Data ------------------- */
//NOTE: key 值必須設定為該連結的 url
export const getMenuCollection = (key: string, t: any, user: AuthUser) => {
  switch (key) {
    case "project":
      return [
        {
          key: "/project/new",
          label: <SLink to="/project/new">{t("newProject")}</SLink>,
        },
        // {
        //   key: "/project",
        //   label: <SLink to="/project">{t("projectManagement")}</SLink>,
        // },
        {
          key: PATH_PROJECT_MANAGEMENT_V2,
          label: (
            <Space style={{ justifyContent: "space-between", width: "100%" }}>
              <SLink to={PATH_PROJECT_MANAGEMENT_V2}>
                {t("projectManagement")}
              </SLink>
              {/* <Tag color={"blue"}>New</Tag> */}
            </Space>
          ),
        },
        // {
        //   key: "/project/progress",
        //   label: (
        //     <Space style={{ justifyContent: "space-between", width: "100%" }}>
        //       <SLink to="/project/progress">{t("projectProgress")}</SLink>
        //       <Tag color={"warning"}>Beta</Tag>
        //     </Space>
        //   ),
        // },
        {
          key: "/project/closed",
          label: <SLink to="/project/closed">{t("closedProject")}</SLink>,
        },
        {
          key: "/project/delay",
          label: <SLink to="/project/delay">{t("delayAnalysis")}</SLink>,
        },
      ];
    // case "resource":
    //   return [
    //     {
    //       key: "/resource/new",
    //       label: <SLink to="/resource/register">{t("newResource")}</SLink>,
    //     },
    //     {
    //       key: "/resource",
    //       label: <SLink to="/resource/list">{t("resourceList")}</SLink>,
    //     },
    //   ];
    case "process":
      return [
        {
          key: "/process/new",
          label: <SLink to="/process/new">{t("newProcess")}</SLink>,
        },
        {
          key: "/process",
          label: <SLink to="/process">{t("processList")}</SLink>,
        },
      ];
    case "workcenter":
      if (user?.role === "user") return;
      return [
        {
          key: PATH_WORKCENTER_LIST,
          label: (
            <SLink to={PATH_WORKCENTER_LIST}>{t("workcenterManagement")}</SLink>
          ),
        },
        {
          key: PATH_WORKCENTER_SCHEDULE,
          label: (
            <SLink to={PATH_WORKCENTER_SCHEDULE}>
              {t("workcenterSchedule")}
            </SLink>
          ),
        },
        {
          key: PATH_WORKCENTER_WIP_RECORD,
          label: (
            <SLink to={PATH_WORKCENTER_WIP_RECORD}>
              {t("workcenterWipRecord")}
            </SLink>
          ),
        },
        {
          key: PATH_WORKCENTER_ABNORMAL,
          label: (
            <SLink to={PATH_WORKCENTER_ABNORMAL}>
              {t("workcenterAbnormal")}
            </SLink>
          ),
        },
        {
          key: PATH_WORKCENTER_COMMIT_WORK,
          label: (
            <SLink to={PATH_WORKCENTER_COMMIT_WORK}>
              {t("workcenterCommitWork")}
            </SLink>
          ),
        },
      ];
    case "material":
      return [
        {
          key: "/material/schedule",
          label: <SLink to="/material/schedule">{t("materialSchedule")}</SLink>,
        },
        {
          key: "/material/shortage-list",
          label: (
            <SLink to="/material/shortage-list">{t("materialShortage")}</SLink>
          ),
        },
        {
          key: "/material/inventory",
          label: <SLink to="/material/inventory">{t("inventoryList")}</SLink>,
        },
      ];
    case "setting":
      return [
        {
          key: "user",
          label: t("user"),
          children: [
            {
              key: "/setting/users",
              label: <SLink to="/setting/users">{t("userInfo")}</SLink>,
            },
            {
              key: "/setting/users/new-user",
              label: <SLink to="/setting/users/new-user">{t("newUser")}</SLink>,
            },
          ],
        },
        {
          key: "working",
          label: t("working"),
          children: [
            {
              key: "/setting/workingcalendar",
              label: (
                <SLink to="/setting/workingcalendar">{t("workingCal")}</SLink>
              ),
            },
            {
              key: "/setting/workingday",
              label: <SLink to="/setting/workingday">{t("workingDay")}</SLink>,
            },
            {
              key: "/setting/workinghour",
              label: (
                <SLink to="/setting/workinghour">{t("workingHour")}</SLink>
              ),
            },
          ],
        },
        {
          key: "system",
          label: t("system"),
          children: [
            {
              key: "/setting/abnormal",
              label: <SLink to="/setting/abnormal">{t("abnormal")}</SLink>,
            },
            {
              key: "/setting/default-values",
              label: (
                <SLink to="/setting/default-values">{t("defaultValues")}</SLink>
              ),
            },
            {
              key: "/setting/cronjobs",
              label: <SLink to="/setting/cronjobs">{t("cronJobs")}</SLink>,
            },
          ],
        },
        {
          key: "notif",
          label: t("notif"),
          children: [
            {
              key: "/setting/email-notif",
              label: <SLink to="/setting/email-notif">{t("mailNotif")}</SLink>,
            },
            {
              key: "/setting/email-notif/new",
              label: (
                <SLink to="/setting/email-notif/new">{t("newReceiver")}</SLink>
              ),
            },
          ],
        },
      ];
  }
};

// export const menuCollection: MenuCollection = {
//   setting: [
//     // {
//     //   key: "/setting",
//     //   label: <SLink to="/setting">設定總覽</SLink>,
//     // },
//     {
//       key: "user",
//       label: "使用者管理",
//       children: [
//         {
//           key: "/setting/users",
//           label: <SLink to="/setting/users">檢視使用者</SLink>,
//         },
//         {
//           key: "/setting/users/new-user",
//           label: <SLink to="/setting/users/new-user">新增使用者</SLink>,
//         },
//       ],
//     },
//     {
//       key: "working",
//       label: "班表管理",
//       children: [
//         {
//           key: "/setting/workingcalendar",
//           label: <SLink to="/setting/workingcalendar">廠區行事曆</SLink>,
//         },
//         {
//           key: "/setting/workingday",
//           label: <SLink to="/setting/workingday">工作日</SLink>,
//         },
//         {
//           key: "/setting/workinghour",
//           label: <SLink to="/setting/workinghour">工作班表</SLink>,
//         },
//       ],
//     },
//     {
//       key: "system",
//       label: "系統設定",
//       children: [
//         {
//           key: "/setting/abnormal",
//           label: <SLink to="/setting/abnormal">異常代碼</SLink>,
//         },
//         {
//           key: "/setting/default-values",
//           label: <SLink to="/setting/default-values">系統預設值</SLink>,
//         },
//         {
//           key: "/setting/cronjobs",
//           label: <SLink to="/setting/cronjobs">設定自動重排</SLink>,
//         },
//       ],
//     },
//   ],
// versions: [
//   { key: "1", label: "version 1" },
//   { key: "2", label: "version 2" },

//   {
//     key: "3",
//     label: "version 3",
//     children: [
//       { key: "3-1", label: "version 3-1" },
//       { key: "3-2", label: "version 3-2" },
//     ],
//   },
//   {
//     key: "4",
//     label: "version 4",
//     children: [{ key: "4-1", label: "version 4-1" }],
//   },
// ],
// };
