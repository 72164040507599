import ApiStatus from "@/components/dashboard/common/ApiStatus";
import { ApiState } from "@/hooks/useApiState";
import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Space, Tag, Typography } from "antd";
import { useEffect, useMemo, useRef, useState } from "react";
import { FixedSizeList as List } from "react-window";
import styled from "styled-components";
import { FilterValues } from "./TableFilter";

/* ------------------- Types ------------------ */
interface ValueListProps {
  rawValues: FilterValues[string];
  selectedValues: string[];
  selectSingleValue: (val: string) => void;
  selectMultipleValues: (values: FilterValues[string]) => void;
  deselectMultipleValues: (values: FilterValues[string]) => void;
  curColumn: string;
  getLabelFromValue?: (column: string, value: any) => string;
  loading: ApiState["loading"];
  success: ApiState["success"];
}
/* -------------------------------------------- */
/*               Default Component              */
/* -------------------------------------------- */
export const ValueList = (props: ValueListProps) => {
  const {
    rawValues,
    selectedValues,
    selectSingleValue,
    selectMultipleValues,
    deselectMultipleValues,
    curColumn,
    getLabelFromValue,
    loading,
    success,
  } = props;
  const [searchText, setSearchText] = useState("");
  const prevCol = useRef("");

  const tRef = useRef(getLabelFromValue);
  useEffect(() => {
    tRef.current = getLabelFromValue;
  }, [getLabelFromValue]);

  const values = useMemo(
    () =>
      rawValues.filter(({ value }) => {
        const searchValue = searchText.replace(/\s/g, "").toLowerCase();

        /** Skip searching if search value is empty */
        if (!searchValue) return true;

        const t = tRef.current;
        const testValue = (t && t(curColumn, value)) || value;

        return String(testValue)
          .replace(/\s/g, "")
          .toLowerCase()
          .includes(searchValue);
      }),
    [curColumn, rawValues, searchText]
  );

  /** 當篩選欄位改變時，淨空篩選值的搜尋欄位 */
  useEffect(() => {
    if (prevCol.current !== curColumn) {
      setSearchText("");
      prevCol.current = curColumn;
    }
  }, [curColumn]);

  /* ------------------ RENDER ------------------ */
  return (
    <SValueList>
      <ApiStatus
        loading={loading}
        success={success}
        resultProps={{ style: { marginTop: "auto" } }}
      >
        <div className="toolbar">
          <Input
            allowClear
            type="text"
            value={searchText}
            prefix={<SearchOutlined />}
            placeholder={"Search..."}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <Space.Compact>
            <Button onClick={() => selectMultipleValues(values)}>全選</Button>
            <Button onClick={() => deselectMultipleValues(values)}>清除</Button>
          </Space.Compact>
        </div>

        <SValueSelect>
          {/* ---------- Without virtualization ---------- */}
          {/* {values.map((v) => (
          <SValueOption
            key={v.value}
            onClick={() => handleSelectValue(v.value)}
            isSelected={selectedValues?.includes(v.value)}
          >
            {v.value}
            <Tag
              className="rounded rounded-pill"
              color={v.count ? "#6fa9c1b9" : "var(--bs-gray-400)"}
              bordered={false}
            >
              {v.count} ({v.total})
            </Tag>
          </SValueOption>
        ))} */}

          {/* ---------- With virtualization ---------- */}
          <List
            height={186} // 必須指定高度且符合 parent height 避免資料被截掉
            itemCount={values.length} // 資料長度
            itemData={values} // 來源資料
            itemSize={30} // 選項高度
            itemKey={(index, data) => {
              const item = data[index];
              return item.value;
            }}
            width={"100%"}
          >
            {({ data, index, style }) => {
              const item = data[index];
              const label = getLabelFromValue
                ? getLabelFromValue(curColumn, item.value)
                : item.value;

              return (
                <SValueOption
                  onClick={() => selectSingleValue(item.value)}
                  isSelected={selectedValues?.includes(item.value)}
                  style={style}
                >
                  <Typography.Text
                    ellipsis={{
                      tooltip: {
                        placement: "left",
                      },
                    }}
                  >
                    {label}
                  </Typography.Text>
                  <Tag
                    className="rounded rounded-pill"
                    color={item.count ? "#6fa9c1b9" : "var(--bs-gray-400)"}
                    bordered={false}
                  >
                    {item.count} ({item.total})
                  </Tag>
                </SValueOption>
              );
            }}
          </List>
        </SValueSelect>
      </ApiStatus>
    </SValueList>
  );
};

export default ValueList;

/* ------------- Styled Components ------------ */
const SValueList = styled.div`
  border: 1px solid var(--bs-gray-500);
  border-radius: 4px;
  height: 235px;
  background-color: var(--bs-white);

  .toolbar {
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: space-between;
    padding: 0.5rem 0.5rem;
  }
`;

const SValueSelect = styled.div`
  height: calc(100% - 48px);
  overflow-y: hidden;
  overflow-anchor: none;

  /* custom scroll bar */
  & > div::-webkit-scrollbar {
    /* this selector is required for track and thumb to work. cannot be empty attributes. */
    width: 10px;
  }
  & > div::-webkit-scrollbar-track {
    background: var(--bs-black);
  }
  & > div::-webkit-scrollbar-thumb {
    background: var(--bs-gray-200);
    border-radius: 10px;
    border: 2px solid transparent;
    background-clip: content-box;
  }
`;

const SValueOption = styled.div<{ isSelected: boolean }>`
  padding: 0.2rem 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--bs-dark);
  cursor: pointer;
  border: 1px solid transparent;
  transition: all 0.2s;
  background-color: ${(props) =>
    props.isSelected ? "#e9c5934e" : "transparent"};

  :hover {
    border: 1px solid var(--bs-dark);
  }
`;
