import ApiStatus from "@/components/dashboard/common/ApiStatus";
import useAppDispatch from "@/hooks/useAppDispatch";
import useAppSelector from "@/hooks/useAppSelector";
import {
  formData,
  resetAll,
  selectedCapability,
  selectedLines,
} from "@/redux/slices/resource/resourceEdit";
import { Capabilities, ResourceForm, Values } from "@/utils/api/resource";
import { Form, FormInstance, FormProps, Typography } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CapabilityLineSection from "./CapabilityLineSection";
import DisableResource from "./DisableResource";
import { LockTimeSection } from "./LockTimeSection";
import StatusSection from "./StatusSection";
import WorkingDayHoursSection from "./WorkingDayHoursSection";
import { Content, Description } from "./styledForm";
import { EditFormHook } from "./useEditForm";

interface ResourceEditFormProps {
  resourceId?: number;
  form?: FormInstance;
  resourceForm?: ResourceForm;
  editFormHook: EditFormHook | undefined;
}

const getJlbIdList = (jlbs: Capabilities) => {
  const jlbIdList: number[] = [];
  Object.values(jlbs).forEach((jlb) => {
    jlb.forEach((jlbItem) => jlbIdList.push(jlbItem.id));
  });
  return jlbIdList;
};

const ResourceEditForm = ({
  resourceId,
  form,
  editFormHook = {},
}: ResourceEditFormProps) => {
  const { resourceForm, formSubmit } = editFormHook;

  const dispatch = useAppDispatch();
  const capability = useAppSelector(selectedCapability);
  const line = useAppSelector(selectedLines);
  const initialValues = useAppSelector(formData);

  useEffect(() => {
    const shouldSetFields = resourceId
      ? (initialValues as Values)?.jlb
      : !(initialValues as Values)?.jlb;
    if (!form || !initialValues) return;
    if (shouldSetFields) form.setFieldsValue(initialValues);
    return () => {
      form.resetFields();
    };
  }, [form, initialValues, resourceId]);

  useEffect(() => {
    return () => {
      dispatch(resetAll());
    };
  }, [dispatch, form]);

  const { t } = useTranslation("resources", {
    keyPrefix: "_pages.edit",
  });

  const onFinish: FormProps["onFinish"] = async (values) => {
    if (!formSubmit) return;

    const isLineResourceType = values.resource_type === "Line";

    const is_extra = values.is_extra ?? null;
    const jlb = getJlbIdList(capability);
    const line_capacity = isLineResourceType ? values.line_capacity : null;
    const lines = line ?? [];
    const lock_time = values.lock_time ?? 0;

    const commonData = { ...values, jlb, line: lines, line_capacity, is_extra };
    const newResourceData = { ...commonData, lock_time };

    const isEditPage = resourceId !== undefined;
    if (isEditPage) await formSubmit({ id: resourceId, data: commonData });
    else await formSubmit({ data: newResourceData });
  };

  return (
    <Form
      id="editResource"
      form={form}
      onFinish={onFinish}
      layout="vertical"
      style={{ width: "100%" }}
    >
      <ApiStatus
        loading={resourceForm?.loading}
        success={resourceForm?.success}
      >
        {resourceId && (
          <>
            <Description>{t("idInfo")}</Description>
            <Typography.Title level={3}>
              <Content>{resourceId}</Content>
            </Typography.Title>

            <Description>{t("resourceName")}</Description>
            <Typography.Title level={3}>
              <Content>{(initialValues as Values)?.resource_name}</Content>
            </Typography.Title>
          </>
        )}

        <StatusSection resourceId={resourceId} />
        <CapabilityLineSection />
        <WorkingDayHoursSection />
        <LockTimeSection />
        {resourceId && <DisableResource />}
      </ApiStatus>
    </Form>
  );
};

export default ResourceEditForm;
