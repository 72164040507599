import { useRouterObject } from "@/routes/Router";
import { matchRoutes, useLocation, useParams } from "react-router-dom";
import S from "./Breadcrumbs.module.scss";

const Breadcrumbs = () => {
  const params = useParams();

  /**
   * ! `useMatches` is only worked with static router (the content of routeObject is fixed)
   */
  // let matches = useMatches();
  // let crumbs = matches
  //   // remove matches that don't have handle and crumb
  //   .filter((match: any) => !!match.handle?.crumb)
  //   .map((match: any) => match.handle.crumb(params));

  /**
   * ! `useRouterObject` return dynamic router
   */
  const routeObject = useRouterObject();
  const location = useLocation();
  const routeMatches = matchRoutes(routeObject, location.pathname);

  if (!routeMatches) return null;

  let crumbs = routeMatches
    .filter((match) => !!match.route.handle)
    .map((match) => match.route.handle.crumb(params));

  return (
    <ol className={S.breadcrumbs}>
      {crumbs.length > 1 &&
        crumbs.map((crumb, index) => {
          return (
            <li key={index} className={S.crumb}>
              {crumb}
            </li>
          );
        })}
    </ol>
  );
};

export default Breadcrumbs;
