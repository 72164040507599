import React, { Suspense, useEffect, useState } from "react";

/** Bootstrap */
import { Container } from "react-bootstrap";

/** Components */
import FallbackSuspense from "../components/FallbackSuspense";
import Footer from "../components/Footer";
import Breadcrumbs from "../components/breadcrumb/Breadcrumbs";
import Header from "../components/header/Header";
import NewSidebar from "../components/newSidebar/NewSidebar";

/** Contexts */
import { SocketProvider } from "../contexts/SocketContext";

/** Data */
import { getMenuCollection } from "../components/newSidebar/data";

/** Hooks */
import useMedia from "../hooks/useMedia";

/** Plugins */
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation } from "react-router-dom";
import S from "./Root.module.scss";

/** Utils */
import { LS_SIDEBAR_COLLAPSED_V0 } from "@/constants/globals";
import useAuth from "@/hooks/useAuth";
import useTrial from "@/hooks/useTrial";
import { startIdleTimer, stopIdleTimer } from "@/utils/autoLogout";
import { z } from "zod";
import TrialRoot from "./trial/TrialRoot";

/**
 * @note Utility functions for saving and loading sorting state.
 * Get, save, or remove cached sorting state from localStorage.
 */

const getSavedCollapsedFromLocal = (): boolean => {
  const savedCollapsed = localStorage.getItem(LS_SIDEBAR_COLLAPSED_V0);
  if (!savedCollapsed) return false; // 默認為 false
  const parsedCollapsed = JSON.parse(savedCollapsed);
  const schema = z.boolean();
  const validatedCollapsed = schema.safeParse(parsedCollapsed);
  return validatedCollapsed.success ? validatedCollapsed.data : false;
};

const setSavedCollapsedToLocal = (collapsed: boolean) => {
  localStorage.setItem(LS_SIDEBAR_COLLAPSED_V0, JSON.stringify(collapsed));
};

/* ---------------- Code Starts --------------- */

const Root: React.FC = ({ children }) => {
  /** Set collapsed is false to show sidebar by default */
  const [collapsed, setCollapsed] = useState(getSavedCollapsedFromLocal);

  /** 用以判斷是否在試用版中 */
  const { isTrial } = useTrial();

  /** retrieve data based on the current URL path segment */
  const { t } = useTranslation("common", { keyPrefix: "sidebar" });
  let { pathname } = useLocation();
  const key = pathname.split("/").filter((el) => !!el)[0];
  const { user } = useAuth();
  const menuItemsData = getMenuCollection(key, t, user);

  const openMenu = () => setCollapsed(false);
  const closeMenu = () => setCollapsed(true);
  const toggleSidebar = () => setCollapsed((prev) => !prev);

  const { sm, xs, md } = useMedia();

  /** Save collapsed state to localStorage */
  useEffect(() => {
    setSavedCollapsedToLocal(collapsed);
  }, [collapsed]);

  /** to prevent body scrolling behavior */
  useEffect(() => {
    if (xs && !collapsed && menuItemsData) {
      (document.body as HTMLBodyElement).classList.add("overflow-hidden");
    } else {
      (document.body as HTMLBodyElement).classList.remove("overflow-hidden");
    }
  }, [xs, sm, collapsed, menuItemsData]);

  /** collapse sidebar in mobile view */
  useEffect(() => {
    if (xs) {
      setCollapsed(true);
    }
  }, [xs]);

  /** add idle listener */
  useEffect(() => {
    startIdleTimer();
    return () => stopIdleTimer();
  }, []);

  const noSidebar = menuItemsData === undefined;

  /** 替換成試用版的 Root */
  if (isTrial)
    return (
      <SocketProvider>
        <Suspense fallback={<FallbackSuspense text="Loading Page ..." />}>
          <TrialRoot />
        </Suspense>
      </SocketProvider>
    );

  return (
    <div className={S.wrap}>
      <SocketProvider>
        {/* HEADER */}
        <Header toggleSidebar={toggleSidebar} noSidebar={noSidebar} />
        {/* MAIN: Sidebar + Content */}
        <main className={classNames(S.main, { [S.mobile]: !sm })}>
          {/* SIDEBAR: if there is no menuItemsData, hide Sidebar */}
          {menuItemsData ? (
            <aside
              className={classNames(S.sidebar, {
                [S.close]: collapsed,
                [S.mobile]: xs,
              })}
            >
              <NewSidebar
                collapsed={collapsed}
                open={openMenu}
                close={closeMenu}
                menuItemsData={menuItemsData}
              />
            </aside>
          ) : null}

          {/* CONTENT */}
          <section
            className={classNames(S.content, {
              [S.shrink]: menuItemsData && !collapsed,
              [S.hideCollapsedSidebar]: !md || noSidebar,
            })}
          >
            <React.Suspense
              fallback={<FallbackSuspense text="Loading Page ..." />}
            >
              <Container fluid>
                <Breadcrumbs />
                {children}
                <Outlet />
              </Container>
            </React.Suspense>
          </section>
          <Footer />
        </main>
      </SocketProvider>
    </div>
  );
};

export default Root;
