import {
  addCustomFixedColumn,
  checkVisibleColumn,
  usePreference,
} from "@/components/ui/Preference";
import { useResizableTable } from "@/components/ui/ResizableTable";
import { useTableFilter } from "@/components/ui/tableFilter/TableFilter";
import useApi from "@/hooks/useApi";
import useApiState from "@/hooks/useApiState";
import useTableSSR from "@/hooks/useTableSSR";
import ResourceApi, {
  Api_DownloadResourceCsv_Payload,
  Api_DownloadResourceZip_Payload,
} from "@/utils/api/resource";
import { getFilenameFromHeader, saveCsvFromAxios } from "@/utils/data";
import { saveAs } from "file-saver";
import { createContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ColumnType,
  ResourceListContextProviderProps,
  ResourceListContextValue,
} from "./ResourceListContext.type";
import {
  allowDraggable,
  applySorting,
  applyWrapping,
  generateExactFilterColumns,
  generateExactFilterJsonColumns,
  generateGlobalFilterColumns,
  generateI18nHeader,
  generatePreferenceHeaders,
  generateTableColumns,
} from "./ResourceListContext.util";

const ResourceListContext = createContext<ResourceListContextValue>({
  _contextProvided: false,
} as ResourceListContextValue);

const ResourceListContextProvider = ({
  children,
}: ResourceListContextProviderProps) => {
  const [selectedId, setSelectedId] = useState<number | undefined>();

  /* -- Design for handling manual click -- */
  const editRef = useRef<HTMLElement>(null);
  const deleteRef = useRef<HTMLElement>(null);

  const { t } = useTranslation("resources", {
    keyPrefix: "_pages.list",
  });

  /* ---------------- API ----------------- */
  const apiResource = useApi(ResourceApi);
  const apiDownloadCsv = useApiState(apiResource.downloadResourceCsv, {
    successMessage: t("downloadSuccess"),
    pendingMessage: t("downloadPending"),
    errorMessage: t("downloadError"),
  });
  const apiDownloadTable = useApiState(apiResource.downloadTable, {
    successMessage: t("downloadSuccess"),
    pendingMessage: t("downloadPending"),
    errorMessage: t("downloadError"),
  });
  const apiDownloadZip = useApiState(apiResource.downloadResourceZip, {
    successMessage: t("downloadSuccess"),
    pendingMessage: t("downloadPending"),
    errorMessage: t("downloadError"),
  });

  /* ---------------- Table Column --------------- */
  const { preferenceHeaders, i18nHeader } = useMemo(() => {
    const headers = generatePreferenceHeaders(t);
    const i18nHeader = generateI18nHeader(headers);
    return { preferenceHeaders: headers, i18nHeader };
  }, [t]);

  const { exactFilterColumns, exactFilterJsonColumns } = useMemo(() => {
    const exactFilterColumns = generateExactFilterColumns(t);
    const exactFilterJsonColumns =
      generateExactFilterJsonColumns(exactFilterColumns);
    return { exactFilterColumns, exactFilterJsonColumns };
  }, [t]);

  /* ----------------- Table UI ----------------- */
  const resizableHook = useResizableTable();
  const { columns, setColumns, initColumnWidth } = resizableHook;

  /* ---------------- Table Data ---------------- */
  const tableSSRHook = useTableSSR({
    api: apiResource.getResourceList,
    globalFilterColumns: generateGlobalFilterColumns(columns),
    exactFilterJsonColumns,
  });
  const { apiSend, ssrParams, setExactFilter } = tableSSRHook;

  /* --------------- Table Filter --------------- */
  const apiGetExactFilter = useApiState(
    apiResource.getResourceTableFilterValues
  );
  // Get table data with exactFilterValues
  const onFilterChange = async (column: string) => {
    const requestBody = { ...ssrParams, exactFilterColumns: [column] };
    const response = await apiGetExactFilter.send(requestBody);
    if (response.ok) return response.data._.exact_stats;
  };
  // Generate i18n label from value of row data in selected column
  const getLabelFromValue = (column: string, value: string) => {
    const i18nKey = `exactFilter.${column}.${value}`;
    return t(i18nKey, String(value));
  };

  const tableFilterHook = useTableFilter({
    exactFilter: ssrParams.exactFilter,
    filterColumns: exactFilterColumns,
    onFilterChange,
    setExactFilter,
    getLabelFromValue,
  });
  const { reloadFilterValues } = tableFilterHook;

  /* ------------- Table Preference ------------- */
  const preferenceSettingHook = usePreference({
    columns: preferenceHeaders,
    defaultValues: { wrap: true },
  });
  // Get features of wrapping, fixed, and visible columns
  const {
    preferenceValues: {
      wrap,
      columns: { visible, fixed },
    },
  } = preferenceSettingHook;

  /* --------------- Table Column --------------- */
  const setId = (id: number | undefined) => {
    setSelectedId(id);
  };
  // Set columns with preference settings and sorting
  useEffect(() => {
    const columns: ColumnType[] = generateTableColumns(t, wrap, setId, {
      editRef,
      deleteRef,
    });
    setColumns(
      columns
        .filter((o) => checkVisibleColumn(o, visible))
        .map((o) => {
          o = { ...o };

          applyWrapping(o, wrap);
          applySorting(o, ssrParams);
          addCustomFixedColumn(o, fixed);
          allowDraggable(o);

          return initColumnWidth(o);
        })
    );
  }, [
    t,
    fixed,
    i18nHeader,
    initColumnWidth,
    setColumns,
    ssrParams,
    visible,
    wrap,
  ]);

  useEffect(() => {
    reloadFilterValues();
  }, [reloadFilterValues]);

  /* ------------ Utility Functions ------------- */

  const reloadTableData = () => {
    apiSend();
    reloadFilterValues();
  };

  const downloadTable = async () => {
    const response = await apiDownloadTable.send(ssrParams);
    saveCsvFromAxios({ response });
  };

  const downloadCsvFile = async (
    table: Api_DownloadResourceCsv_Payload["table"]
  ) => {
    const response = await apiDownloadCsv.send({ table });
    if (response.ok) saveCsvFromAxios({ response });
  };

  const downloadZipFile = async (
    table: Api_DownloadResourceZip_Payload["table"]
  ) => {
    const { ok, data, headers } = await apiDownloadZip.send({ table });
    if (ok) saveAs(data, getFilenameFromHeader(headers["content-disposition"]));
  };

  const contextValue: ResourceListContextValue = {
    _contextProvided: true,
    resizableHook,
    tableSSRHook,
    tableFilterHook,
    preferenceSettingHook,
    apiGetExactFilter,
    i18nHeader,
    selectedId,
    editRef,
    setId,
    reloadTableData,
    downloadTable,
    downloadCsvFile,
    downloadZipFile,
  };

  return (
    <ResourceListContext.Provider value={contextValue}>
      {children}
    </ResourceListContext.Provider>
  );
};

export { ResourceListContext };
export default ResourceListContextProvider;
