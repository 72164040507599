import useApiState from "@/hooks/useApiState";
import ScheduleApi from "@/utils/api/schedule";
import { Dropdown, MenuProps, Modal } from "antd";
import { ItemType } from "antd/es/menu/hooks/useItems";
import { AiFillPlayCircle } from "react-icons/ai";
import styled from "styled-components";
import { ID_TOUR_HEADER_ACTION } from "../../constants/globals";
import useApi from "../../hooks/useApi";
import useAuth from "../../hooks/useAuth";
import type { AuthUser } from "../../types/auth";
import useModal from "./FunctionButton/useModal";

/* ------------------- DATA ------------------- */

const getAvailableMenuItems = (user: AuthUser): BasicMenuItem[] => {
  const basicMenuItems: BasicMenuItem[] = [
    {
      label: "更新",
      key: "prepare",
      plan: ["Premium"],
      available: true,
      hint: "按下[確認]後，系統即會由所有已串接的資料來源自動匯入最新資料，依資料量可能需要數分鐘完成。請等待更新作業完成後，再執行重排/發行等其他操作。",
    },
    {
      label: "重排",
      key: "reschedule",
      plan: ["Standard", "Premium"],
      available: true,
      hint: "按下[確認]後，系統即會依最新資料重新計算/優化排程，依資料量可能需要數分鐘完成。在排程運算進行中時，請勿同時進行資料編輯等操作，以避免與運算中的資料發生衝突。請等待重排作業完成後，再執行重排/發行等其他操作。",
    },
    {
      label: "重排後發行",
      key: "rescheduleWithRelease",
      plan: ["Standard", "Premium"],
      available: true,
      hint: "按下[確認]後，系統即會依最新資料重新計算/優化排程，並且在計算後自動將排程結果發行至站點排程，依資料量可能需要數分鐘完成。在排程運算進行中時，請勿同時進行資料編輯等操作，以避免與運算中的資料發生衝突。",
    },
    {
      label: "發行",
      key: "release",
      plan: ["Standard", "Premium"],
      available: true,
      hint: "按下[確認]後，系統即會將排程結果發行至站點排程，依資料量可能需要數分鐘完成。",
    },
    {
      label: "回寫",
      key: "writeBackERP",
      plan: ["Standard", "Premium"],
      available: !!user?.customUI.button_writeBackERP,
      hint: "按下[確認]後，系統即會將已串接的輸出資料匯出，依資料量可能需要數分鐘完成。",
    },
  ];

  return basicMenuItems.filter((item) => {
    if (!item.available) return false;
    if (!user?.plan) return false;
    return item.plan.includes(user.plan);
  });
};

const getDropdownMenuItems = (items: BasicMenuItem[]): ItemType[] => {
  return items.map((item) => {
    return {
      label: item.label,
      key: item.key,
    };
  });
};

/* ---------------- Code Starts --------------- */
const FunctionButton = () => {
  const { user } = useAuth();
  const api = useApi(ScheduleApi);

  const apiPrepare = useApiState(api.prepare, {
    successMessage: "開始更新資料",
    errorMessage: "無法更新資料",
    catchServerMessage: true,
  });
  const apiReschedule = useApiState(api.reschedule, {
    successMessage: "開始重排",
    errorMessage: "無法重排",
    catchServerMessage: true,
  });
  const apiRelease = useApiState(api.release, {
    successMessage: "發行中...",
    errorMessage: "無法發行",
    catchServerMessage: true,
  });
  const apiWriteBackERP = useApiState(api.writeBackERP, {
    successMessage: "匯出成功",
    errorMessage: "無法匯出",
    catchServerMessage: true,
  });

  const submitPrepare = () => apiPrepare.send();
  const submitReschedule = () => apiReschedule.send();
  const submitRescheduleWithRelease = () => apiReschedule.send(true);
  const submitRelease = () => apiRelease.send();
  const submitErp = () => apiWriteBackERP.send();

  const menuItems = getAvailableMenuItems(user);

  const onClick: MenuProps["onClick"] = ({ key }) => {
    const item = menuItems.find((item) => item.key === key);
    if (item) {
      setData(item);
      open();
    }
  };

  const onOk = async () => {
    if (!data) return;
    const key = data.key;
    let api;

    switch (key) {
      case "prepare":
        api = submitPrepare;
        break;
      case "reschedule":
        api = submitReschedule;
        break;
      case "release":
        api = submitRelease;
        break;
      case "rescheduleWithRelease":
        api = submitRescheduleWithRelease;
        break;
      case "writeBackERP":
        api = submitErp;
        break;
      default:
        console.error(`Unknown key ${key}`);
        return;
    }

    const response = await api();
    if (response.ok) close();
  };

  /* ------------------- Modal ------------------ */
  const { isOpen, open, close, data, setData } = useModal();

  const isModalLoading = (key: Action | undefined) => {
    switch (key) {
      case "prepare": {
        return apiPrepare.loading;
      }
      case "reschedule":
      case "rescheduleWithRelease": {
        return apiReschedule.loading;
      }
      case "release": {
        return apiRelease.loading;
      }
      case "writeBackERP": {
        return apiWriteBackERP.loading;
      }

      default:
        return false;
    }
  };

  return (
    <>
      <Dropdown
        menu={{
          items: getDropdownMenuItems(menuItems),
          onClick,
        }}
        trigger={["click"]}
      >
        <SActionBtn id={ID_TOUR_HEADER_ACTION}>
          <AiFillPlayCircle fontSize={18} />
          <span>執行</span>
        </SActionBtn>
      </Dropdown>
      <Modal
        title={"確認執行 - " + data?.label}
        open={isOpen}
        onOk={onOk}
        onCancel={close}
        centered
        okText={"執行"}
        cancelText={"取消"}
        confirmLoading={isModalLoading(data?.key)}
      >
        {data?.hint}
      </Modal>
    </>
  );
};

export default FunctionButton;

/* -------------- Styled Comonent ------------- */
const SActionBtn = styled.button`
  color: white;
  border: none;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  background-color: transparent;
  white-space: nowrap;
  transition: color 0.3s;

  &:hover,
  &:focus {
    color: var(--bs-orange);
  }
`;

/* ------------------- Types ------------------ */
type Plan = "Premium" | "Standard";
type Action =
  | "prepare"
  | "reschedule"
  | "release"
  | "rescheduleWithRelease"
  | "writeBackERP";

export interface BasicMenuItem {
  label: string;
  key: Action;
  plan: Plan[];
  available: boolean;
  hint: string;
}
