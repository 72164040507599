import { LS_TABLE_PREFERENCE } from "@/constants/globals";
import { ActionMap, OneOfValues } from "@/types/util";
import logger from "@/utils/logger";
import { Button, Checkbox, Col, Modal, Row, Select, Space, Switch } from "antd";
import type { CheckboxValueType } from "antd/es/checkbox/Group";
import { useEffect, useReducer, useState } from "react";
import { RxReset } from "react-icons/rx";
import { z } from "zod";
import { CustomColumnType } from "./ResizableTable";

/* -------------------- Zod ------------------- */
const preferenceValuesSchema = z.object({
  wrap: z.boolean(),
  resizable: z.boolean(),
  draggable: z.boolean(),
  columns: z.object({
    fixed: z.object({
      left: z.array(z.string()),
      right: z.array(z.string()),
    }),
    visible: z.array(z.string()),
  }),
});

/* ------------------- Types ------------------ */
export type PreferenceValues = z.infer<typeof preferenceValuesSchema>;

export interface PreferenceHookProps {
  columns: {
    key: string;
    label: string;
    // defaultFixed?: boolean;
    disableToggleFix?: boolean;
  }[];
  storageKey?: string; // local storage key 緩存設定
  defaultValues?: Omit<Partial<PreferenceValues>, "columns">; // 偏好預設值
}

export interface PreferenceHook {
  isOpen: boolean;
  close: () => void;
  open: () => void;
  preferenceValues: PreferenceValues;
  setPreferenceValues: React.Dispatch<PreferenceAction>;
  columns: PreferenceHookProps["columns"];
}

export interface PreferenceProps {
  hook: PreferenceHook;
  customized?: {
    key: React.Key;
    title: string;
    description: string;
    component: React.ReactNode;
  }[];
  resetColumnWidth?: () => void;
  resetDraggableColumn?: () => void;
}

type PreferenceActionTypes = {
  update_wrap: boolean;
  update_resizable: boolean;
  update_draggable: boolean;
  update_fixed_column: {
    side: "left" | "right";
    columns: string[];
  };
  update_visible_column: string[];
};

type PreferenceAction = OneOfValues<ActionMap<PreferenceActionTypes>>;

/* ------------------ Reducer ----------------- */
const preferenceRuducer = (
  state: PreferenceValues,
  action: PreferenceAction
): PreferenceValues => {
  switch (action.type) {
    case "update_wrap": {
      return {
        ...state,
        wrap: action.payload,
      };
    }
    case "update_resizable": {
      return {
        ...state,
        resizable: action.payload,
      };
    }
    case "update_draggable": {
      return {
        ...state,
        draggable: action.payload,
      };
    }
    case "update_fixed_column": {
      const { side: currentSide, columns: currentSideColumns } = action.payload;
      const oppositeSide = currentSide === "left" ? "right" : "left";

      const oppositeSideColumns = state.columns.fixed[oppositeSide].filter(
        (v) => !currentSideColumns.includes(v)
      );

      return {
        ...state,
        columns: {
          ...state.columns,
          fixed: {
            ...state.columns.fixed,
            [currentSide]: currentSideColumns,
            [oppositeSide]: oppositeSideColumns,
          },
        },
      };
    }
    case "update_visible_column": {
      return {
        ...state,
        columns: {
          ...state.columns,
          visible: action.payload,
        },
      };
    }
  }
};

/* ------------------- Hook ------------------- */
export const usePreference = ({
  columns,
  storageKey,
  defaultValues,
}: PreferenceHookProps): PreferenceHook => {
  /** 彈跳視窗開關 */
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const close = () => setIsOpen(false);
  const open = () => setIsOpen(true);

  /** 更新設定值 */
  const cacheKey = `${LS_TABLE_PREFERENCE}${
    storageKey ?? window.location.pathname
  }`;
  const [preferenceValues, setPreferenceValues] = useReducer(
    preferenceRuducer,
    null,
    () => {
      try {
        const cacheData = JSON.parse(String(localStorage.getItem(cacheKey)));
        if (cacheData) {
          return preferenceValuesSchema.parse(cacheData);
        }
      } catch (e) {
        logger.error(e);
      }

      return {
        wrap: defaultValues?.wrap ?? false,
        resizable: defaultValues?.resizable ?? true,
        draggable: defaultValues?.draggable ?? false,
        columns: {
          fixed: {
            left: [],
            right: [],
          },
          visible: columns.map((i) => i.key),
        },
      };
    }
  );

  /** 儲存至 Localstorage */
  useEffect(() => {
    localStorage.setItem(cacheKey, JSON.stringify(preferenceValues));
  }, [preferenceValues, cacheKey]);

  return {
    isOpen,
    close,
    open,
    preferenceValues,
    setPreferenceValues,
    columns,
  };
};

/* ------------------ Default ----------------- */
const Preference = ({
  hook,
  customized,
  resetColumnWidth,
  resetDraggableColumn,
}: PreferenceProps) => {
  const { close, isOpen, setPreferenceValues, preferenceValues, columns } =
    hook;

  /** 可見欄位設定 */
  const onVisibleColsChange = (checkedValues: CheckboxValueType[]) => {
    if (checkedValues.length === 0) return;
    setPreferenceValues({
      type: "update_visible_column",
      payload: checkedValues.filter((i) => typeof i === "string") as string[],
    });
  };

  /** 釘選欄位設定 */
  const filterOptions = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const fixedColsChange = (values: string[], side: "left" | "right") => {
    /** 最多可釘選欄位數量，目前預設三個 */
    if (values.length > 3) return;
    setPreferenceValues({
      type: "update_fixed_column",
      payload: {
        side,
        columns: values,
      },
    });
  };

  return (
    <Modal
      centered
      footer={null}
      open={isOpen}
      title="頁面設定"
      onCancel={close}
      // style={{ maxHeight: "80vh", overflow: "auto" }}
      styles={{ body: { maxHeight: "80vh", overflowY: "auto" } }}
    >
      <div className="p-2 d-flex flex-column gap-3">
        <div>
          <p className="m-0">變更欄寬</p>
          <small className="d-block mb-1">啟用則可透過拖曳改變欄位寬度</small>
          <Space>
            <Switch
              checkedChildren={"開啟"}
              unCheckedChildren={"關閉"}
              checked={preferenceValues.resizable}
              onChange={(checked) =>
                setPreferenceValues({
                  type: "update_resizable",
                  payload: checked,
                })
              }
            />
            {resetColumnWidth && (
              <Button
                size={"small"}
                type={"dashed"}
                shape={"round"}
                icon={<RxReset />}
                onClick={resetColumnWidth}
              >
                <small>重置欄寬</small>
              </Button>
            )}
          </Space>
        </div>

        <div>
          <p className="m-0">變更欄位順序</p>
          <small className="d-block mb-1">
            啟用則允許按住表頭，拖曳欄位至游標指定位置。
          </small>
          <Space>
            <Switch
              checkedChildren={"開啟"}
              unCheckedChildren={"關閉"}
              checked={preferenceValues.draggable}
              onChange={(checked) =>
                setPreferenceValues({
                  type: "update_draggable",
                  payload: checked,
                })
              }
            />
            {resetDraggableColumn && (
              <Button
                size={"small"}
                type={"dashed"}
                shape={"round"}
                icon={<RxReset />}
                onClick={resetDraggableColumn}
              >
                <small>重置欄位順序</small>
              </Button>
            )}
          </Space>
        </div>

        <div>
          <p className="m-0">換行</p>
          <small className="d-block mb-1">
            啟用則會讓表格內容自動換行，停用則會截斷文字
          </small>
          <Switch
            checkedChildren={"開啟"}
            unCheckedChildren={"關閉"}
            checked={preferenceValues.wrap}
            onChange={(checked) =>
              setPreferenceValues({
                type: "update_wrap",
                payload: checked,
              })
            }
          />
        </div>

        <div>
          <p className="m-0">釘選左側欄位</p>
          <small className="d-block mb-1">
            設定欲釘選在表格左側的欄位，最多三項
          </small>
          <Select
            allowClear={true}
            mode="multiple"
            placeholder="選擇釘選欄位"
            className="w-100"
            onChange={(value) => fixedColsChange(value, "left")}
            value={preferenceValues.columns.fixed.left}
            dropdownStyle={{ background: "var(--bs-light)" }}
            filterOption={filterOptions}
            options={columns.map(({ key, label, disableToggleFix }) => ({
              value: key,
              label,
              disabled: disableToggleFix,
            }))}
          />
        </div>

        <div>
          <p className="m-0">釘選右側欄位</p>
          <small className="d-block mb-1">
            設定欲釘選在表格右側的欄位，最多三項
          </small>
          <Select
            allowClear={true}
            mode="multiple"
            placeholder="選擇釘選欄位"
            className="w-100"
            onChange={(value) => fixedColsChange(value, "right")}
            value={preferenceValues.columns.fixed.right}
            dropdownStyle={{ background: "var(--bs-light)" }}
            filterOption={filterOptions}
            options={columns.map(({ key, label, disableToggleFix }) => ({
              value: key,
              label,
              disabled: disableToggleFix,
            }))}
          />
        </div>

        {/* customized 設定欄位 */}
        {customized?.map(({ key, title, description, component }) => (
          <div key={key}>
            <p className="m-0">{title}</p>
            <small className="d-block mb-1">{description}</small>
            {component}
          </div>
        ))}
      </div>

      <div className="border rounded rounded-lg p-2 mt-3">
        <p className="m-0">可見欄位</p>
        <small className="d-block mb-1">選取表格可見欄位</small>
        <Checkbox.Group
          className="w-100"
          onChange={onVisibleColsChange}
          value={preferenceValues.columns.visible}
        >
          <Row>
            {columns.map(({ key, label }) => (
              <Col key={key} span={12}>
                <Checkbox value={key}>{label}</Checkbox>
              </Col>
            ))}
          </Row>
        </Checkbox.Group>
      </div>
    </Modal>
  );
};

export default Preference;

/* ------------------- Utils ------------------ */
export const checkVisibleColumn = (
  column: CustomColumnType,
  visible: PreferenceValues["columns"]["visible"]
): boolean => {
  return visible.some((i) => (column.dataIndex || column.key || "") === i);
};

export const addCustomFixedColumn = (
  column: CustomColumnType,
  fixed: PreferenceValues["columns"]["fixed"]
) => {
  if (fixed.right) {
    const shouldFixRightIndex = fixed.right.indexOf(
      String(column.dataIndex || column.key || "")
    );
    if (shouldFixRightIndex !== -1) {
      column.fixed = "right";
      column.customFixed = -(shouldFixRightIndex + 1); // avoid zero
    }
  }
  if (fixed.left) {
    const shouldFixLeftIndex = fixed.left.indexOf(
      String(column.dataIndex || column.key || "")
    );
    if (shouldFixLeftIndex !== -1) {
      column.fixed = "left";

      // use arbitary large number to reverse number
      column.customFixed = 1e6 - shouldFixLeftIndex + 1; // avoid zero
    }
  }
};

export const isColumnFixed = (
  key: string,
  fixed: PreferenceValues["columns"]["fixed"]
): boolean => {
  return fixed.left.includes(key) || fixed.right.includes(key);
};
