import { Button, Typography } from "antd";
import React from "react";
import styled from "styled-components";
import FeedbackModal from "./FeedbackModal/FeedbackModal";
import useFeedback from "./FeedbackModal/useFeedback";

const Text = Typography.Text;

const Footer = styled.footer`
  border-top: 1px solid var(--bs-light);
  background-color: ${({ theme }) => (theme.dark ? "#293042" : "#f7f9fc")};
  font-size: 0.8rem;
  min-height: 3rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
`;

const CopyRightText = styled(Text)`
  margin: 0;
  position: absolute;
  bottom: 0.75rem;
  right: calc(50vw - 6.5rem);
`;

const ContactButton = styled(Button)`
  margin-right: 0.5rem;
  color: ${({ theme }) => theme.antd.colorTextBase};
`;

const CopyRightFooter: React.FC = () => {
  const { open, targetText, openModalWith, closeModal } = useFeedback();

  return (
    <>
      <Footer>
        <CopyRightText>
          &copy; {new Date().getFullYear()} -{" "}
          <span className="text-muted">InterAgent Co., Ltd.</span>
        </CopyRightText>
        <ContactButton onClick={() => openModalWith("快速上線")}>
          取得協助
        </ContactButton>
      </Footer>
      <FeedbackModal
        open={open}
        targetText={targetText}
        closeModal={closeModal}
      />
    </>
  );
};

export default CopyRightFooter;
