import { ApiService } from "../axios";

/** Plugins */
import { v4 } from "uuid";

/* ------------------- Types ------------------ */
/** API: Bottleneck */
export interface ApiBottleneckPayload {
  interval: number;
  length: number;
}

export interface ApiBottleneckResponse {
  data: {
    name: string;
    value: number;
    job_count: number;
    uuid: string;
  }[];
  ratio: {
    total: number;
    count: number;
  };
}

/** API: Delay */
export interface ApiDelayPayload {
  interval: number;
  level: "order" | "product" | "lot";
  stage: number;
  kind?: string; //只有二階需要指定
  include_past?: boolean;
}
export interface ApiDelayData {
  data: {
    id?: number; // stage2, id corresponds to level
    lot_id?: number; // stage3
    mo_nbr: string;
    product_name?: string;
    lot_nbr?: string;
    doc_nbr: any[];
    priority: number;
    due: string;
    delay_hour: number;
    wating_hour: number;
    material_waiting: number;
    plan_end_time: string;
    resource_lights: "gray" | "yellow" | "red";
    material_lights: "gray" | "yellow" | "red";
    /** client-side attribute */
    uid?: string;
  }[];
  ratio?: {
    project: number;
    resource: number;
    material: number;
  };
}
export interface ApiDelayResponseData {
  data: ApiDelayData;
}

/** API: Deliver */
export interface ApiDeliverResponseData {
  data: {
    current: {
      name: string;
      count: number;
      deliver: number;
    }[];
    expired: number;
  };
}
/** API: Goal */
export interface ApiGoalPayload {
  interval: number;
  level: "order" | "product" | "lot" | "job";
}
export interface ApiGoalResponseData {
  report: number;
  expect: number;
  uuid: string;
}

/** API: Loading */
export interface ApiLoadingPayload {
  interval: number;
  length: number;
  threshold: number;
  resource_type: "all" | "operator" | "machine" | "vendor";
}

export interface ApiLoadingResponseData {
  data: {
    loading: number;
    resource_name: string;
    job_count: number;
  }[];
  ratio: {
    total: number;
    count: number;
  };
}

/* ----------------- API Path ----------------- */
const endpoints = {
  bottleneckProcess: {
    url: "/dashboard/jlb/max-waiting-time",
    method: "post",
  },
  bottleneckWorkcenter: {
    url: "/dashboard/workcenter/waiting-jlb",
    method: "post",
  },
  delay: {
    url: "/dashboard/delay",
    method: "post",
  },
  deliverMonth: {
    url: "/dashboard/chart/deliver/month",
    method: "post",
  },
  deliverPriority: {
    url: "/dashboard/chart/deliver/priority",
    method: "post",
  },
  goal: {
    url: "/dashboard/stat/goal",
    method: "post",
  },
  loading: {
    url: "/dashboard/resource-loading",
    method: "post",
  },
} as const;

type EndpointName = keyof typeof endpoints;

/* ---------------- API Service --------------- */
const mockServer = "https://bd44772e-d434-47a3-9a29-42090a5b3b40.mock.pstmn.io";

class DashboardApi extends ApiService<EndpointName> {
  _endpoints = endpoints;
  // _mockURL = mockServer;

  async bottleneckProcess(payload: ApiBottleneckPayload) {
    return await this.send<ApiBottleneckResponse>("bottleneckProcess", {
      data: payload,
    });
  }

  async bottleneckWorkcenter(payload: ApiBottleneckPayload) {
    return await this.send<ApiBottleneckResponse>("bottleneckWorkcenter", {
      data: payload,
    });
  }

  async delay(payload: ApiDelayPayload) {
    const response = await this.send<ApiDelayResponseData>("delay", {
      data: payload,
    });

    /** Add UID for each row */
    const { data } = response;
    const { data: rows } = data;
    if (Array.isArray(rows)) {
      rows.forEach((row) => (row["uid"] = v4()));
    }

    return response;
  }

  async goal(payload: ApiGoalPayload) {
    return await this.send<ApiGoalResponseData>("goal", {
      data: payload,
    });
  }

  async loading(payload: ApiLoadingPayload) {
    return await this.send<ApiLoadingResponseData>("loading", {
      data: payload,
    });
  }
}

export default DashboardApi;
