import { SFormLabel } from "@/components/resource/share/ResourceEditForm/styledForm";
import { Form, Select } from "antd";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const ExtraResource = () => {
  const { t } = useTranslation("resources", {
    keyPrefix: "_pages.edit",
  });

  const options = useMemo(
    () => [
      { value: false, label: t("categoryOptions.regular") },
      { value: true, label: t("categoryOptions.extraResource") },
    ],
    [t]
  );

  return (
    <Form.Item
      name={"is_extra"}
      label={<SFormLabel>{t("resourceCategory")}</SFormLabel>}
    >
      <Select
        options={options}
        placeholder={t("resourceCategoryPlaceholder")}
      />
    </Form.Item>
  );
};

export default ExtraResource;
