import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import { ErrorBoundary } from "react-error-boundary";

/** Components */
import App from "./App";
import FallbackError from "./components/FallbackError";
import FallbackSuspense from "./components/FallbackSuspense";

/** Stylesheets */
import "./assets/antd/overwrite.scss";
import "./assets/react/app.scss";
import "./assets/react/dev.scss";
import "./assets/react/font.scss";

/** i18n */
import "./utils/i18n";

const IS_MOCKING = false;

async function enableMocking() {
  if (process.env.NODE_ENV !== "development" || !IS_MOCKING) return;
  const { worker } = await import("./mocks/browser");

  // `worker.start()` returns a Promise that resolves
  // once the Service Worker is up and ready to intercept requests.
  return worker.start();
}

enableMocking().then(() =>
  ReactDOM.render(
    <React.StrictMode>
      <ErrorBoundary fallback={<FallbackError text="App is not available." />}>
        <React.Suspense fallback={<FallbackSuspense text="Loading SARA ..." />}>
          <App />
        </React.Suspense>
      </ErrorBoundary>
    </React.StrictMode>,
    document.getElementById("root")
  )
);
