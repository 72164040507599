import { SFormLabel } from "@/components/resource/share/ResourceEditForm/styledForm";
import { Form, InputNumber } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const checkIsUType = (category: string): boolean => {
  return category === "U";
};

const Capacity = () => {
  const form = Form.useFormInstance();
  const capacityType = Form.useWatch("capacity_type", form);

  useEffect(() => {
    if (checkIsUType(capacityType)) {
      form.setFieldsValue({ standard_capacity: 0 });
    } else if (
      form.getFieldValue("standard_capacity") === 0 ||
      form.getFieldValue("standard_capacity") === undefined
    ) {
      form.setFieldsValue({ standard_capacity: 1 });
    }
  }, [form, capacityType]);

  const { t } = useTranslation("resources", {
    keyPrefix: "_pages.edit",
  });

  const rules = [{ required: true, message: t("capacityRequired") }];

  if (checkIsUType(capacityType))
    return (
      <Form.Item name="standard_capacity" noStyle>
        <InputNumber style={{ display: "none" }} />
      </Form.Item>
    );

  return (
    <Form.Item
      name="standard_capacity"
      label={<SFormLabel>{t("capacity")}</SFormLabel>}
      rules={rules}
    >
      <InputNumber
        min={1}
        style={{ width: "100%" }}
        placeholder={t("capacityPlaceholder")}
      />
    </Form.Item>
  );
};

export default Capacity;
