/** Antd */
import { Button, Space, Tag, Typography } from "antd";

/** Icons */
import { AiFillCloseCircle } from "react-icons/ai";

/** Plugins */
import styled from "styled-components";

/* ------------------- Types ------------------ */
export interface ResultProps {
  selectedFilters: { [key: string]: string[] };
  clearValueFilter: (col: string, val: string) => void;
  clearFieldFilter: (col: string) => void;
  filterColumns: { value: string; label: string }[];
  clearAllFilters: () => void;
  getLabelFromValue?: (column: string, value: any) => string;
}
/* -------------------------------------------- */
/*               Default Component              */
/* -------------------------------------------- */
const Result = (props: ResultProps) => {
  const {
    selectedFilters,
    filterColumns,
    clearValueFilter,
    clearFieldFilter,
    clearAllFilters,
    getLabelFromValue,
  } = props;
  //columns 有設定篩選值的欄位 ex: ['workcenter_name', 'job_name']
  const columns = Object.keys(selectedFilters);
  //getDisplayedNames: {worcenter_name: "站點"}
  const getDisplayedNames = () => {
    const obj: any = {};
    filterColumns.forEach((col: any) => (obj[col.value] = col.label));
    return obj;
  };
  //displayedColumnNames 顯示文字
  const displayedColumnNames = getDisplayedNames();

  /* ------------------ RENDER ------------------ */
  return (
    <SResult>
      {!columns.length ? (
        <Space className="w-100 h-100 justify-content-center">
          目前無篩選條件
        </Space>
      ) : (
        <Button
          onClick={clearAllFilters}
          type="text"
          icon={<AiFillCloseCircle fontSize={18} color="var(--bs-danger)" />}
        >
          清除所有條件
        </Button>
      )}
      {columns.map((col) => (
        <SBox key={col}>
          <div className="field-title">
            {displayedColumnNames[col]}
            <Button
              size="small"
              type="text"
              onClick={() => clearFieldFilter(col)}
            >
              清除條件
            </Button>
          </div>
          <div className="filterTags">
            {selectedFilters[col].map((value) => {
              const label = getLabelFromValue
                ? getLabelFromValue(col, value)
                : value;

              return (
                <Tag
                  key={value}
                  closable
                  onClose={() => clearValueFilter(col, value)}
                >
                  <Typography.Text
                    ellipsis={{
                      tooltip: {
                        placement: "left",
                      },
                    }}
                    style={{
                      paddingRight: "8px", // add space between label and close icon
                    }}
                  >
                    {label}
                  </Typography.Text>
                </Tag>
              );
            })}
          </div>
        </SBox>
      ))}
    </SResult>
  );
};

export default Result;

/* ------------- Styled Components ------------ */
const SResult = styled.div`
  height: 235px;
  border: 1px solid var(--bs-gray-500);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  background-color: var(--bs-white);
  overflow: auto;
  position: relative;

  /* custom scroll bar */
  &::-webkit-scrollbar {
    /* this selector is required for track and thumb to work. cannot be empty attributes. */
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: var(--bs-black);
  }
  &::-webkit-scrollbar-thumb {
    background: var(--bs-gray-200);
    border-radius: 10px;
    border: 2px solid transparent;
    background-clip: content-box;
  }
`;

const SBox = styled.div`
  .field-title {
    /* background-color: #e9c5934e; */
    /* border-top: 1px solid #e1ba83; */

    background-color: #b2d1df2f;
    padding: 0.2rem 0.5rem;
    color: var(--bs-dark);
    /* border-top: 1px solid #9bb7c4e6; */
    box-shadow: 0 -1px 1px #b0cfddaa;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .filterTags {
    padding: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    overflow: hidden;

    /** Tag text overflow >>> */
    .ant-tag {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin: 0;
    }

    .ant-tag-close-icon {
      position: absolute;
      right: 3px;
      top: 5px;
    }
    /** <<< Tag text overflow */
  }
`;
