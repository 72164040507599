import { Divider } from "antd";

const FormDivider = ({ title }: { title: string }) => {
  return (
    <Divider
      orientation="left"
      orientationMargin={0}
      style={{ marginTop: "2rem", fontWeight: "bold" }}
    >
      {title}
    </Divider>
  );
};

export default FormDivider;
