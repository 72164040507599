import useAppDispatch from "@/hooks/useAppDispatch";
import useAppSelector from "@/hooks/useAppSelector";
import {
  options,
  setSelectedLines,
} from "@/redux/slices/resource/resourceEdit";
import { Form, Select, SelectProps, Typography } from "antd";
import { BaseOptionType } from "antd/es/select";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SFormLineLabel } from "../styledForm";

type OptionRender = SelectProps["optionRender"];

const Text = Typography.Text;

const getOptions = (list: string[]): BaseOptionType[] => {
  if (!list) return [];
  return list.map((i) => ({ value: i, label: i }));
};

const ProcessLine = () => {
  const form = Form.useFormInstance();
  const selected = form.getFieldValue("line");

  const list = useAppSelector(options)?.line;
  const memoizedOptions = useMemo(() => getOptions(list as string[]), [list]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setSelectedLines(selected));
  }, [dispatch, selected]);

  const { t } = useTranslation("resources", {
    keyPrefix: "_pages.edit",
  });

  const handleSelect = (value: string[]) => {
    dispatch(setSelectedLines(value));
  };

  const optionRender: OptionRender = (option) => {
    if (!list) return;
    if (list.includes(option.value as string)) {
      return <Text>{option.label}</Text>;
    } else {
      return <Text>{`${option.label} ${t("addLine")}`}</Text>;
    }
  };

  return (
    <>
      <SFormLineLabel>{t("line")}</SFormLineLabel>
      <Form.Item name="line" noStyle>
        <Select
          style={{ width: "100%" }}
          mode="tags"
          placeholder={t("linePlaceholder")}
          onChange={handleSelect}
          optionRender={optionRender}
          options={memoizedOptions}
        />
      </Form.Item>
    </>
  );
};

export default ProcessLine;
