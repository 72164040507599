import { AnyFunction } from "@/types/util";
import { App } from "antd";
import { useMemo, useState } from "react";
import { v4 } from "uuid";
import { EnhancedAxiosResponse } from "../utils/axios";

/* ------------------- Types ------------------ */
export interface Config<D> {
  initData?: D;

  /** client message */
  pendingMessage?: string;
  successMessage?: string;
  errorMessage?: string;
  shouldEmitClientMessage?: (
    response: EnhancedAxiosResponse | undefined
  ) => boolean;

  /** server message */
  catchServerMessage?: boolean;
  bothMessage?: boolean; // 預設 server message 存在時不發送 client message，若設定為 True，兩者皆會發送
  i18nServerMessage?: (key: string) => string;

  successCallback?: (data: D) => void;
  errorCallback?: (data: D) => void;
}

export interface ApiState<D = any, F = AnyFunction> {
  send: F;
  data: D | undefined;
  response: EnhancedAxiosResponse | undefined;
  loading: boolean;
  success: boolean | undefined; // cancelled request is count as not success (false/error).
  error: boolean; // cancelled request is not count as error.
  reset: () => void;
}

export type InferApiState<ApiMethod> = ApiMethod extends (
  ...args: any
) => Promise<EnhancedAxiosResponse<infer D>>
  ? ApiState<D, ApiMethod>
  : ApiState;

/* ------------------ Default ----------------- */
const useApiState = <D = any, T extends any[] = any[]>(
  api: (...args: T) => Promise<EnhancedAxiosResponse<D>>,
  config: Config<D> = {}
): ApiState<D, (...args: T) => Promise<EnhancedAxiosResponse<D>>> => {
  const [data, setData] = useState<D | undefined>(config.initData);
  const [success, setSuccess] = useState<ApiState["success"]>();
  const [error, setError] = useState<ApiState["error"]>(false);
  const [loading, setLoading] = useState<ApiState["loading"]>(false);
  const [response, setResponse] = useState<ApiState["response"]>();

  /* Message */
  const { message } = App.useApp();
  const clientMessage = (
    response: ApiState["response"],
    messageID?: string
  ): string | undefined => {
    const successText = config.successMessage;
    if (successText && response?.ok) {
      message.success({ content: successText, duration: 5, key: messageID });
      return successText;
    }

    let errorText = config.errorMessage;
    if (response?.isTimeout) {
      errorText = "Connection Timeout";
    }

    if (errorText) {
      /** Emit error message if ...
       *  1. Response not fulfilled, except cancelled.
       *  2. Response is undefined (unexpected error).
       * */
      if ((!response?.ok || response === undefined) && !response?.isCanceled) {
        message.error({ content: errorText, duration: 5, key: messageID });
        return errorText;
      }
    }
  };

  const serverMessage = (
    response: ApiState["response"]
  ): string | undefined => {
    if (!config.catchServerMessage) return;

    /** Server should response json with `message` as key. */
    const text = response?.data?.message;
    if (!text) return;

    if (response.ok) {
      message.success(text);
    } else {
      message.error({
        content: config.i18nServerMessage
          ? config.i18nServerMessage(text)
          : text,
        duration: 5,
      });
    }

    return text; // allow checking if server message exists
  };

  const startPendingMessage = (messageID: string) => {
    const messageText = config.pendingMessage;

    if (messageText) {
      message.open({
        key: messageID,
        type: "loading",
        content: messageText,
        duration: 0,
      });
    }
  };

  const send = async (...args: T) => {
    setLoading(true);
    const messageID = v4();
    startPendingMessage(messageID);

    const response = await api(...args);

    if (response.ok) {
      setData(response.data);
      config.successCallback && config.successCallback(response.data);
    } else {
      config.errorCallback && config.errorCallback(response.data);
    }

    setResponse(response);
    setSuccess(response.ok);
    setError(!response.ok && !response.isCanceled);

    /**
     * * Keep loading if the requested is being canceled, usually by performing another request to same endpoint.
     * TODO: If this does not fit all the cases, pass control parameter to config in the hook.
     */
    if (!response.isCanceled) {
      setLoading(false);
    }

    const serverText = serverMessage(response);
    const { bothMessage, shouldEmitClientMessage } = config;
    if (
      /**
       * Emit client message if,
       * (1) No server text is found in response
       * (2) Explicit bothMessage in config.
       * (3) Custom emit logic `shouldEmitClientMessage`
       */
      (serverText === undefined || bothMessage) &&
      (shouldEmitClientMessage === undefined ||
        shouldEmitClientMessage(response))
    ) {
      /**
       * * Clear message ID if client message was empty
       */
      const clientText = clientMessage(response, messageID);
      if (!clientText) {
        message.destroy(messageID);
      }
    } else {
      message.destroy(messageID);
    }
    return response;
  };

  const reset = () => {
    setData(config.initData);
    setSuccess(undefined);
    setError(false);
    setLoading(false);
    setResponse(undefined);
  };

  /* always return same apiState object to prevent re-render */
  const apiState = useMemo<ApiState>(() => ({} as any), []);
  apiState.send = send;
  apiState.data = data;
  apiState.loading = loading;
  apiState.response = response;
  apiState.success = success;
  apiState.error = error;
  apiState.reset = reset;

  return apiState;
};

export default useApiState;
