import { SFormLabel } from "@/components/resource/share/ResourceEditForm/styledForm";
import { Form, Select } from "antd";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

const CapacityType = ({ isNewResource }: { isNewResource: boolean }) => {
  const form = Form.useFormInstance();

  const { t } = useTranslation("resources", {
    keyPrefix: "_pages.edit",
  });

  useEffect(() => {
    if (isNewResource) form.setFieldsValue({ capacity_type: "M" });
  }, [form, isNewResource]);

  const optionList = useMemo(
    () => [
      { value: "U", label: t("capacityOptions.U") },
      { value: "M", label: t("capacityOptions.M") },
    ],
    [t]
  );

  return (
    <Form.Item
      rules={[{ required: true }]}
      name={"capacity_type"}
      label={<SFormLabel>{t("capacityType")}</SFormLabel>}
    >
      <Select options={optionList} placeholder={t("capacityTypePlaceholder")} />
    </Form.Item>
  );
};

export default CapacityType;
