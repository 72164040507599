import { useLocation } from "react-router-dom";

const useTrial = () => {
  const location = useLocation();

  if (location.pathname.includes("trial")) {
    return { isTrial: true };
  } else {
    return { isTrial: false };
  }
};

export default useTrial;
