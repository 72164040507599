import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

/** Types */
import type {
  ApiBottleneckPayload,
  ApiBottleneckResponse,
  ApiDelayPayload,
  ApiDelayResponseData,
  ApiDeliverResponseData,
  ApiGoalPayload,
  ApiGoalResponseData,
  ApiLoadingPayload,
  ApiLoadingResponseData,
} from "../../utils/api/dashboard";
import type { RootState } from "../store";

/** Utils */
import DashboardApi from "../../utils/api/dashboard";
import {
  createApiState,
  createExtraReducer,
  handleAxiosThunk,
} from "../asyncThunk";

/** Create new api instance
 * * The Global API instance does not allow parallel request to the same endpoint. Create a new instance to allow it.
 */
const api = new DashboardApi({ enableParallelRequest: true });

enum State {
  BottleneckProcess = "BottleneckProcess",
  BottleneckWorkcenter = "BottleneckWorkcenter",
  Delay = "Delay",
  DeliverMonth = "DeliverMonth",
  DeliverPriority = "DeliverPriority",
  GoalOrder = "GoalOrder",
  GoalProduct = "GoalProduct",
  GoalLot = "GoalLot",
  GoalJob = "GoalJob",
  LoadingMachine = "LoadingMachine",
  LoadingOperator = "LoadingOperator",
  LoadingVendor = "LoadingVendor",
}

const initialState = {
  [State.BottleneckProcess]: createApiState<ApiBottleneckResponse>(),
  [State.BottleneckWorkcenter]: createApiState<ApiBottleneckResponse>(),
  [State.Delay]: createApiState<ApiDelayResponseData>(),
  [State.DeliverMonth]: createApiState<ApiDeliverResponseData>(),
  [State.DeliverPriority]: createApiState<ApiDeliverResponseData>(),
  [State.GoalOrder]: createApiState<ApiGoalResponseData>(),
  [State.GoalProduct]: createApiState<ApiGoalResponseData>(),
  [State.GoalLot]: createApiState<ApiGoalResponseData>(),
  [State.GoalJob]: createApiState<ApiGoalResponseData>(),
  [State.LoadingMachine]: createApiState<ApiLoadingResponseData>(),
  [State.LoadingOperator]: createApiState<ApiLoadingResponseData>(),
  [State.LoadingVendor]: createApiState<ApiLoadingResponseData>(),
};

export const slice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers(builder) {
    const thunkReducers = [
      /** Bottleneck */
      {
        asyncThunk: getBottleneckProcess,
        stateKey: State.BottleneckProcess,
      },
      {
        asyncThunk: getBottleneckWorkcenter,
        stateKey: State.BottleneckWorkcenter,
      },

      /** Delay */
      {
        asyncThunk: getDelay,
        stateKey: State.Delay,
      },

      /** Deliver */
      {
        asyncThunk: getDeliverPriority,
        stateKey: State.DeliverPriority,
      },
      {
        asyncThunk: getDeliverMonth,
        stateKey: State.DeliverMonth,
      },

      /** Goal */
      {
        asyncThunk: getGoalOrder,
        stateKey: State.GoalOrder,
      },
      {
        asyncThunk: getGoalProduct,
        stateKey: State.GoalProduct,
      },
      {
        asyncThunk: getGoalLot,
        stateKey: State.GoalLot,
      },
      {
        asyncThunk: getGoalJob,
        stateKey: State.GoalJob,
      },

      /** Loading */
      {
        asyncThunk: getLoadingMachine,
        stateKey: State.LoadingMachine,
      },
      {
        asyncThunk: getLoadingOperator,
        stateKey: State.LoadingOperator,
      },
      {
        asyncThunk: getLoadingVendor,
        stateKey: State.LoadingVendor,
      },
    ];

    thunkReducers.forEach(({ asyncThunk, stateKey }) =>
      createExtraReducer({
        builder,
        asyncThunk,
        stateKey,
      })
    );
  },
});

/** Async thunks */
export const getGoalOrder = createAsyncThunk(
  "dashboard/getGoalData",
  async (payload: ApiGoalPayload, thunkAPI) =>
    handleAxiosThunk(await api.goal(payload), thunkAPI)
);
export const getGoalProduct = createAsyncThunk(
  "dashboard/getGoalProduct",
  async (payload: ApiGoalPayload, thunkAPI) =>
    handleAxiosThunk(await api.goal(payload), thunkAPI)
);
export const getGoalLot = createAsyncThunk(
  "dashboard/getGoalLot",
  async (payload: ApiGoalPayload, thunkAPI) =>
    handleAxiosThunk(await api.goal(payload), thunkAPI)
);
export const getGoalJob = createAsyncThunk(
  "dashboard/getGoalJob",
  async (payload: ApiGoalPayload, thunkAPI) =>
    handleAxiosThunk(await api.goal(payload), thunkAPI)
);
export const getDeliverPriority = createAsyncThunk(
  "dashboard/getDeliverPriority",
  async (thunkAPI) =>
    handleAxiosThunk(await api.send("deliverPriority"), thunkAPI)
);
export const getDeliverMonth = createAsyncThunk(
  "dashboard/getDeliverMonth",
  async (thunkAPI) => handleAxiosThunk(await api.send("deliverMonth"), thunkAPI)
);
export const getDelay = createAsyncThunk(
  "dashboard/getDelay",
  async (payload: ApiDelayPayload, thunkAPI) =>
    handleAxiosThunk(await api.delay(payload), thunkAPI)
);
export const getBottleneckProcess = createAsyncThunk(
  "dashboard/getBottleneckProcess",
  async (payload: ApiBottleneckPayload, thunkAPI) =>
    handleAxiosThunk(await api.bottleneckProcess(payload), thunkAPI)
);
export const getBottleneckWorkcenter = createAsyncThunk(
  "dashboard/getBottleneckWorkcenter",
  async (payload: ApiBottleneckPayload, thunkAPI) =>
    handleAxiosThunk(await api.bottleneckWorkcenter(payload), thunkAPI)
);
export const getLoadingMachine = createAsyncThunk(
  "dashboard/getLoadingMachine",
  async (payload: ApiLoadingPayload, thunkAPI) =>
    handleAxiosThunk(await api.loading(payload), thunkAPI)
);
export const getLoadingOperator = createAsyncThunk(
  "dashboard/getLoadingOperator",
  async (payload: ApiLoadingPayload, thunkAPI) =>
    handleAxiosThunk(await api.loading(payload), thunkAPI)
);
export const getLoadingVendor = createAsyncThunk(
  "dashboard/getLoadingVendor",
  async (payload: ApiLoadingPayload, thunkAPI) =>
    handleAxiosThunk(await api.loading(payload), thunkAPI)
);

/** Selectors */
export const selectGoalOrder = (state: RootState) =>
  state.dashboard[State.GoalOrder];
export const selectGoalProduct = (state: RootState) =>
  state.dashboard[State.GoalProduct];
export const selectGoalLot = (state: RootState) =>
  state.dashboard[State.GoalLot];
export const selectGoalJob = (state: RootState) =>
  state.dashboard[State.GoalJob];
export const selectDeliverPriority = (state: RootState) =>
  state.dashboard[State.DeliverPriority];
export const selectDeliverMonth = (state: RootState) =>
  state.dashboard[State.DeliverMonth];
export const selectDelay = (state: RootState) => state.dashboard[State.Delay];
export const selectBottleneckProcess = (state: RootState) =>
  state.dashboard[State.BottleneckProcess];
export const selectBottleneckWorkcenter = (state: RootState) =>
  state.dashboard[State.BottleneckWorkcenter];
export const selectLoadingMachine = (state: RootState) =>
  state.dashboard[State.LoadingMachine];
export const selectLoadingOperator = (state: RootState) =>
  state.dashboard[State.LoadingOperator];
export const selectLoadingVendor = (state: RootState) =>
  state.dashboard[State.LoadingVendor];

/** Reducer */
export default slice.reducer;
