import { TRIAL_ROLE, USER_ROLE } from "@/constants/globals";
import useAuth from "@/hooks/useAuth";
import { PATH_TRIAL_HOME } from "@/routes/path";
import React from "react";
import { Navigate } from "react-router-dom";

const HomeGuard = ({ children }: { children: React.ReactNode }) => {
  const { user } = useAuth();
  const currRole = user?.role;

  /** 使用者首頁：站點排程 */
  if (currRole === USER_ROLE) {
    return <Navigate to={"/workcenter/schedule"} />;
  }

  /** 試用者首頁：Trial Home */
  if (currRole === TRIAL_ROLE) {
    return <Navigate to={PATH_TRIAL_HOME} />;
  }

  /** 管理者首頁：Dashboard */
  return <React.Fragment>{children}</React.Fragment>;
};

export default HomeGuard;
