import { SFormLabel } from "@/components/resource/share/ResourceEditForm/styledForm";
import useAppSelector from "@/hooks/useAppSelector";
import { options } from "@/redux/slices/resource/resourceEdit";
import { Form, Select } from "antd";
import { useTranslation } from "react-i18next";
import { transformOptions } from "./workingdayAndHours.util";

const WorkingHours = () => {
  const allOptions = useAppSelector(options);

  const { t } = useTranslation("resources", {
    keyPrefix: "_pages.edit",
  });

  return (
    <Form.Item
      name={"workinghour"}
      label={<SFormLabel>{t("workingHour")}</SFormLabel>}
    >
      <Select
        options={transformOptions({ options: allOptions, type: "workinghour" })}
      />
    </Form.Item>
  );
};

export default WorkingHours;
