import React from "react";

/** Antd */
import { Button, Tooltip } from "antd";

/** Icons & Images */
import { MessageTwoTone } from "@ant-design/icons";
import { FaEye } from "react-icons/fa";

/** Plugins */
import styled from "styled-components";

/** Types */
import { EventContent, MessageContent } from "../../../types/notification";

/** Utils */
import { useNavigate } from "react-router-dom";
import { getBsColorClass_ByLevel } from "./funcs";

/** ---------- Types ---------- */
interface DropdownContentParam {
  message: EventContent;
  hrefShowAll: string;
  toggleRead: () => void;
  renderBody?: (...args: any[]) => React.ReactNode;
  extraFooter?: React.ReactNode;
  showNotificationText?: string;
}

/** ---------- Code Start ---------- */
const DropdownContent = ({
  message,
  hrefShowAll,
  toggleRead,
  renderBody,
  extraFooter,
  showNotificationText,
}: DropdownContentParam) => {
  const navigate = useNavigate();

  return (
    <SNotifContent>
      {/** Header */}
      <div className="header">{message.count} 則新通知</div>

      {/** Body */}
      <div className="body">
        {message.data.map((m: MessageContent) => (
          <SNotifItem key={m.id}>
            <h5 className={getBsColorClass_ByLevel(m.level, "text")}>
              {m.id > message.sync.last_readId && (
                <MessageTwoTone
                  twoToneColor={"#aaa"}
                  style={{ marginRight: "0.5em" }}
                />
              )}
              {m.subject}
            </h5>

            <p className="detail">{renderBody ? renderBody(m.body) : m.body}</p>
            <small className="time">{m.created_on}</small>
          </SNotifItem>
        ))}
      </div>

      {/** Footer */}
      <div className="footer">
        <Button type="link" onClick={() => navigate(hrefShowAll)}>
          {showNotificationText ?? "顯示所有通知"}
        </Button>
        <div>{extraFooter}</div>
        <Tooltip title="標示為已讀" placement="bottom">
          <SToggleReadBtn onClick={toggleRead}>
            <FaEye />
          </SToggleReadBtn>
        </Tooltip>
      </div>
    </SNotifContent>
  );
};

export default DropdownContent;

/** -------------- Styled Components ------------ */
const SNotifContent = styled.div`
  position: relative;
  min-width: 200px;

  .header {
    text-align: center;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid var(--bs-gray-200);
  }

  .body {
    max-height: 45vh;
    overflow-y: auto;

    /* custom scroll bar */
    &::-webkit-scrollbar {
      /* this selector is required for track and thumb to work. cannot be empty attributes. */
      width: 10px;
    }
    &::-webkit-scrollbar-track {
      background: var(--bs-black);
    }
    &::-webkit-scrollbar-thumb {
      background: var(--bs-gray-200);
      border-radius: 10px;
      border: 2px solid transparent;
      background-clip: content-box;
    }
  }

  .footer {
    text-align: center;
    border-top: 1px solid var(--bs-gray-300);
    position: sticky;
    z-index: 10;
    padding-top: 0.5rem;
  }
`;

const SNotifItem = styled.div`
  padding: 0.8rem 0.5rem;
  border-bottom: 1px solid var(--bs-gray-200);

  &:last-child {
    border-bottom: 1px solid transparent;
  }

  h5 {
    font-size: 0.9rem;
  }

  .detail {
    font-size: 0.8rem;
    margin: 0;
  }

  .time {
    color: gray;
  }
`;

const SToggleReadBtn = styled.div`
  position: absolute;
  top: 0.5rem;
  right: 1rem;
  cursor: pointer;
`;
