import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

/** Types */
import { Endpoint } from "../types/api";
import { PROMISE_STATE } from "../types/util";

/** Utils */
import logger from "./logger";

const baseURL =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_AXIOS_BASE_URL || "/proxy"
    : "/";

const instance = axios.create({
  baseURL,
  /** test config */
  // timeout: 10,
});

instance.defaults.xsrfCookieName = "xsrf-token";
instance.defaults.xsrfHeaderName = "X-Xsrf-Token";
instance.defaults.headers.common["X-Exception-Type"] = "json";

type ExtraResponseProps = {
  /**
   * @property {boolean} ok
   * @property
   */
  ok: boolean;
  isCanceled?: boolean;
  isTimeout?: boolean;
  isUnexpectedError?: boolean;
};

export type EnhancedAxiosResponse<Data = any> = AxiosResponse<Data> &
  ExtraResponseProps;

const enhanceResponse = (
  response: AxiosResponse,
  ok: boolean
): EnhancedAxiosResponse => ({
  ...response,
  ok,
});

instance.interceptors.response.use(
  (response) => {
    /** Any status code that lie within the range of 2xx cause this function to trigger. Do something with response data */

    return enhanceResponse(response, true);
  },
  (error) => {
    /** Any status codes that falls outside the range of 2xx cause this function to trigger. Do something with response error */

    if (error.response) {
      /** HTTPs status (>300) error */
      return enhanceResponse(error.response, false);
    }

    let response: ExtraResponseProps;
    switch (error.name) {
      /** Connection timeout */
      case "AxiosError":
        logger.warn("Axios Connection Timeout: ", error);
        response = { ok: false, isTimeout: true };
        break;

      /** Manually cancel request */
      case "CanceledError":
        logger.debug("Axios Request Cancelled: ", error);
        response = { ok: false, isCanceled: true };
        break;

      /** Unexpected application error */
      default:
        logger.error("Unexpected request error occurred.", error);
        response = { ok: false, isUnexpectedError: true };
    }

    return response;
  }
);

export interface ApiServiceConstructor {
  enableParallelRequest?: boolean;
}

type AbortCtrlStore = {
  [name: string]: AbortController;
};

type RequestStore = {
  [name: string]: Promise<EnhancedAxiosResponse> | undefined;
};

type CustomConfig = {
  pathVars?: Record<string, string | number>;
};

export abstract class ApiService<EndpointName extends string = string> {
  /**
   * @param {Object.<ApiServiceConstructor>} config
   *
   * @property {Object} _abortCtrlStore store the abort controller for each endpoint.
   * @property  {Object} _requestStore store the lastest 1 request for each endpoint.
   * @property {Boolean} [_enableParallelRequest=false] allow multiple requests to the same endpoint simultaneously.
   * @property {String} _mockURL replace the axios base URL.
   */
  abstract _endpoints: Endpoint;
  _abortCtrlStore: AbortCtrlStore = {};
  _requestStore: RequestStore = {};
  _enableParallelRequest: boolean;
  _mockURL?: string = undefined;

  constructor(config?: ApiServiceConstructor) {
    this._enableParallelRequest = config?.enableParallelRequest ?? false;
  }

  _abort(name: EndpointName) {
    const controller = this._abortCtrlStore[name];
    controller && controller.abort();
  }

  _abortAll() {
    for (const endpoint in this._abortCtrlStore) {
      const ctrl = this._abortCtrlStore[endpoint];
      if (!ctrl.signal.aborted) {
        ctrl.abort();
      }
    }
  }

  _getAbortController<T extends string>(name: T): AbortController {
    /** Use single controller for identical endpoint (e.g., /auth). When abortion is triggered, the controller will cancel all the requests for that endpoint. This only affect the requests using the same instance of `ApiService`. */

    let ctrl = this._abortCtrlStore[name];
    if (!ctrl || (ctrl && ctrl.signal.aborted)) {
      /** create new controller if not exists or that signal is already aborted. */
      ctrl = new AbortController();
      this._abortCtrlStore[name] = ctrl;
    }

    return ctrl;
  }

  _getPromiseState(promise: Promise<EnhancedAxiosResponse>) {
    /** Using custom pending (resolved immediately) to get the pending state of the testing promise */

    const pending = PROMISE_STATE.PENDING;

    return Promise.race([promise, pending]).then(
      (value) => {
        return value === pending
          ? PROMISE_STATE.PENDING
          : PROMISE_STATE.RESOLVE;
      },
      () => PROMISE_STATE.REJECT
    );
  }

  async send<Data = any>(
    name: EndpointName,
    axiosConfig?: AxiosRequestConfig,
    customConfig?: CustomConfig
  ): Promise<EnhancedAxiosResponse<Data>> {
    /** Cancel previous request before making a new one */
    if (!this._enableParallelRequest) {
      const previousRequest = this._requestStore[name];
      if (previousRequest) {
        const previousPromiseState = await this._getPromiseState(
          previousRequest
        );
        if (previousPromiseState === PROMISE_STATE.PENDING) {
          this._abort(name);
        }
      }
    }

    /** Replace baseURL with mockURL on development environment */
    axiosConfig = axiosConfig || {};
    if (
      process.env.NODE_ENV !== "production" &&
      typeof this._mockURL === "string"
    ) {
      axiosConfig.baseURL = this._mockURL;
    }

    /** URL formatting */
    let url = this._endpoints[name].url;
    const patternPathVar = /\{(.+?)\}/g;
    const matchedPathVars = [...url.matchAll(patternPathVar)];
    if (matchedPathVars.length > 0) {
      const pathVars = customConfig?.pathVars;
      if (!pathVars) {
        throw new Error(
          `Pattern variables are specified in url path ${url}, but no variables are defined to substitute them.`
        );
      }

      matchedPathVars.forEach((match) => {
        const name = match[1];
        const substr = pathVars[name];
        if (!substr) {
          throw new Error(
            `Pattern variable ${name} is specified in url path ${url}, but no variable is defined to substitute it.`
          );
        }

        url = url.replace(new RegExp(`{${name}}`, "g"), String(substr));
      });
    }

    /** Send request */
    const request = instance({
      ...this._endpoints[name], // url + method
      ...{ url }, // overwrite url
      ...axiosConfig,
      signal: this._getAbortController(name).signal,
    }) as Promise<EnhancedAxiosResponse>;

    /** Record the request: CACHE=1 */
    this._requestStore[name] = request;

    return request;
  }
}

export default instance;
