/** FRONTEND language string */
export enum LANG {
  EN_US = "en-US",
  ZH_TW = "zh-TW",
}

/** MAP FRONTEND language string to BACKEND: KEY is BACKEND supported language string, value is FRONTEND supported format */
export enum LANG_BACKEND_MAP {
  EN = LANG.EN_US,
  ZH = LANG.ZH_TW,
}
