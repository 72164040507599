import React from "react";
import { Button } from "react-bootstrap";

interface Params {
  title?: string;
  text?: string;
  label?: string;
}

const FallbackError = ({ title, text, label }: Params) => {
  return (
    <div className="d-flex vh-100 vw-100 justify-content-center align-items-center">
      <div className="text-center">
        <h1 className="display-1 fw-bold">{title || "Error"}</h1>
        <p className="h1">{text || "This page is not available now."}</p>
        {label && <p className="h3 fw-normal mt-3 mb-4">{label}</p>}

        <Button
          className="my-5"
          variant="primary"
          size="lg"
          onClick={() => window.history.back()}
        >
          Back to website
        </Button>
      </div>
    </div>
  );
};

export default FallbackError;
