import styled, { css } from "styled-components";

export const scrollCSS = css`
  /* custom scroll bar */
  &::-webkit-scrollbar {
    /* this selector is required for track and thumb to work. cannot be empty attributes. */
    width: 10px;
    height: 10px;
  }
  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.antd.colorBgElevated};
  }
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.antd.colorBorderSecondary};
    border-radius: 10px;
    border: 2px solid transparent;
    background-clip: content-box;
  }
  &::-webkit-scrollbar-corner {
    background: ${({ theme: { antd } }) => antd.colorBgElevated};
  }
`;

export const ModalContent = styled.section`
  width: 90%;
  padding: 1rem;
  max-width: 700px;
  max-height: calc(75vh - 130px);
  margin: 1rem auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: auto;

  ${scrollCSS}
`;

export const SFormLabel = styled.label`
  font-weight: 500;
  color: ${(props) => props.theme.antd.colorTextLabel};
`;

export const SFormLineLabel = styled(SFormLabel)`
  margin-bottom: 0.5rem;
`;

export const Description = styled.div`
  color: ${(props) => props.theme.antd.colorTextPlaceholder};
`;

export const Content = styled.div`
  color: ${(props) => props.theme.antd.colorTextSecondary};
`;
