import { useTranslation } from "react-i18next";
import FormDivider from "../FormDivider";
import LockTime from "./LockTime";

const LockTimeSection = () => {
  const { t } = useTranslation("resources", {
    keyPrefix: "_pages.edit",
  });

  return (
    <section>
      <FormDivider title={t("lockTime")} />
      <LockTime />
    </section>
  );
};

export default LockTimeSection;
