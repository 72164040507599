import { CaretRightOutlined } from "@ant-design/icons";
import { Col, Row, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { SFormLabel } from "../../styledForm";
import { CapabilityList, SelectedResult } from "./SelectCapability";
import SelectedWorkcenter from "./SelectCapability/SelectedWorkcenter";
import { SCapabilitySection, SSectionTitle } from "./styledCapability";

export interface CapabilityState {
  workcenterList: string[];
  currentCapability: string[];
  selectedWorkcenter: string | undefined;
  selectedCapability: string | undefined;
}

const getSectionTitle = (order: number, description: string) => {
  return (
    <SSectionTitle>
      <Typography.Text>{order}</Typography.Text>
      <Typography.Text>{description}</Typography.Text>
    </SSectionTitle>
  );
};

const Capability = () => {
  const { t } = useTranslation("resources", {
    keyPrefix: "_pages.edit.capability",
  });

  return (
    <SCapabilitySection>
      <SFormLabel>{t("label")}</SFormLabel>
      <Row gutter={8} align="middle">
        <Col span={6}>
          {getSectionTitle(1, t("selectWorkcenter"))}
          <SelectedWorkcenter />
        </Col>

        <Col span={10}>
          {getSectionTitle(2, t("selectCapability"))}
          <CapabilityList />
        </Col>

        <Col span={1} style={{ textAlign: "center" }}>
          <CaretRightOutlined />
        </Col>

        <Col span={7}>
          {getSectionTitle(3, t("viewCapability"))}
          <SelectedResult />
        </Col>
      </Row>
    </SCapabilitySection>
  );
};

export default Capability;
