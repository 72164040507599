import { ApiService, ApiServiceConstructor } from "@/utils/axios";
import logger from "@/utils/logger";
import { useEffect, useMemo } from "react";

/* ------------------- Types ------------------ */
interface Config {
  useGlobalScope?: boolean;
  enableParallelRequest?: boolean;
}
type API<T extends ApiService = any> = {
  new (config?: ApiServiceConstructor): T;
};

/* ---------------- Global API ---------------- */
/** variable to store all `ApiService`
 * @note Use singleton pattern and hash table if finding instance with looping is too expensive.
 */
const apiStore: InstanceType<API>[] = [];
const getGlobalApiInstance = (apiClass: API) => {
  let apiInstance = apiStore.find((i) => i instanceof apiClass);
  if (!apiInstance) {
    logger.debug(`create global api instance: ${apiClass.name}`);
    apiInstance = new apiClass({
      enableParallelRequest: false,
    });
    apiStore.push(apiInstance);
  }
  return apiInstance;
};

/* ------------------ Default ----------------- */
/**
 * @param config.globalScope (default = false) If true, use the global api instances, which share the same abort controller and request history. If one component is unmonted, then all requests from the other components used the same global api instances will be cancelled.
 *
 * @param config.enableParallelRequest (default = false) If true, simutaneously requests to the same endpoint will be allowed. Otherwise, before making a new request to the same endpoint, the previous request will be cancelled first. For global scope, this is always false.
 */

const useApi = <T extends API>(
  apiClass: T,
  config: Config = { useGlobalScope: false, enableParallelRequest: false }
): InstanceType<T> => {
  const { useGlobalScope, enableParallelRequest } = config;
  const api: InstanceType<T> = useMemo(() => {
    return useGlobalScope
      ? getGlobalApiInstance(apiClass)
      : new apiClass({ enableParallelRequest });
  }, [apiClass, useGlobalScope, enableParallelRequest]);

  /** cancel all pending requests */
  useEffect(
    () => () => {
      api._abortAll();
      // logger.debug(`abort all pending requests: ${name}`);
    },
    [api, apiClass]
  );

  return api;
};

export default useApi;
