/**
 * - role 定義什麼身份可以造訪該網址。若所有人都可以訪問則不需要給予 role；若任何人皆不可造訪該網址，則設定為空陣列[]
 * - 父層 role 的優先度會高於子層，也就是說父子層皆有設定 role 的情況下，將會採用父層的設定值
 * - role 需和 path 在同一階層一起定義
 */

import { createContext, useContext, useMemo } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import routes from "./routes";
import { UserRole } from "../types/auth";
import { ExtRouteObject } from "../types/route";

/* ------------------ Default ----------------- */
const Router = () => {
  const { user } = useAuth();

  /** mimic system role as 'admin' */
  const currRole = user?.role === "system" ? "admin" : user?.role;

  const dynamicRoutes = useMemo(
    () => filterByRole(routes, currRole),
    [currRole]
  );

  return (
    <RouterObjectContext.Provider value={dynamicRoutes}>
      <RouterProvider
        router={createBrowserRouter(dynamicRoutes, {
          basename: process.env.PUBLIC_URL,
        })}
      />
    </RouterObjectContext.Provider>
  );
};

export default Router;

/* ------------------- Utils ------------------ */
const filterByRole = (
  routes: ExtRouteObject[],
  userRole: UserRole | undefined
): ExtRouteObject[] =>
  routes.reduce((prev, curr) => {
    let children;
    if (curr?.children) {
      children = filterByRole(curr.children, userRole);
    }

    /** Test if current path is permitted */
    if (Array.isArray(curr.role)) {
      if (!curr.role.some((allowRole) => allowRole === userRole)) {
        return [...prev];
      }
    }

    if (curr.index) {
      /** Index Route: children property should be undefined */
      return [...prev, { ...curr }];
    } else {
      /** NonIndex Route */
      return [...prev, { ...curr, children }];
    }
  }, [] as ExtRouteObject[]);

/* ------------------ Context ----------------- */
/**
 * @issue `useMatches` seems to match against an old route object instead of the current one, likely due to the requirement of using createBrowserRouter with a static route object.
 * @solution provide latest router object by context api.
 * @ref https://github.com/remix-run/react-router/discussions/10496
 * @ref https://github.com/remix-run/react-router/discussions/10223#discussioncomment-5909050
 */

const RouterObjectContext = createContext<ExtRouteObject[] | null>(null);

export const useRouterObject = () => {
  const context = useContext(RouterObjectContext);
  if (!context) throw new Error("Context must be placed within Provider");
  return context;
};
