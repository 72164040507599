import { ResourceForm } from "@/utils/api/resource";

interface TransferOptions {
  options: ResourceForm["options"] | undefined;
  type: "workingday" | "workinghour";
}

export const transformOptions = ({ options, type }: TransferOptions) => {
  if (!options) return [];
  return options[type].map((option) => ({
    value: option[0],
    label: option[1],
  }));
};
