import * as React from "react";
import { Navigate } from "react-router-dom";

import useAuth from "../../hooks/useAuth";
interface AuthGuardType {
  children: React.ReactNode;
}

// For routes that can only be accessed by authenticated users
function AuthGuard({ children }: AuthGuardType) {
  const { isAuthenticated, isInitialized, user } = useAuth();

  if (isInitialized && !isAuthenticated) {
    return <Navigate to="/auth/sign-in" />;
  }

  /* ---------------- Temp. Code ---------------- */
  /** Redirect root to SSR page
   * !Hardcode Solution. Replace this later.
   * !若使用 dev server (e.g., port=3000) 登入非 admin user，成功 signin 取得 cookie 後，需手動切換 port=80。
   */
  const currRole = user?.role;
  if (process.env.NODE_ENV === "production" && isAuthenticated) {
    if (currRole === "root") {
      window.location.href = "/";
      return <></>;
    }
  }
  /* ---------------- Temp. Code ---------------- */

  return <React.Fragment>{isInitialized && children}</React.Fragment>;
}

export default AuthGuard;
