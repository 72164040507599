/** Components */
import Router from "./routes/Router";

/** Contexts */
import AntdThemeProvider from "./contexts/AntdThemeContext";
import { AuthProvider } from "./contexts/AuthContext";
import { MediaProvider } from "./contexts/MediaContext";
import SThemeProvider from "./contexts/SThemeProvider";
import { ThemeProvider } from "./contexts/ThemeContext";

/** Plugins */
import { Helmet, HelmetProvider } from "react-helmet-async";

/** Redux */
import { Provider } from "react-redux";
import { store } from "./redux/store";

/** ----- App ----- */
const App = () => {
  return (
    <HelmetProvider>
      <Helmet titleTemplate="%s | SARA" defaultTitle="SARA" />
      <Provider store={store}>
        <MediaProvider>
          <ThemeProvider>
            <AntdThemeProvider>
              <SThemeProvider>
                <AuthProvider>
                  <Router />
                </AuthProvider>
              </SThemeProvider>
            </AntdThemeProvider>
          </ThemeProvider>
        </MediaProvider>
      </Provider>
    </HelmetProvider>
  );
};

export default App;
