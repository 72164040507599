import AuthApi from "@/utils/api/auth";
import { Alert, Button, Col, Form, Input, Space, Typography } from "antd";
import { Formik } from "formik";
import { Trans, useTranslation } from "react-i18next";
import { BiLinkExternal } from "react-icons/bi";
import { Navigate, useParams, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import useApi from "../../hooks/useApi";
import useApiState from "../../hooks/useApiState";

const ResetPasswordForm = () => {
  const { t } = useTranslation("common", { keyPrefix: "resetPassword" });
  const { token } = useParams();
  const [searchParams] = useSearchParams();
  const uid = searchParams.get("uid");

  /* -------------------- API ------------------- */
  const api = useApi(AuthApi, { useGlobalScope: true });
  const apiResetPassword = useApiState(api.resetPasswordConfirm);

  const onSubmit = async (values: any) => {
    await apiResetPassword.send(values.password, token, uid);
  };

  /* -------------- Api Error Alert ------------- */
  const alertComponent = {
    common: (
      <Alert
        message={t("error.change.alertTitle")}
        description={
          <Trans
            ns={"common"}
            i18nKey={"resetPassword.error.change.alertBody"}
            components={{
              Link: (
                <Typography.Link
                  href="https://www.interagent.io/"
                  target="_blank"
                  underline
                />
              ),
              Icon: <BiLinkExternal />,
            }}
            values={{ interagentLink: "Interagent" }}
          />
        }
        type={"error"}
        showIcon
      />
    ),
    400: <Navigate to={"/auth/reset-password?error=token"} />,
  };

  return (
    <>
      {/* Alert Message on Submit */}
      <Col xs={24}>
        {apiResetPassword.success !== undefined &&
          (apiResetPassword.success ? (
            <Alert
              message={t("success.change.alertTitle")}
              description={t("success.change.alertBody")}
              type={"success"}
              showIcon
            />
          ) : apiResetPassword.response?.status === 400 ? (
            alertComponent[400]
          ) : (
            alertComponent["common"]
          ))}
      </Col>

      {/* Form */}
      <Col xs={24}>
        <Formik
          initialValues={{
            password: "",
            confirmPassword: "",
          }}
          onSubmit={onSubmit}
          validationSchema={Yup.object().shape({
            password: Yup.string().required("Password is required"),
            confirmPassword: Yup.string()
              .required("Required")
              .oneOf([Yup.ref("password"), null], "Passwords must match"),
          })}
        >
          {(formik) => (
            <Form layout="vertical" onFinish={formik.handleSubmit}>
              <Space direction={"vertical"} style={{ width: "100%" }}>
                <Form.Item
                  label={t("password")}
                  help={formik.errors.password}
                  validateStatus={formik.errors.password ? "error" : "success"}
                >
                  <Input.Password
                    name="password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                  />
                </Form.Item>
                <Form.Item
                  label={t("confirmPassword")}
                  help={formik.errors.confirmPassword}
                  validateStatus={
                    formik.errors.confirmPassword ? "error" : "success"
                  }
                >
                  <Input.Password
                    name="confirmPassword"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.confirmPassword}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={formik.isSubmitting}
                    block
                  >
                    {t("resetPassword")}
                  </Button>
                </Form.Item>
              </Space>
            </Form>
          )}
        </Formik>
      </Col>
    </>
  );
};

export default ResetPasswordForm;
