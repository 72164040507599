import React, { useState } from "react";
import Alert from "react-bootstrap/Alert";
import { AlertProps } from "react-bootstrap/Alert";
import S from "./AlertDismissible.module.scss";

type AlertDismissibleType = {
  children: React.ReactNode;
} & AlertProps;

export default function AlertDismissible({
  children,
  ...props
}: AlertDismissibleType) {
  const [show, setShow] = useState(true);
  return (
    <>
      <Alert
        className={S.alert}
        show={show}
        onClose={() => setShow(false)}
        dismissible
        {...props}
      >
        {children}
      </Alert>
    </>
  );
}
