import logger from "@/utils/logger";
import { useContext } from "react";
import { ResourceListContext } from "./ResourceListContext";

export const useResourceListContext = () => {
  const context = useContext(ResourceListContext);
  const isContextProvided = context?._contextProvided;
  /**
   * Use logger.debug instead of throwing an error to avoid triggering the ErrorBoundary
   * in the "ComponentSuspense" component. This approach:
   * 1. Prevents the error from propagating up the component tree
   * 3. Allows for logging the issue without disrupting the user experience
   *
   * Note: This method logs is only for short-term solution, consider
   * throwing an error might still be appropriate in the future.
   */
  if (!isContextProvided) logger.debug("Error: ResourceListContext is empty");
  return context;
};
