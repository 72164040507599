import { SFormLabel } from "@/components/resource/share/ResourceEditForm/styledForm";
import useAppSelector from "@/hooks/useAppSelector";
import { options as allOptions } from "@/redux/slices/resource/resourceEdit";
import { Form, Select, Typography } from "antd";
import { InputStatus } from "antd/es/_util/statusUtils";
import { useEffect, useMemo } from "react";
import { TFunction, useTranslation } from "react-i18next";

type Type = "Vendor" | "Machine" | "Operator" | "Virtual" | "Tooling";

const Text = Typography.Text;

/**
 * Validate resource type to prevent illegal resource type for backend.
 * If resource type is Virtual, submit button will be disabled.
 */
const validateResourceType = (_: any, value: string, t: TFunction) => {
  if (value === t("typeOptions.virtual")) return Promise.reject();
  else return Promise.resolve();
};

const getSelectStatus = (value: string, t: TFunction): InputStatus => {
  if (value === t("typeOptions.virtual")) return "error";
  else return "";
};

const ResourceType = () => {
  const resourceType = useAppSelector(allOptions)?.resource_type;
  const form = Form.useFormInstance();
  const currentType = Form.useWatch("resource_type", form) as string;

  const { t } = useTranslation("resources", {
    keyPrefix: "_pages.edit",
  });

  /**
   * Virtual is only available in display but not allowed in options,
   * so it should be manually set i18n with setFieldsValue.
   */
  useEffect(() => {
    if (currentType !== "Virtual") return;
    form.setFieldsValue({ resource_type: t("typeOptions.virtual") });
  }, [currentType, form, t]);

  /**
   * Remove Virtual from options due to illegal resource type for backend.
   */
  const options = useMemo(() => {
    const i18nMap = {
      Virtual: t("typeOptions.virtual"),
      Tooling: t("typeOptions.tooling"),
      Machine: t("typeOptions.machine"),
      Operator: t("typeOptions.operator"),
      Vendor: t("typeOptions.vendor"),
      Line: t("typeOptions.line"),
    };

    return resourceType?.map((value) => ({
      value: value[0] as Type,
      label: i18nMap[value[0] as Type],
    }));
  }, [resourceType, t]);

  return (
    <Form.Item
      rules={[
        { required: true },
        { validator: (_, value) => validateResourceType(_, value, t) },
      ]}
      name={"resource_type"}
      label={<SFormLabel>{t("resourceType")}</SFormLabel>}
      help={
        getSelectStatus(currentType, t) === "error" && (
          <Text type="warning">{t("virtualWarning")}</Text>
        )
      }
    >
      <Select
        options={options}
        status={getSelectStatus(currentType, t)}
        placeholder={t("resourceTypePlaceholder")}
      />
    </Form.Item>
  );
};

export default ResourceType;
