import React from "react";

/** Componenets */
import XEmpty from "./XEmpty";
import XResult from "./XResult";

/** Hooks */
import { useTranslation } from "react-i18next";

/** Icons */
import { LoadingOutlined } from "@ant-design/icons";

/** Plugins */
import { Spin } from "antd";

/** Types */
import type { EmptyProps, ResultProps, SpinProps } from "antd";
import type { StateData } from "../../../redux/asyncThunk";

/* ------------------- Types ------------------ */
interface ApiStatusProps {
  children?: React.ReactNode;
  empty?: boolean;
  emptyProps?: EmptyProps;
  hideContentOnLoading?: boolean;
  loading?: StateData["loading"];
  loadingContent?: React.ReactNode;
  spinProps?: SpinProps;
  resultProps?: ResultProps;
  success?: StateData["success"];
}

/* ------------------ Default ----------------- */
const ApiStatus = (props: ApiStatusProps) => {
  const { t } = useTranslation("dashboard");

  const {
    empty,
    emptyProps,
    children,
    hideContentOnLoading,
    loading,
    loadingContent,
    spinProps,
    resultProps,
    success,
  } = props;

  return (
    <Spin
      indicator={antIcon}
      spinning={loading ?? false}
      tip={t("loadingData")}
      {...spinProps}
    >
      {success === undefined || success || (!success && loading) ? (
        hideContentOnLoading && loading ? (
          /**
           * TODO: Allow custom loading content's CSS styles.
           */
          loadingContent ?? <div style={{ padding: "5rem" }}></div>
        ) : empty || !children ? (
          <XEmpty {...emptyProps} />
        ) : (
          children
        )
      ) : (
        <XResult resultProps={resultProps} />
      )}
    </Spin>
  );
};

export default ApiStatus;

/* -------------- Sub Components -------------- */
const antIcon = <LoadingOutlined spin />;
