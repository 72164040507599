import {
  LOCK_TIME_DEFAULT,
  LOCK_TIME_PRECISION,
  LOCK_TIME_RANGE,
} from "@/constants/resourceList";
import { Form, InputNumber } from "antd";
import { useTranslation } from "react-i18next";
import { SFormLabel } from "../styledForm";

const LockTime = () => {
  const { t } = useTranslation("resources", {
    keyPrefix: "_pages.edit",
  });

  return (
    <Form.Item
      name={"lock_time"}
      label={<SFormLabel>{t("lockTime")}</SFormLabel>}
      extra={t("lockTimeRange")}
    >
      <InputNumber
        placeholder={LOCK_TIME_DEFAULT}
        precision={LOCK_TIME_PRECISION}
        addonAfter={t("lockTimeUnit")}
        min={LOCK_TIME_RANGE.min}
        max={LOCK_TIME_RANGE.max}
        style={{ width: "100%" }}
      />
    </Form.Item>
  );
};

export default LockTime;
