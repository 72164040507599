import useAppDispatch from "@/hooks/useAppDispatch";
import { resetAll } from "@/redux/slices/resource/resourceEdit";
import {
  Button,
  Flex,
  Form,
  FormInstance,
  Modal,
  ModalProps,
  Space,
  Typography,
} from "antd";
import { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useResourceListContext } from "../../list/context/useResourceListContext";
import { ModalContent } from "./styledForm";
import useEditForm, { EditFormHook } from "./useEditForm";

interface FormModalProps extends Partial<ModalProps> {
  button: JSX.Element;
  resourceId?: string | number;
  render?: (props: RenderProps) => ReactNode;
}

interface RenderProps {
  resourceId: string | number | undefined;
  form?: FormInstance;
  editFormHook?: EditFormHook;
}

const Title = Typography.Title;

const ClickTrigger = styled.div`
  width: 100%;
  height: 100%;
`;

const FormModal = ({
  title,
  button,
  resourceId,
  render,
  children,
  ...props
}: FormModalProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);
  const [submittable, setSubmittable] = useState<boolean>(false);

  const [form] = Form.useForm();
  const values = Form.useWatch([], form);

  const dispatch = useAppDispatch();

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch((error) => {
        const isError = error?.errorFields?.length > 0;
        if (isError) {
          setSubmittable(false);
        } else {
          setSubmittable(true);
        }
      });
  }, [form, values]);

  const { reloadTableData, setId } = useResourceListContext();

  const editFormHook = useEditForm({
    resourceId,
    onSuccess: () => {
      reloadTableData?.();
      setIsOpen(false);
    },
    isModalVisible: isOpen,
  });

  const { addResource, updateResource, deleteResource } = editFormHook;

  useEffect(() => {
    return () => {
      dispatch(resetAll());
    };
  }, [dispatch]);

  const { t } = useTranslation("resources", {
    keyPrefix: "_pages.edit",
  });

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsOpen(false);
    setId?.(undefined);
  };

  const toggleDeleteModal = () => {
    setIsDeleteOpen(!isDeleteOpen);
  };

  const handleDelete = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!resourceId) return;
    e.stopPropagation();
    const sendDeleteResource = async () => {
      try {
        const response = await deleteResource.send(resourceId);
        if (!response.ok) throw new Error("Edit form delete error");
        setIsDeleteOpen(false);
        setIsOpen(false);
        reloadTableData();
      } catch (error) {
        console.error("Failed to delete resource:", error);
      }
    };
    sendDeleteResource();
  };

  return (
    <>
      <ClickTrigger onClick={handleOpen}>{button}</ClickTrigger>
      <Modal
        title={<Title level={4}>{title}</Title>}
        width={"min(max(80vw, 500px), 800px)"}
        keyboard={false}
        open={isOpen}
        maskClosable={false}
        destroyOnClose={true}
        onCancel={handleClose}
        footer={
          <Flex
            justify={resourceId ? "space-between" : "flex-end"}
            style={{ maxWidth: "700px", width: "100%", margin: "2rem auto 0" }}
          >
            {resourceId && (
              <Button
                danger
                loading={deleteResource.loading}
                onClick={toggleDeleteModal}
              >
                {t("delete")}
              </Button>
            )}
            <Modal
              title={t("deletePopTitle")}
              okText={t("delete")}
              cancelText={t("cancel")}
              open={isDeleteOpen}
              onOk={handleDelete}
              confirmLoading={deleteResource.loading}
              onCancel={toggleDeleteModal}
            >
              {t("deletePopContent")}
            </Modal>
            <Space>
              <Button onClick={handleClose}>{t("cancel")}</Button>
              <Button
                type="primary"
                form="editResource"
                htmlType="submit"
                disabled={!submittable}
                loading={
                  resourceId ? updateResource.loading : addResource.loading
                }
              >
                {t("saveEdit")}
              </Button>
            </Space>
          </Flex>
        }
        {...props}
      >
        <ModalContent>
          {render && render({ resourceId, editFormHook, form })}
          {children}
        </ModalContent>
      </Modal>
    </>
  );
};

export default FormModal;
