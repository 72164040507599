/** Componenets */
import SignIn from "../../components/auth/SignIn";

/** Bootstrap */
import { Card, Container, Row } from "react-bootstrap";

/** Hooks */
import { useTranslation } from "react-i18next";

/** Images & Icons */
import logoText from "../../assets/images/logo_text.svg";
import { BsGlobeAsiaAustralia } from "react-icons/bs";

/** Plugins */
import { Link } from "react-router-dom";
import { Divider, Space } from "antd";
import dayjs from "dayjs";

/**
 * @note 用 <img/> 引入 svg 只適用於 CRA 建立的 React 專案。 若不是用 CRA，則需要設定 loader system(webpack:file-loader)
 */

/** ---------- Code Start ---------- */

const SignInPage = () => {
  const { t, i18n } = useTranslation("common");

  return (
    <Container className="d-flex flex-column align-items-center">
      <Row className="w-100">
        <div className="text-center w-75 mx-auto mb-4">
          <img src={logoText} alt="logo" className="w-75" />
        </div>
        <Card className="pt-4 rounded">
          <Card.Body className="mb-2">
            <SignIn />
          </Card.Body>
          <Card.Footer className="text-center">
            <Space direction={"vertical"}>
              <Space
                split={<Divider type={"vertical"} />}
                style={{ justifyContent: "center" }}
              >
                {/* Forget Password */}
                <Link to="/auth/reset-password" className="text-info">
                  {t("signIn.forgetPassword")}
                </Link>

                {/* Locale */}
                <Link to="/auth/language" className="text-info">
                  <Space align={"center"}>
                    <BsGlobeAsiaAustralia
                      style={{ position: "relative", top: "-1px" }}
                    />
                    {t(`locale.${i18n.language}`)}
                  </Space>
                </Link>
              </Space>

              <Card.Link
                href="https://www.interagent.io"
                className="text-muted"
              >
                <small>&copy; {dayjs().format("YYYY")} InterAgent.io</small>
              </Card.Link>
            </Space>
          </Card.Footer>
        </Card>
      </Row>
    </Container>
  );
};

export default SignInPage;
