import { useTranslation } from "react-i18next";
import FormDivider from "../FormDivider";
import Capability from "./Capability";
import ProcessLine from "./ProcessLine";

const CapabilityLineSection = () => {
  const { t } = useTranslation("resources", {
    keyPrefix: "_pages.edit",
  });

  return (
    <section style={{ width: "100%" }}>
      <FormDivider title={t("capabilityLine")} />
      <Capability />
      <ProcessLine />
    </section>
  );
};

export default CapabilityLineSection;
