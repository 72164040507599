const logType = {
  debug: 10,
  info: 20,
  warn: 30,
  error: 40,
} as const;
export type LogLevel = keyof typeof logType;

interface LogFn {
  (message?: any, ...optionalParams: any[]): void;
}

interface Logger {
  debug: LogFn;
  info: LogFn;
  warn: LogFn;
  error: LogFn;
}

const ignore = (level: LogLevel, threshold: number): LogFn | void => {
  /** set logger to empty function if the logger level is less than the specified log number */
  if (logType[level] < threshold) {
    return (message?: any, ...optionalParams: any[]) => {};
  }
};

class ConsoleLogger implements Logger {
  readonly debug: LogFn;
  readonly info: LogFn;
  readonly warn: LogFn;
  readonly error: LogFn;

  constructor(level?: LogLevel) {
    const threshold = logType[level || "info"];

    this.debug = ignore("debug", threshold) || console.debug.bind(console);
    this.info = ignore("info", threshold) || console.log.bind(console);
    this.warn = ignore("warn", threshold) || console.warn.bind(console);
    this.error = ignore("error", threshold) || console.error.bind(console);
  }
}

const logger = new ConsoleLogger(
  process.env.REACT_APP_CONSOLE_LEVEL as LogLevel
);

export default logger;
