/** Define received websocket events in enum values */
export enum EVENT {
  SARA = "sara-message",
  ABNOMRAL = "abnormal",
}

export enum RESET_EVENT {
  SARA = "reset-sara-message",
  ABNOMRAL = "reset-abnormal",
}

export enum READ_EVENT {
  SARA = "read-sara-message",
  ABNOMRAL = "read-abnormal",
}

export enum IDLE_EVENT {
  SARA = "idle-sara-message",
}

export type Levels = "INFO" | "WARNING" | "ERROR";

/** Define websocket message payload's structure
 * @note the actual structure is determined by backend server
 */
export interface MessageContent {
  id: number;
  created_on: string;
  level: Levels;
  subject: string;
  body: string;
  pg_info?: {
    stage: string;
    perc_absolute: number;
  };
}

/** Define inital notification state */
export type EventContent = {
  data: MessageContent[];
  level: undefined | Levels;
  count: number;
  sync: {
    status: boolean;
    MessageIdBeforeSync: number[];
    last_readId: number;
    last_MessageId: number;
    last_ResetLevelId: number;
  };
};
