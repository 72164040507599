import { CaretRightOutlined } from "@ant-design/icons";
import { Col, Collapse, Popover, Row, theme } from "antd";
import { AiFillStar } from "react-icons/ai";
import { BsLightningChargeFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ID_TOUR_HEADER_SHORTCUT } from "../../constants/globals";
import useAuth from "../../hooks/useAuth";
import {
  FormModal,
  ResourceEditForm,
} from "../resource/share/ResourceEditForm";
import ComponentSuspense from "../ui/ComponentSuspense";

const { Panel } = Collapse;
const { useToken } = theme;

const ShortcutForComponent = styled.span`
  color: ${(props) => props.theme.antd.colorText};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

/* ---------------- Code Starts --------------- */
const TEMP_DATA = {
  title: "快速建立",
  items: [
    {
      subtitle: "新增站點",
      path: "/workcenter/list",
      isReactPage: true,
    },
    {
      subtitle: "新增製程",
      path: "/process/new",
      isReactPage: true,
    },
    {
      path: ".",
      component: (
        <ComponentSuspense>
          <FormModal
            title={"新增資源"}
            button={<ShortcutForComponent>新增資源</ShortcutForComponent>}
            closable
            render={({ editFormHook, form }) => (
              <ResourceEditForm editFormHook={editFormHook} form={form} />
            )}
          />
        </ComponentSuspense>
      ),
    },
    {
      subtitle: "新增專案（介面輸入）",
      path: "/project/new",
      isReactPage: true,
    },
    { subtitle: "新增專案（匯入檔案）", path: "/project/upload" },
  ],
};

const GNATT_DATA = {
  title: "甘特圖",
  items: [
    { subtitle: "專案甘特圖", path: "/project/gantt" },
    { subtitle: "資源甘特圖", path: "/resource/gantt" },
  ],
};

/**
 * @note Now, use <a> redirect to target page. Switch to react-router-dom <Link> when react pages are ready.
 */

const Shortcut = ({ isMobile }: { isMobile?: boolean }) => {
  const { token } = useToken();
  const { isAdmin } = useAuth();

  const popoverContent = (
    <Collapse
      defaultActiveKey={[1, 2]}
      expandIcon={({ isActive }) => (
        <CaretRightOutlined rotate={isActive ? 90 : 0} />
      )}
      style={{ width: "min(max(95vw, 300px), 1000px)" }}
    >
      <Panel header={<span>快速建立</span>} key="1">
        <Row gutter={[16, 16]}>
          {TEMP_DATA.items.map((item, index) => (
            <Col md={8} xs={24} key={index}>
              <SShortcutItem>
                <AiFillStar color={token.gold} />
                {item.component}
                {item.isReactPage ? (
                  <Link className="link" to={item.path}>
                    {item.subtitle}
                  </Link>
                ) : (
                  <a className="link" href={item.path}>
                    {item.subtitle}
                  </a>
                )}
              </SShortcutItem>
            </Col>
          ))}
        </Row>
      </Panel>
      <Panel header={<span>甘特圖</span>} key="2">
        <Row gutter={[16, 16]}>
          {GNATT_DATA.items.map((item, index) => (
            <Col md={8} xs={24} key={index}>
              <SShortcutItem>
                <AiFillStar color={token.gold} />
                <Link className="link" to={item.path}>
                  {item.subtitle}
                </Link>
              </SShortcutItem>
            </Col>
          ))}
        </Row>
      </Panel>
    </Collapse>
  );

  //暫時：因為目前功能只有顯示「快速建立」捷徑，而快速建立只有 admin 可以使用，因此若不是 admin 則先隱藏「快速捷徑」按鈕。
  if (isMobile && isAdmin) return popoverContent;
  if (!isAdmin) return null;

  return (
    <SShortcut id={ID_TOUR_HEADER_SHORTCUT}>
      <Popover trigger="hover" content={popoverContent}>
        <div className="cursor-pointer shortcutBtn">
          <BsLightningChargeFill />
          <span>快速捷徑</span>
        </div>
      </Popover>
    </SShortcut>
  );
};

export default Shortcut;

/* ------------- Styled Components ------------ */
const SShortcut = styled.div`
  color: white;
  margin-right: 0.25rem;
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  .shortcutBtn {
    color: #fff;
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    gap: 0.2rem;
    transition: color 0.3s;

    &:hover {
      color: var(--bs-orange);
    }
  }
`;

const SShortcutItem = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;

  .link {
    color: inherit;
    transition: color 0.3s;
  }
`;
