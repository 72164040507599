import { Tag, TagProps } from "antd";
import { ReactElement } from "react";
import styled from "styled-components";

/**
 * XCellTag Usage:
 * @param value: accept string, number and array, this component will generate a list of tag if value is array
 * @depreciated wrap: if true, the value will be wrapped in a flex container
 * @param config
 *   @param colors: a map of value to color, such as { "in-house": "#faedcd", "out-sourcing": "#bde0fe" }
 *   @param textColor: the base color of the text, such as "#fff"
 *   @param baseBgColor: the base color of the background, such as "#89ffba"
 *   @param textCovert: a map of value to text for i18n, such as { "in-house": t("in-house"), "out-sourcing": t("out-sourcing") }
 * @param tagConfig: settings for Antd Tag props
 */

interface XCellTagProps<ValueType = string | number | string[]> {
  value: ValueType;
  wrap?: boolean;
  tagConfig?: TagProps;
  config?: {
    colors?: Record<string, string>;
    textColor?: string;
    baseBgColor?: string;
    textCovert?: Record<string, string>;
  };
}

const Text = styled.div`
  color: ${({ theme }) => theme.antd.colorText};
`;

const getText = (
  value: string | number,
  config?: XCellTagProps["config"]
): ReactElement => (
  <Text style={{ color: config?.textColor || "" }}>
    {config?.textCovert?.[value] ?? value}
  </Text>
);

const getStringTag = (
  value: string,
  config?: XCellTagProps["config"],
  tagConfig?: TagProps
): ReactElement => {
  const convertedType = value.toLowerCase();
  const color = config?.colors?.[convertedType] ?? config?.baseBgColor;

  return (
    <Tag color={color} {...tagConfig}>
      {getText(value, config)}
    </Tag>
  );
};

const getArrayTag = (
  value: (string | number)[],
  config?: XCellTagProps["config"],
  tagConfig?: TagProps
): ReactElement => {
  return (
    <>
      {value.map((content, index) => (
        <Tag
          key={`${index}-${content}`}
          color={config?.baseBgColor}
          style={{ marginBottom: "0.25rem" }}
          {...tagConfig}
        >
          {getText(content, config)}
        </Tag>
      ))}
    </>
  );
};

const getDefaultTag = (
  value: any,
  config?: XCellTagProps["config"],
  tagConfig?: TagProps
): ReactElement => {
  return <Tag {...tagConfig}>{getText(value, config)}</Tag>;
};

const XCellTag = <ValueType extends unknown>({
  wrap, // unused but kept for backward compatibility in JobEdit component
  value,
  config,
  tagConfig,
}: XCellTagProps<ValueType>): ReactElement => {
  if (!value) return <></>;
  if (typeof value === "string") return getStringTag(value, config, tagConfig);
  if (Array.isArray(value)) return getArrayTag(value, config, tagConfig);
  return getDefaultTag(value, config, tagConfig);
};

export default XCellTag;
