/** Antd */
import { Typography } from "antd";

/** Icons */
import { AiFillCheckCircle } from "react-icons/ai";

/** Plugins */
import styled from "styled-components";

const { Text } = Typography;

/* ------------------- Types ------------------ */
interface ColumnSelectProps {
  options: { value: string; label: string }[];
  curColumn: string;
  onSelectColumn: (col: string) => void;
}

/* -------------------------------------------- */
/*               Default Component              */
/* -------------------------------------------- */
const ColumnSelect = (props: ColumnSelectProps) => {
  const { options, curColumn, onSelectColumn } = props;

  return (
    <SColumnSelect>
      {options.map((o) => (
        <SOption key={o.value} onClick={() => onSelectColumn(o.value)}>
          <Text>{o.label}</Text>
          {/** 已選取 Icon */}
          {o.value === curColumn ? (
            <AiFillCheckCircle fontSize={20} color="var(--bs-sara)" />
          ) : null}
        </SOption>
      ))}
    </SColumnSelect>
  );
};

export default ColumnSelect;

/* ------------- Styled Components ------------ */
const SColumnSelect = styled.div`
  height: 235px;
  border-radius: 4px;
  border: 1px solid var(--bs-gray-500);
  background-color: var(--bs-white);
  overflow-y: auto;

  /* custom scroll bar */
  &::-webkit-scrollbar {
    /* this selector is required for track and thumb to work. cannot be empty attributes. */
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: var(--bs-black);
  }
  &::-webkit-scrollbar-thumb {
    background: var(--bs-gray-200);
    border-radius: 10px;
    border: 2px solid transparent;
    background-clip: content-box;
  }
`;

const SOption = styled.div`
  border-bottom: 1px solid var(--bs-gray-400);
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: #e9c5934e;
  }
`;
