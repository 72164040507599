import { Checkbox, Form } from "antd";
import { useTranslation } from "react-i18next";
import FormDivider from "./FormDivider";

const DisableResource = () => {
  const { t } = useTranslation("resources", {
    keyPrefix: "_pages.edit",
  });

  return (
    <>
      <FormDivider title={t("disableStatus")} />
      <Form.Item name="disabled" valuePropName="checked" noStyle>
        <Checkbox>{t("disableResource")}</Checkbox>
      </Form.Item>
    </>
  );
};

export default DisableResource;
