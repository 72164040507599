import useAuth from "@/hooks/useAuth";
import React from "react";
import { Navigate } from "react-router-dom";

const InitGuard = ({ children }: { children: React.ReactNode }) => {
  const { isInitialized, user } = useAuth();

  /** 在 Auth API 還沒成功時，不顯示任何頁面。避免 404 頁面在等待 Auth API 回傳 User 時閃現 */
  if (!isInitialized) return <></>;

  /** 登出後，無效的網址顯示跳轉到登入頁面。這包括登出後，原有的頁面將不被授權，跳回登入頁面。 */
  if (!user) {
    return <Navigate to={"/"} />;
  }

  /** 管理者首頁：Dashboard */
  return <React.Fragment>{children}</React.Fragment>;
};

export default InitGuard;
