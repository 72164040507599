import ApiStatus from "@/components/dashboard/common/ApiStatus";
import Searchbar from "@/components/ui/Searchbar";
import useAppDispatch from "@/hooks/useAppDispatch";
import useAppSelector from "@/hooks/useAppSelector";
import {
  capabilities,
  capabilityState,
  removeAll,
  selectAll,
  selectedCapability,
  selectedWorkcenter,
  toggleSelect,
} from "@/redux/slices/resource/resourceEdit";
import { Capabilities, ItemJlb } from "@/utils/api/resource";
import { Button, Flex, Form } from "antd";
import { TFunction } from "i18next";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FixedSizeList } from "react-window";
import {
  EmptyCapabilityPlaceholder,
  SCapabilityOption,
  SCapabilityTag,
  SList,
} from "../styledCapability";

const getIsSelected = (
  workcenter: number | undefined,
  capability: string,
  selected: Capabilities
): boolean => {
  if (!workcenter) return false;
  const selectedCapability = selected[workcenter];
  if (!selectedCapability) return false;
  return selectedCapability.some(({ job_name }) => job_name === capability);
};

function filterCapabilityList(
  capability: ItemJlb[],
  searchValue: string
): ItemJlb[] {
  if (!capability) return [];
  const regex = new RegExp(searchValue, "i");
  return capability.filter((item) => regex.test(item.job_name));
}

function filterCapabilityByResourceType(
  capabilityList: ItemJlb[],
  resourceType: string
): ItemJlb[] {
  if (!capabilityList) return [];

  switch (resourceType) {
    case "Tooling":
      return capabilityList;
    case "Operator":
      return capabilityList.filter((capability) => {
        return capability.sourcing === "in-house";
      });
    case "Vendor":
      return capabilityList.filter((capability) => {
        return capability.sourcing === "out-sourcing";
      });
    case "Virtual":
      return capabilityList;
    case "Machine":
      return capabilityList.filter((capability) => {
        return capability.sourcing === "in-house";
      });
    default:
      return capabilityList;
  }
}

const getSourcingTranslate = (
  type: "in-house" | "out-sourcing",
  t: TFunction
): string => {
  switch (type) {
    case "in-house":
      return t("sourcing.in-house");
    case "out-sourcing":
      return t("sourcing.out-sourcing");
    default:
      return t("sourcing.unknown");
  }
};

const CapabilityList = () => {
  const [searchValue, setSearchValue] = useState("");

  const form = Form.useFormInstance();
  const resourceType = form.getFieldValue("resource_type");

  const workcenterId = useAppSelector(selectedWorkcenter);
  const capabilityMap = useAppSelector(capabilities);
  const selectedList = useAppSelector(selectedCapability);
  const loadingState = useAppSelector(capabilityState);
  const dispatch = useAppDispatch();

  const { t } = useTranslation("resources", {
    keyPrefix: "_pages.edit.capability",
  });

  const filteredList = useMemo(() => {
    if (!workcenterId) return [];
    const filterByType = filterCapabilityByResourceType(
      capabilityMap[workcenterId],
      resourceType
    );
    return filterCapabilityList(filterByType, searchValue);
  }, [capabilityMap, searchValue, workcenterId, resourceType]);

  const handleSearch = (value: string) => {
    setSearchValue(value);
  };

  const handleSelectAll = () => {
    dispatch(selectAll(filteredList));
  };

  const handleRemoveAll = () => {
    dispatch(removeAll(filteredList));
  };

  const handleSelect = (capability: ItemJlb) => {
    dispatch(toggleSelect(capability));
  };

  return (
    <SList>
      <Flex justify="space-between" align="center">
        <Searchbar onChange={handleSearch} />
        <Flex>
          <Button onClick={handleSelectAll}>{t("selectAll")}</Button>
          <Button onClick={handleRemoveAll}>{t("removeAll")}</Button>
        </Flex>
      </Flex>
      <ApiStatus
        loading={loadingState.isCapabilityLoading}
        success={loadingState.isCapabilitySuccess}
      >
        {filteredList.length > 0 ? (
          <FixedSizeList
            height={200}
            itemCount={filteredList.length}
            itemData={filteredList}
            itemSize={30}
            width="100%"
            itemKey={(index, data) => {
              return data[index].id;
            }}
          >
            {({ data, index, style }) => {
              const item = data[index];
              return (
                <SCapabilityOption
                  key={item.id}
                  $isSelected={getIsSelected(
                    workcenterId,
                    item.job_name,
                    selectedList
                  )}
                  onClick={() => handleSelect(item)}
                  style={style}
                >
                  {`${item.job_name}`}
                  <SCapabilityTag>{` (${getSourcingTranslate(
                    item.sourcing,
                    t
                  )})`}</SCapabilityTag>
                </SCapabilityOption>
              );
            }}
          </FixedSizeList>
        ) : (
          <EmptyCapabilityPlaceholder>
            {t("noCapability")}
          </EmptyCapabilityPlaceholder>
        )}
      </ApiStatus>
    </SList>
  );
};

export default CapabilityList;
