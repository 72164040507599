import useAppDispatch from "@/hooks/useAppDispatch";
import useAppSelector from "@/hooks/useAppSelector";
import {
  filteredCapability,
  removeWorkcenter,
  resetCapability,
  selectedCapability,
  toggleSelect,
} from "@/redux/slices/resource/resourceEdit";
import { ItemJlb } from "@/utils/api/resource";
import { CloseCircleFilled } from "@ant-design/icons";
import { Button, Form, Tag, Typography } from "antd";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CapabilityTagList,
  EmptyPlaceholder,
  SFlex,
  SList,
} from "../styledCapability";

const Text = Typography.Text;

const isCapabilityExist = (capabilityList: ItemJlb[]) => {
  return capabilityList.length > 0;
};

const SelectedResult = () => {
  const [previousType, setPreviousType] = useState<string>("");

  const capability = useAppSelector(selectedCapability);
  const dispatch = useAppDispatch();

  const form = Form.useFormInstance();
  const resourceType = form.getFieldValue("resource_type");

  const { t } = useTranslation("resources", {
    keyPrefix: "_pages.edit.capability",
  });

  useEffect(() => {
    if (resourceType === previousType) return;
    dispatch(filteredCapability(resourceType));
    setPreviousType(resourceType);
  }, [dispatch, resourceType, previousType]);

  const handleRemoveAll = () => {
    dispatch(resetCapability());
  };

  const handleRemoveWorkcenter = (workcenterId: number) => {
    dispatch(removeWorkcenter(workcenterId));
  };

  const handleRemove = (capability: ItemJlb) => {
    dispatch(toggleSelect(capability));
  };

  const isEmpty = Object.values(capability).every((v) => v.length === 0);

  return (
    <SList>
      {isEmpty ? (
        <EmptyPlaceholder>{t("emptySelected")}</EmptyPlaceholder>
      ) : (
        <Button
          icon={<CloseCircleFilled />}
          style={{ width: "100%" }}
          onClick={handleRemoveAll}
        >
          <Text>{t("removeAllCapability")}</Text>
        </Button>
      )}
      {Object.entries(capability).map(([workcenterId, capabilityList]) => {
        return (
          capabilityList.length > 0 && (
            <Fragment key={workcenterId}>
              <SFlex justify="space-between" align="center">
                <Text strong>{capabilityList[0]?.workcenter_name}</Text>
                <Button
                  size="small"
                  type="text"
                  onClick={() => handleRemoveWorkcenter(Number(workcenterId))}
                >
                  {t("removeAll")}
                </Button>
              </SFlex>
              <CapabilityTagList>
                {isCapabilityExist(capabilityList) &&
                  capabilityList.map((capability) => (
                    <Tag
                      closable
                      key={`${workcenterId}-${capability.id}`}
                      onClose={() => handleRemove(capability)}
                    >
                      {capability.job_name}
                    </Tag>
                  ))}
              </CapabilityTagList>
            </Fragment>
          )
        );
      })}
    </SList>
  );
};

export default SelectedResult;
