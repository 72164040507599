import { useTranslation } from "react-i18next";
import FormDivider from "../FormDivider";
import Capacity from "./Capacity";
import CapacityType from "./CapacityType";
import ExtraResource from "./ExtraResource";
import LineCapacity from "./LineCapacity";
import ResourceName from "./ResourceName";
import ResourceType from "./ResourceType";

interface StatusSectionProps {
  resourceId?: number;
}

const StatusSection = ({ resourceId }: StatusSectionProps) => {
  const { t } = useTranslation("resources", {
    keyPrefix: "_pages.edit",
  });

  return (
    <section style={{ width: "100%" }}>
      <FormDivider title={t("resourceStatus")} />
      {!resourceId && <ResourceName />}
      <ResourceType />
      <LineCapacity />
      <ExtraResource />
      <CapacityType isNewResource={!resourceId} />
      <Capacity />
    </section>
  );
};

export default StatusSection;
