import { AnyFunction } from "@/types/util";
import { type DebounceSettings } from "lodash";
import debounce from "lodash/debounce";
import { useEffect, useMemo, useRef } from "react";

const useDebounce = <T extends AnyFunction>(
  callback: T,
  delay: number,
  options?: DebounceSettings
) => {
  const refCallback = useRef(callback);
  const refOptions = useRef(options);

  useEffect(() => {
    refCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    refOptions.current = options;
  }, [options]);

  const debouncedCallback = useMemo(
    () =>
      debounce(
        (...args) => {
          refCallback.current(...args);
        },
        delay,
        refOptions.current
      ),
    [delay]
  );

  return debouncedCallback;
};

export default useDebounce;
