/** Types */
import { Levels } from "../../../types/notification";

enum LevelColor_TextClass {
  INFO = "text-success",
  WARNING = "text-sara",
  ERROR = "text-danger",
}

enum LevelColor_BgClass {
  INFO = "bg-success",
  WARNING = "bg-sara",
  ERROR = "bg-danger",
}

export const getBsColorClass_ByLevel = (
  level: Levels | undefined,
  type: "bg" | "text"
) => {
  switch (type) {
    case "bg":
      return level ? LevelColor_BgClass[level] : "bg-secondary";
    case "text":
      return level ? LevelColor_TextClass[level] : "text-secondary";
    default:
      return "";
  }
};

export const renderMessageCount = (count: number) =>
  count > 99 ? "99+" : count;
