import useDebounce from "@/hooks/useDebounce";
import { SearchOutlined } from "@ant-design/icons";
import { Button, Input } from "antd";
import { useEffect, useRef, useState } from "react";

/** ------- Types ------- */

interface SearchbarProps {
  onChange: (value: string) => void;
  debounce?: number;
  placeholder?: string;
  initialValue?: string;
  manualTrigger?: boolean;
}

const Searchbar = ({
  onChange,
  debounce = 500,
  placeholder,
  initialValue,
  manualTrigger,
}: SearchbarProps) => {
  const [inputValue, setInputValue] = useState<string>(initialValue ?? "");
  const prevSubmitValue = useRef(inputValue);

  /** Use number to indicate user clicked change */
  const [clickedValue, setClickedValue] = useState<number>(0);
  const prevClickedValue = useRef(clickedValue);
  const triggerSearch = (value: string) => {
    value !== prevSubmitValue.current && setClickedValue((p) => p + 1);
  };

  const debounceCallback = useDebounce(
    () => {
      onChange(inputValue);
    },
    debounce,
    { leading: manualTrigger }
  );

  useEffect(() => {
    if (manualTrigger) {
      if (clickedValue !== prevClickedValue.current) {
        debounceCallback();
        prevClickedValue.current = clickedValue;
        prevSubmitValue.current = inputValue;
      }
    } else {
      if (inputValue !== prevSubmitValue.current) {
        debounceCallback();
        prevSubmitValue.current = inputValue;
      }
    }

    return () => {
      debounceCallback.cancel();
    };
  }, [inputValue, manualTrigger, debounceCallback, clickedValue]);

  return manualTrigger ? (
    <Input.Search
      allowClear
      type="text"
      value={inputValue}
      placeholder={placeholder || "Search..."}
      onChange={(e) => {
        setInputValue(e.target.value);
      }}
      onSearch={(value) => {
        //! Always pass argument `value` to `triggerSearch`. Don't use state `value`. Since it is updated on next render, so the data value is staled!
        triggerSearch(value);
      }}
      enterButton={
        <Button
          icon={<SearchOutlined />}
          type={inputValue !== prevSubmitValue.current ? "primary" : "default"}
        />
      }
    />
  ) : (
    <Input
      allowClear
      type="text"
      value={inputValue}
      prefix={<SearchOutlined />}
      placeholder={placeholder || "Search..."}
      onChange={(e) => setInputValue(e.target.value)}
    />
  );
};

export default Searchbar;
