import { Form, Input } from "antd";
import { useTranslation } from "react-i18next";
import { SFormLabel } from "../styledForm";

const ResourceName = () => {
  const { t } = useTranslation("resources", {
    keyPrefix: "_pages.edit",
  });

  return (
    <Form.Item
      rules={[{ required: true }]}
      name={"resource_name"}
      label={<SFormLabel>{"資源名稱"}</SFormLabel>}
    >
      <Input placeholder={t("resourceNamePlaceholder")} />
    </Form.Item>
  );
};

export default ResourceName;
