import { Capabilities, ItemJlb } from "@/utils/api/resource";
import { PayloadAction } from "@reduxjs/toolkit";
import { ResourceState } from "./resourceEdit";

export const isExist = (
  workcenterId: number | undefined
): workcenterId is number => {
  return workcenterId !== undefined;
};

export const setCapabilityItems = (
  state: ResourceState,
  action: PayloadAction<Capabilities[]>
) => {
  if (action.payload.length === 0) {
    if (!state.workcenterId) return;
    return (state.capabilities[state.workcenterId] = []);
  }
  Object.entries(action.payload[0]).forEach(([id, capabilities]) => {
    state.capabilities[id] = capabilities;
  });
};

export const setSelectedItems = (
  state: ResourceState,
  action: PayloadAction<ItemJlb[]>
) => {
  state.selectedCapability = {};
  action.payload.forEach((capability: ItemJlb) => {
    const workcenterId = state.workcenters.find(
      (workcenter) => workcenter.name === capability.workcenter_name
    )?.id;
    if (!workcenterId) return;
    if (!state.selectedCapability[workcenterId]) {
      state.selectedCapability[workcenterId] = [];
    }
    state.selectedCapability[workcenterId] = [
      ...state.selectedCapability[workcenterId],
      capability,
    ];
  });
};

export const filteredCapabilityItems = (
  state: ResourceState,
  action: PayloadAction<string>
) => {
  const resourceType = action.payload;
  const isInHouse = ({ sourcing }: ItemJlb) => sourcing === "in-house";
  switch (resourceType) {
    case "Machine":
      Object.entries(state.selectedCapability).forEach(
        ([workcenterId, jlbs]) => {
          state.selectedCapability[workcenterId] = jlbs.filter(isInHouse);
        }
      );
      break;
    case "Operator":
      Object.entries(state.selectedCapability).forEach(
        ([workcenterId, jlbs]) => {
          state.selectedCapability[workcenterId] = jlbs.filter(isInHouse);
        }
      );
      break;
    case "Vendor":
      Object.entries(state.selectedCapability).forEach(
        ([workcenterId, jlbs]) => {
          state.selectedCapability[workcenterId] = jlbs.filter(
            (item) => !isInHouse(item)
          );
        }
      );
      break;
    default:
      break;
  }
};

export const getWorkcenterId = (
  state: ResourceState,
  action: PayloadAction<ItemJlb>
): number | undefined => {
  const workcenterName = action.payload.workcenter_name;
  return state.workcenters.find(
    (workcenter) => workcenter.name === workcenterName
  )?.id;
};

export const hasJlbExist = (
  state: ResourceState,
  action: PayloadAction<ItemJlb>
) => {
  const workcenterId = getWorkcenterId(state, action);
  if (workcenterId === undefined) return false;
  return state.selectedCapability[workcenterId]?.some(
    (jlb) => jlb.id === action.payload.id
  );
};

export const filteredCapability = (
  state: ResourceState,
  action: PayloadAction<ItemJlb>
) => {
  const workcenterId = getWorkcenterId(state, action);
  if (workcenterId === undefined) return [];
  return state.selectedCapability[workcenterId].filter(
    ({ id }) => id !== action.payload.id
  );
};

export const toggleSelectItem = (
  state: ResourceState,
  action: PayloadAction<ItemJlb>
) => {
  const workcenterId = getWorkcenterId(state, action);
  if (!isExist(workcenterId)) return;

  if (!state.selectedCapability[workcenterId]) {
    state.selectedCapability[workcenterId] = [action.payload];
    return;
  } else if (hasJlbExist(state, action)) {
    if (!isExist(workcenterId)) return;
    state.selectedCapability[workcenterId] = filteredCapability(state, action);
  } else {
    if (!isExist(workcenterId)) return;
    state.selectedCapability[workcenterId]?.push(action.payload);
  }
};

export const selectAllItems = (
  { workcenterId, selectedCapability }: ResourceState,
  action: PayloadAction<ItemJlb[]>
) => {
  if (!workcenterId) return;
  action.payload.forEach((jlb) => {
    const hasExistJlb = selectedCapability[workcenterId]?.some(
      ({ id: itemId }) => itemId === jlb.id
    );
    if (hasExistJlb) return;
    if (!selectedCapability[workcenterId]) {
      selectedCapability[workcenterId] = [];
    }
    selectedCapability[workcenterId].push(jlb);
  });
};

export const removeAllItems = (
  { workcenterId, selectedCapability }: ResourceState,
  action: PayloadAction<ItemJlb[]>
) => {
  if (!action.payload || action.payload.length === 0) return;
  if (!workcenterId) return;
  action.payload.forEach((jlb) => {
    selectedCapability[workcenterId] = selectedCapability[workcenterId].filter(
      ({ id }) => id !== jlb.id
    );
  });
};

export const removeWorkcenterItems = (
  state: ResourceState,
  action: PayloadAction<number>
) => {
  if (!action.payload) return;
  const workcenterId = action.payload;
  if (!state.selectedCapability[workcenterId]) return;
  delete state.selectedCapability[workcenterId];
};
